/* eslint-disable no-unused-vars */
import { Link } from 'react-router-dom'
import './index.css'
import { useGetOrdersQuery } from '../../../store/OrderApi'
import Loading from '../../../components/Loading'

export default function Orders() {
    const { data, error, isLoading } = useGetOrdersQuery()

    if(isLoading){
        return <div><Loading /></div>
    }
    return (
        <div>
            {data.data.map((item,index) => (
                <div key={index} style={{ borderBottom: '2px solid #efefef' }} className='articles_command row py-3 text-center align-items-center'>
                    <div className='col-2 col-md-3 p-0 article_image_command'>
                        <img style={{ width: '100%', aspectRatio: 1, maxHeight: '200px' }} src={'/'} alt='product' loading="lazy"/>
                    </div>
                    <div className='col-7 article_image_command'>
                        <div className='row align-items-center'>
                            <div className='article_name_command col-12 col-md-9'>
                                <Link to={'/#'}>
                                    Product name Product name Product name Product name Product name Product name Product name Product name Product name Product name Product name Product name
                                </Link>
                            </div>
                            <div className='col-12 col-md-3'>3080 Dhs</div>
                        </div>
                    </div>
                    <div className='col-3 p-0 col-md-2 article_image_command'></div>
                </div>
            ))}
        </div>
    )
}

