import { Link } from 'react-router-dom';
import { useState } from 'react';
import './index.css';
import { useSendEmailMutation } from '../../store/CommunicationApi';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    besoin: '',
  });

  const [sendEmail, { isLoading, isError }] = useSendEmailMutation();
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      const response = await sendEmail(formData).unwrap();
      if (response.status === 200) {
        setFormData({ nom: '', prenom: '', email: '', telephone: '', besoin: '' });
        setMessage('Votre message a été envoyé avec succès. Nous vous répondrons dès que possible.');
      }
    } catch {
      setMessage("Une erreur s'est produite lors de l'envoi de votre message. Veuillez réessayer.");
    }
  };

  return (
    <div className="ContactUs my-5">
      <div className="container-fluid container-xl">
        <div className="row g-4">
          <div className="col-12 col-lg-8">
            <div>
              <h1 style={{ fontSize: '2.1em', fontWeight: 700 }} className="title">CONTACT</h1>
              <hr />
              <div className='my-4'>
                <p className="paragraphContactUs">
                  {`Pour toute question ou pour discuter de votre projet, n'hésitez pas à nous contacter. Notre équipe est à votre disposition pour vous accompagner dans votre projet.`}
                </p>
                <p className="paragraphContactUs mb-0">Email: contact@smab-co.com</p>
                <p className="paragraphContactUs">Tél: +212 766-074939</p>
              </div>

              <div className='map'>
              <div style={{ width: '100%' }}>
                  <iframe
                      style={{ borderRadius: '8px' }}
                      width="100%"
                      height="500"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=33.587528,-7.6117231&ie=UTF8&t=&z=14&iwloc=B&output=embed"
                  >
                      <a href="https://www.gps.ie/">gps vehicle tracker</a>
                  </iframe>
              </div>
          </div>

              {message && (
                <div className={`alert ${isError ? 'alert-danger' : 'alert-success'}`}>{message}</div>
              )}

              <div className="ContactForm">
                <form onSubmit={handleSubmit}>
                  <div>
                    <label>Nom</label>
                    <input
                      type="text"
                      name="nom"
                      value={formData.nom}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label>Prénom</label>
                    <input
                      type="text"
                      name="prenom"
                      value={formData.prenom}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label>Téléphone</label>
                    <input
                      type="text"
                      name="telephone"
                      value={formData.telephone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label>Détaillez-nous votre message</label>
                    <textarea
                      style={{ height: '300px' }}
                      name="besoin"
                      value={formData.besoin}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <span className="d-flex justify-content-end my-5">
                    <button type="submit" disabled={isLoading}>
                      {isLoading ? 'Envoi en cours...' : 'Soumettre'}
                    </button>
                  </span>
                </form>
              </div>
            </div>
          </div>

          <div className='col-12 col-lg-4'>
            <div className='shortblog p-3'>
              <div className='py-2'>
                <h2 className='text-center mb-5'>{`journal d'actualités`}</h2>
                <div>
                  <ul className='p-0'>
                    <li className='my-3'>
                      <Link to={'/nos-conseils/blog/Secteurs-porteurs-pour-les-jeunes-entrepreneurs-au-maroc-en-2024?id=1'}>
                        7 Secteurs Porteurs pour les Jeunes Entrepreneurs au Maroc en 2024
                      </Link>
                    </li>

                    <li className='my-3'>
                      <Link to={'/nos-conseils/blog/revolutionner-la-production-Amlou?id=2'}>
                        {`Révolutionner la Production d'Amlou`}
                      </Link>
                    </li>

                    <li className='my-3'>
                      <Link to={'/nos-conseils/blog/optimiser-votre-production-avec-des-machines-densachage-automatiques?id=3'}>
                        {`Comment optimiser votre production avec des machines d'ensachage automatiques ? `}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

