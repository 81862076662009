
import { Link } from 'react-router-dom'

export default function Footer_lg() {
    return (
        <>
            <div className="footer_lg text-start p-4">
                <div className="">
                    <section className="">
                        <div className="row">
                            <div className="col-4">
                                <strong>Service commercial</strong>

                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link className="text-body" to={'/a-propos-de-nous'}>A propos de nous</Link>
                                    </li>
                                    <li>
                                        <Link className="text-body" to={'/politique-de-confidentialite'}>Politique de confidentialité et sécurité</Link>
                                    </li>
                                    <li>
                                        <Link className="text-body" to={'/'}>Programme distributeur SMAB</Link>
                                    </li>
                                    <li>
                                        <Link className="text-body" to={'/'}>Programme affiliés SMAB</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-4">
                                <strong>Service client</strong>

                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link className="text-body" to={'/contactez-nous'}>Contactez-nous</Link>
                                    </li>
                                    <li>
                                        <Link className="text-body" to={'/mon-compte'}>Votre compte</Link>
                                    </li>
                                    <li>
                                        <Link className="text-body" to={'/CGV'}>CGV</Link>
                                    </li>
                                    <li>
                                        <Link className="text-body" to={'/MentionLegal'}>Mentions légales</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-4">
                                <strong>Abonnez-vous à Notre Newsletter</strong>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <form action="" className='subscribe'>
                                            <div className="d-flex justify-content-start">
                                                <div className="form-outline mb-4">
                                                    <input type="email" id="form5Example24" className="form-control" placeholder='Email' />
                                                </div>
                                                <button type="submit" className="btn btn-outline mb-4">
                                                    Subscribe
                                                </button>
                                            </div>
                                        </form>
                                    </li>

                                    <li className='footer_lg_icons'>
                                        <strong className='m-0' >Trouvez-nous sur</strong>
                                        <div>
                                            <a href="https://web.facebook.com/SMABMAROC/?locale=fr_FR&_rdc=1&_rdr"
                                                className="me-4 text-reset"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href={'https://www.tiktok.com/@smabmaroc'} rel="noopener noreferrer" target="_blank" className="me-4 text-reset">
                                                <i className="fa-brands fa-tiktok"></i>
                                            </a>
                                            <a href='https://www.instagram.com/smabmaroc/' target="_blank" rel="noopener noreferrer" className="me-4 text-reset">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                            {/* <Link to={'/'} className="me-4 text-reset">
                                                <i className="fab fa-linkedin"></i>
                                            </Link>*/}
                                            <a target="_blank" rel="noopener noreferrer" href={'https://www.youtube.com/@SMABMAROC'} className="me-4 text-reset">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </div>

                                    </li>
                                </ul>
                            </div>

                        </div>
                    </section>

                </div >

            </div >
        </>
    )
}

