/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// @ts-nocheck
import { useState, createRef, useEffect } from 'react'
import './index.css';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoginMutation } from '../../store/AuthUserApi';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../store/Auth';


export default function DrawerLogin({setLoginDrawerOpen,setDrawerComponent,checkuservalidatyrefetch}) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [userLogin] = useLoginMutation();
    const [FormData, setFormData] = useState({
        identifier: '',
        password: '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/;

        if (!emailRegex.test(FormData.identifier) && !phoneRegex.test(FormData.identifier)) {
            setLoading(false);
            setError('Veuillez entrer une adresse e-mail valide ou un numéro de téléphone à 10 chiffres "0XXXXXXXXX".');
            return;
        }

        try {
            const response = await userLogin(FormData);
            if (response.data.status === 200) {
                dispatch(loginUser(response.data.token));
                setLoginDrawerOpen(false);
                checkuservalidatyrefetch()
            } else {
                setError("Informations d'identification non valides");
            }
        } catch (err) {
            setError("Une erreur s'est produite. Veuillez réessayer.");
        } finally {
            setLoading(false);
        }
    };



    return (
        <div className='drawerlogin'>
            <div className='login_register'>
                <div className='container-fluid'>
                    <div style={{ marginTop: "150px" }} className='row justify-content-center'>
                        <div className='col-12 col-md-6'>
                            <div className='login_component text-center my-3'>
                                <h2 className='title '>Connectez-vous ou créez un compte</h2>
                                {loading && (
                                    <div className='loading d-flex justify-content-center align-items-center'>
                                        <CircularProgress style={{ width: 60, height: 60 }} />
                                    </div>
                                )}
                                <div className='Login_component  p-3 text-center'>
                                    <div className='content_Login_component'>
                                        <form onSubmit={handleSubmit}>
                                            <div className='input_parent Email_phone_login'>
                                                <input
                                                    type='text'
                                                    name="identifier"
                                                    placeholder='Email ou Tél'
                                                    value={FormData.identifier}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className='input_parent password_login'>
                                                <input
                                                    type='password'
                                                    name="password"
                                                    placeholder='mot de passe'
                                                    value={FormData.password}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            {error && <div className="error_message alert alert-danger">{error}</div>}
                                            <div>
                                                <button disabled={loading} className='Se_connecter' type='submit'>
                                                    Se connecter
                                                </button>
                                            </div>
                                        </form>
                                        <button style={{ backgroundColor: 'transparent', border: 'none' }} className='Forgotten_password'>
                                            Mot de passe oublié
                                        </button>
                                        {/* Your other modal code goes here */}
                                    </div>
                                </div>
                                <div className='px-3 d-flex justify-content-center'>
                                    <p className='mb-0 me-2' style={{ fontSize: '1.3em', fontWeight: 600, color: '#00000099' }}>
                                        Nouveau sur SMAB ?
                                    </p>
                                    <button style={{ backgroundColor: 'transparent', border: 'none' }} className='creer_compte' onClick={() => setDrawerComponent('register')}>Créer un compte</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


