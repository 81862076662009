/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import './index.css';
import SimpleSlider from './SimpleSlider';
import { useState, useEffect, useRef } from 'react'
import ContactForm from '../../components/contactForm/ContactForm';
import SlideActivite from './SlideActivite';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../store/Produits';
import DrawerPanier from '../../components/DrawerPanier';
import { useGetProduitsBySousCategoryQuery } from '../../store/ProduitsApi';
import { Helmet } from 'react-helmet-async';
import Slider from "react-slick";
import SlideVdProjects from '../nosProjets/SlideVdProjects';
import Loading from '../../components/Loading';


export default function Home({ seoData }) {
  // const cart = useSelector((state) => state.Produits.cart);
  // console.log(cart)
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleAddToCart = () => {
    setIsDrawerOpen(true);
  };

  const { data: produits, isLoading, error } = useGetProduitsBySousCategoryQuery('Ensachage');
  function getImageUrl(productRef, imageFilenames) {
    if (imageFilenames && imageFilenames.length > 0) {
      return imageFilenames
        .filter(filename => /\.(jpg|jpeg|png|gif|webp)$/i.test(filename)) // Only include image files
        .map(filename => `https://www.kelmohub.com/product-images/${productRef}/${filename}`);
    }
    return ['/Images/default-image.jpg']; // Return an array with the default image
  }


  const [counters, setCounters] = useState({
    'projets réalisés ': 0,
    Partenaires: 0,
    'd’expertise ': 0,
  });

  const targetValues = {
    'projets réalisés ': 800,
    'Partenaires': 200,
    'd’expertise ': 25,
  };
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const interval = setInterval(() => {
              setCounters((prevCounters) => {
                const updatedCounters = {};
                Object.keys(prevCounters).forEach((key) => {
                  updatedCounters[key] = Math.min(prevCounters[key] + 1, targetValues[key]);
                });
                return updatedCounters;
              });
            }, 50);
            entry.target.ontransitionend = () => clearInterval(interval);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    return () => observer.disconnect();
  }, [targetValues]);


  const toSlug = (label) => {
    return label
      .toLowerCase()
      .replace(/ /g, '-')         // Replace spaces with hyphens
      .replace(/[^\w-]+/g, '');   // Remove all non-word characters
  };

  const logosMarque = [
    '/Images/logos/bimbo.png',
    '/Images/logos/cocacola.png',
    '/Images/logos/tria.png',
    '/Images/logos/mohamed5.png',
    '/Images/logos/giz.png',
    '/Images/logos/enabel.png',
    '/Images/logos/ocp.png',
  ]

  const [width, setwidt] = useState(window.innerWidth)
  const settings_carousel_marque = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: width < 576 ? 2 : 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1000,
  };

  useEffect(() => {
    const handleResize = () => {
      setwidt(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="home position-relative">
      <Helmet>
        <title>{seoData.meta_title || 'Default Title'}</title>
        <meta name="description" content={seoData.meta_description || 'Default description'} />
        <meta name="keywords" content={seoData.focus_keyword || 'default keywords'} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="sectionheader">
        <div className="imagebanner">
          <div className="darkLayer d-md-flex justify-content-center align-items-center p-2 p-md-0">
            <div className=" d-md-flex flex-column align-items-md-center">
              <div className="d-none d-lg-flex Logobanier mb-5">
                <img src="/Images/LOGO-SMAB-CROP-1.png" alt="smab logo" loading="eager" />
              </div>
              <div className="mainTitle">
                <h1>{`Des Solutions Avancées pour l'Industrie Agro-Food, Pharmaceutique, Cosmétique et Chimique`}</h1>
              </div>
              <div className='cta'>
                <Link to={'/contactez-nous'}>CONTACTEZ-NOUS</Link>
              </div>
            </div>
          </div>

          <div className="videoContainer">
          <video
          src="/Images/vdbaner.mp4"
          autoPlay
          loop
          muted
          playsInline
          className="videoBanner"
        >
          <track
            src="/Images/vdbaner.vtt"
            kind="captions"
            srcLang="fr"
            label="Français"
          />
        </video>
        
          </div>
        </div>
      </div>

      <div className='usp'>
        <div className='container-fluid container-xl'>
          <div className='row justify-content-center'>
            {[
              {
                img: '/Images/usp/ConseilsetAccompagnement.png',
                alt: 'Conseils et Accompagnement',
                text: 'Conseils <br /> et Accompagnement'
              },
              {
                img: '/Images/usp/InstallationetFormationIncluses.png',
                alt: 'Installation et Formation Incluses',
                text: 'Installation <br /> et Formation Incluses'
              },
              {
                img: '/Images/usp/LivraisonFlexibleetPersonnalisee.png',
                alt: 'Livraison Flexible et Personnalisee',
                text: 'Livraison Flexible <br /> et Personnalisée'
              },
              {
                img: '/Images/usp/OptionsdePaiementFlexibles.png',
                alt: 'Options de Paiement Flexibles',
                text: 'Options de Paiement Flexibles'
              },
              {
                img: '/Images/usp/SupportetRessourceseducatives.png',
                alt: 'Support et Ressources éducatives',
                text: 'Support <br /> et Ressources Éducatives'
              }
            ].map((item, index) => (
              <div className='col-12 col-sm-6 col-md-4 col-lg-2 d-flex flex-column align-items-center' key={index}>
                <img src={item.img} alt={item.alt} loading="lazy" className="img-fluid rounded-circle mb-2" />
                <p
                  className='text-center'
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>


      <div className='homesection5 '>
        <div className='container-fluid px-lg-5'>
          <div>
            <h2 className='text-center title'>Notre expertise couvre différentes activités</h2>
          </div>
          <div>
            <div className='p-lg-5'>
              <SlideActivite />
            </div>
          </div>
        </div>
      </div>
      <div style={{ minHeight: '700px', marginBottom: '200px' }} className='homesection3 align-items-center '>
        <div className='container-fluid container-xl'>
          <div className='text-center my-5'>
            <h2 className='title'>DÉCOUVREZ SMAB</h2>
            <p className='subtitle mb-5'>Votre expert en solutions industrielles pour tous vos besoins en transformation et fin de ligne dans divers secteurs.</p>
          </div>
          <div>
            <SimpleSlider />
          </div>
        </div>
      </div>

      <div style={{ backgroundColor:'#a9a3a3' }} className='homesection6 '>
        <div className='container-fluid container-xl'>
          <h2 className='title text-center '>Nos projets et réalisations</h2>
          <p className='text-center subtitle' >{`Découvrez notre expertise à travers les projets que nous avons réalisés. Notre engagement envers la qualité et l'innovation nous a permis de mener à bien de nombreux projets dans divers secteurs.`}</p>
          <div className='description_historique_company'>
            <div style={{ marginBottom: '200px' }} className='row mt-5'>
              <SlideVdProjects />
            </div>
            <div className='container'>
              <div
                ref={sectionRef}
                style={{ minHeight: '250px', marginTop: '200px', padding: '20px', maxWidth: '1100px', margin: 'auto' }}
                className='row align-items-center justify-content-center text-center counterPartenaires swim-animation'
              >
                {Object.keys(counters).map((key) => (
                  <div key={key} className={`home_page_${key} col-12 col-md-3`}>
                    <h3 style={{ color: '#fff' }} className='title'>
                      +{counters[key]} {key === "d’expertise " ? "ans" : ""}
                    </h3>
                    <p style={{ color: '#fff' }} className='title'>{key}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*start section2 meilleurs vents*/}
      <div className='sectionbestvent'>
        <div style={{ margin: '100px auto' }} className='container-fluid container-xxl'>
          <div className='activite_page_meilleurs_vents my-5'>
            <h2 className='title py-2 m-3 my-5 text-center'>meilleures ventes</h2>
            {produits ? (
              <div className='row'>
                {(produits.products).slice(0, 4).map(product => {
                  const imageUrls = getImageUrl(product.ProductRef, product.ImageFilenames);
                  return (
                    <div className='col-12 col-sm-6 col-md-3' key={product.ProductId}>
                      <div className="mb-2">
                        <Link to={`/produit/${toSlug(product.ProductLabel)}?id=${product.ProductId}`} className='position-relative'>
                          <img
                            style={{ aspectRatio: 1, width: "100%" }}
                            src={imageUrls[0]}  // Display only the first image
                            className="card-img-top"
                            alt={product.ProductLabel || "Product Image"}
                            loading="lazy"
                          />
                        </Link>
                        <div className="cardbody p-1">
                          <div className="cardtitle">
                            <p style={{ lineHeight: '25px', fontSize: '1.3em', fontWeight: '600' }}
                              className='name_pro_acti_p_mv towLine mb-1'>
                              {product.ProductLabel}
                            </p>
                          </div>
                          <div>
                            <button
                              onClick={() => {
                                dispatch(addToCart({
                                  id: product.ProductId,
                                  quantity: 1,
                                  prix: product.Price,
                                  ref: product.ProductRef,
                                  name: product.ProductLabel,
                                  image: imageUrls[0],
                                }));
                                handleAddToCart()
                              }}
                              className="add_pannier_act_p_mv border-0">
                              Ajouter au panier
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
      <div style={{ margin: 'auto' }} className='homesection4'>
        <div className='container-fluid px-lg-5'>
          <div className='text-center'>
            <h2 className='title my-5'>Nos partenaires</h2>
          </div>
          <div className='ourPartner'>
            <div className='container_fluid container-xl'>
              <div style={{ overflowX: 'hidden' }} className='ourPartner_carousel my-5 py-3'>
                <div >
                  <Slider {...settings_carousel_marque}>
                    {logosMarque.map((item, index) => (
                      <div className='' key={index}>
                        <div className="mx-4" >
                          <div className=''>
                            <img style={{ width: '100%', height: '100%' }} src={item} className="" alt="marque" loading="lazy" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end section2 meilleurs vents*/}
      {/*<div style={{ margin: '100px auto' }} className='homesection7 my-5'>
        <div className='container-fluid container-lg'>
          <div>
            <h2 className='title text-center'>De quoi avez vous besoins ?</h2>
            <p className='subtitle text-center'>Nous vous répondrons dans les plus brefs délais</p>
            <ContactForm />
          </div>
        </div>
      </div>*/}
      <DrawerPanier isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
    </div>
  );
}



