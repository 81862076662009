/* eslint-disable no-unused-vars */
// @ts-nocheck

import './index.css';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

export default function Compare() {
    const data = useSelector(state => state.Produits.produits);

    const [products, setProducts] = useState([]);
    const [productIds, setProductIds] = useState([]);
    const location = useLocation();
    const { activiteName,sousActiviteName } = useParams();


    
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const parsedProductIds = JSON.parse(queryParams.get('products')) || [];
        if (parsedProductIds.length > 0) {
            
            setProductIds(parsedProductIds); // Update state with product IDs
            console.log(parsedProductIds);
        }
    },[location.search]);

    return (
        <div className='compareProduct_page my-4'>
            <div className='container-fluid container-xxl'>
                <div style={{ overflowX: 'auto' }} className='d-flex'>
                    {productIds.map((productId) => {
                        return (
                            <div key={productId} style={{ width: '25%', minWidth: '18em' }} className='m-2'>
                                <div className="card border position-relative" style={{ width: '100%' }}>
                                    <img src={'/Images/produit.jpg'} className="card-img-top" alt={'Product'} loading="lazy" />
                                    <button className='addPannier p-2'>Ajouter au pannier</button>
                                    <ul className="list-group list-group-light list-group-small p-0">
                                        <li className="list-group-item p-2">Prix: {`399 Dhs`}</li>
                                        <li className="list-group-item p-2">Poids: 30KG</li>
                                        <li className="list-group-item p-2">Largeur: 20M</li>
                                        <li className="list-group-item p-2">Hauteur: 17M</li>
                                    </ul>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
