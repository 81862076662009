import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Create an API slice
export const AuthUserApi = createApi({
  reducerPath: 'AuthUserApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: 'http://127.0.0.1:8000/api',
    baseUrl: 'https://smab-co.com/backend/public/api',
    // baseUrl: 'https://mintcream-crocodile-587037.hostingersite.com/public/api',

    prepareHeaders: (headers) => {
      const token = localStorage.getItem('userToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({

    // Login endpoint
    login: builder.mutation({
      query: (FormData) => ({
        url: '/user/login',
        method: 'POST',
        body: FormData,
      }),
    }),


    // Register endpoint
    register: builder.mutation({
      query: (FormData) => ({
        url: '/user/register',
        method: 'POST',
        body: FormData,
      }),
    }),


    logout: builder.mutation({
      query: () => ({
        url: '/user/logout', // Assuming this is the logout endpoint on your backend
        method: 'POST', // Make sure it's a POST request
      }),
    }),


    UserCheckValidityToken: builder.query({
      query: () => ({
        url: '/user/check-validity-token',
        method: 'GET',

      })
    }),

    ForgetPassword: builder.mutation({
      query: (email) => ({
        url: '/user/ForgetPassword',
        method: 'post',
        body: email,
      })
    }),

    VerifyCode: builder.mutation({
      query: ({ email, code }) => ({
        url: '/user/VerifyCode-password-reset',
        method: 'post',
        body: { email, code },
      })
    }),

    ResetPassword: builder.mutation({
      query: (data) => ({
        url: "/reset-password",
        method: "POST",
        body: data,
    }),
    })
  }),
});

export const { useLoginMutation, useRegisterMutation, useLogoutMutation, useUserCheckValidityTokenQuery, useForgetPasswordMutation, useVerifyCodeMutation, useResetPasswordMutation } = AuthUserApi;
