import './index.css'
import { useNavigate } from 'react-router-dom'
export default function NosProjets() {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate('/categorieName/projets-details')
    }
    return (
        <div className="NosProjets">
            <div className="sectionheader">
                <div className="banerImage position-relative">
                    <div className='darkLayout'></div>
                    <img src={'/Images/Mainbanner.webp'} alt='banner' />
                </div>
            </div>

            <div className='projectGallery my-5'>
                <div className='container-fluid container-xxl'>
                    <div className='category'>
                        <h1 className='title my-4'>Categorie 1</h1>
                        <div className='row g-3'>
                            {[1, 2, 3].map((item, index) => (
                                <div key={index} className='col-12 col-md-6 col-lg-4'>
                                    <div className=''>
                                        <div onClick={handleClick} className="cardProject">
                                            <img src={'/Images/produit.jpg'} className="card-img-top" alt="product" loading="lazy" />
                                            <div className="card-body py-3">
                                                <p className="card-text text-center sousactivite_product_name">Projet Réalisé</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='category'>
                        <h1 className='title my-4'>Categorie 2</h1>
                        <div className='row g-3'>
                            {[1, 2, 3].map((item, index) => (
                                <div key={index} className='col-12 col-md-6 col-lg-4'>
                                    <div className=''>
                                        <div className="cardProject">
                                            <img src={'/Images/produit.jpg'} className="card-img-top" alt="product" loading="lazy" />
                                            <div className="card-body py-3">
                                                <p className="card-text text-center sousactivite_product_name">Projet Réalisé</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='category '>
                        <h1 className='title my-4'>Categorie 3</h1>
                        <div className='row g-3'>
                            {[1, 2, 3].map((item, index) => (
                                <div key={index} className='col-12 col-md-6 col-lg-4'>
                                    <div className=''>
                                        <div className="cardProject">
                                            <img src={'/Images/produit.jpg'} className="card-img-top" alt="product" loading="lazy" />
                                            <div className="card-body py-3">
                                                <p className="card-text text-center sousactivite_product_name">Projet Réalisé</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
