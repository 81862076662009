import { configureStore } from "@reduxjs/toolkit";
import {Produits} from "./Produits";
import { Auth } from "./Auth";
import { AuthUserApi } from "./AuthUserApi";
import { ProduitsApi } from "./ProduitsApi";
import { UserApi } from "./UserApi";
import { OrderApi } from "./OrderApi";
import { CommunicationApi } from "./CommunicationApi";
import { SeoApi } from "./SeoApi";
import { AuthAdminApi } from "./AuthAdminApi";



export const store=configureStore({
    reducer:{
        'Produits': Produits.reducer,
        'Auth':Auth.reducer,
        'AuthUserApi':AuthUserApi.reducer,
        'ProduitsApi':ProduitsApi.reducer,
        'UserApi':UserApi.reducer,
        'OrderApi' :OrderApi.reducer,
        'CommunicationApi' : CommunicationApi.reducer,
        'SeoApi' : SeoApi.reducer,
        'AuthAdminApi' : AuthAdminApi.reducer
    },

    // because we are using createApi to generate the api endpoints, and
    // it expects the middleware to be added to the store.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            AuthUserApi.middleware,
            ProduitsApi.middleware,
            UserApi.middleware,
            OrderApi.middleware,
            CommunicationApi.middleware,
            SeoApi.middleware,
            AuthAdminApi.middleware
        ),
    })