/* eslint-disable react/prop-types */

import Drawer from '@mui/material/Drawer';
import Panier from '../pages/Panier/Panier';

export default function DrawerPanier({ isDrawerOpen, setIsDrawerOpen }) {
    return (
        <Drawer
            anchor="bottom"
            size="md"
            variant="temporary" // Changed to a valid value
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            PaperProps={{ style: { maxHeight: '80vh', overflow: 'auto', }, }}
        >
            <Panier />
        </Drawer>
    );
}
