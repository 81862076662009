/* eslint-disable no-unused-vars */
// @ts-nocheck
import { useState } from 'react';
import './parametres.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetUserQuery, useUpdateUserMutation } from '../../../store/UserApi';
import Loading from '../../../components/Loading';

export default function Parametres() {
  const [updateUser] = useUpdateUserMutation();
  const { data: user, isLoading, isError, refetch } = useGetUserQuery();
  const [openform, setopenform] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successChange, set_successChange] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  if (isLoading) {
    return <div><Loading /></div>;
  }

  const handleEditClick = () => {
    setName(user?.data?.name || '');
    setEmail(user?.data?.email || '');
    setPhone(user?.data?.phone || '');
    setopenform(true);
    setError(''); // Reset error on form open
  };

  const handleCancelClick = () => {
    setopenform(false);
    setError(''); // Reset error on form close
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(''); // Reset error before new submission

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      setLoading(false);
      setError('Please enter a valid email address.');
      return;
    }

    if (phone && phone.length !== 10) {
      setLoading(false);
      setError('Le numéro de téléphone doit comporter 10 chiffres 0XXXXXXXXX');
      return;
    }

    if (phone && !/^[0-9]{10}$/.test(phone)) {
      setLoading(false);
      setError('Entrez un numéro de téléphone valide');
      return;
    }

    const updatedUser = { name, email, phone };

    try {
      const response = await updateUser(updatedUser);
      if (response.error) {
        setError('Une erreur s\'est produite lors de la mise à jour.');
      } else {
        set_successChange(true);
        setTimeout(() => set_successChange(false), 3000); // Show success message briefly
        refetch();
        setopenform(false); // Close form after successful submission
      }
    } catch (err) {
      setError('An unexpected error occurred.');
    }

    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="loading d-flex justify-content-center align-items-center">
          <CircularProgress style={{ width: 60, height: 60 }} />
        </div>
      )}
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      {successChange && (
        <Alert severity="success" onClose={() => set_successChange(false)}>
          Informations mises à jour avec succès !
        </Alert>
      )}
      <div className="password pt-4 m-3">
        <div>
          <h2 className="title mb-4">Paramètres</h2>
        </div>

        <div className="row align-items-start">
          <div className="col-1">
            <AccountCircleIcon sx={{ fontSize: '60px', color: '#c1c1c1' }} />
          </div>

          <div className="col-12 col-md-10">
            <strong className="subtitle">Informations personnelles</strong>
            <hr className="my-2" />
            <div className="content_info_user">
              <ul className="p-0">
                <li>
                  <div className="view_key">
                    <p>Nom :</p>
                  </div>
                  <div className="view_value">
                    <p>{user?.data?.name}</p>
                  </div>
                </li>
                <li>
                  <div className="view_key">
                    <p>Tél :</p>
                  </div>
                  <div className="view_value">
                    <p>{user?.data?.phone}</p>
                  </div>
                </li>
                <li>
                  <div className="view_key">
                    <p>Email :</p>
                  </div>
                  <div className="view_value">
                    <p>{user?.data?.email}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="edit_info_user">
              <button className="d-flex" onClick={handleEditClick}>
                <div className="me-1">Éditer</div>
                <EditIcon />
              </button>
            </div>

            {openform && (
              <div className="form_edit_info_user mt-4">
                <form onSubmit={handleSubmit}>
                  <div>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      placeholder="Nom"
                      required
                    />
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="col-6">
                      <input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="text"
                        placeholder="Tel"
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 col-md-6 my-2">
                      <button className="form_edit_info_user_save" type="submit">
                        Sauvegarder les modifications
                      </button>
                    </div>
                    <div className="col-12 col-md-6 my-2">
                      <button
                        className="form_edit_info_user_annuler"
                        type="button"
                        onClick={handleCancelClick}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
