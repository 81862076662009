// import { Link } from "react-router-dom";
// import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
// import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
// import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import './index.css';
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { increaseQuantity, decreaseQuantity, removeFromCart, selectCartTotal } from "../../store/Produits";

export default function Panier() {
    const soustotal = useSelector(selectCartTotal);
    const dispatch = useDispatch();
    const [Livraison, setLivraison] = useState({ id: '', zone: '', price: '' });
    const cart = useSelector((state) => state.Produits.cart);

    const Zonesdata = [
        { id: 1, zone: '-', price: '-' },
        // { id: 2, zone: 'Rabat', price: 30 },
        // { id: 3, zone: 'Tanger', price: 50 },
        // { id: 4, zone: 'Fes', price: 45 },
        // { id: 5, zone: 'Agadir', price: 60 }
    ];

    const handleChangezone = (event) => {
        const selectedIndex = event.target.selectedIndex;
        const selectedOption = event.target.options[selectedIndex];

        setLivraison({
            id: selectedOption.getAttribute('data-id'),
            zone: selectedOption.getAttribute('data-zone'),
            price: selectedOption.getAttribute('data-price'),
        });
    };

    // const toggleDevisForm = () => {
    //     setShowDevisForm(!showDevisForm);
    // };

    // const handleInputChange = (e) => {
    //     const { id, value } = e.target;
    //     setDevisData((prevData) => ({
    //         ...prevData,
    //         [id]: value
    //     }));
    // };

    // const handleSubmitDevis = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     setSuccess(false);
    //     setError(false);

    //     try {
    //         const response = await storeDemandeDevis(devisData);
    //         if (response.data.status === 200) {
    //             setLoading(false);
    //             setSuccess(true);
    //             setDevisData({
    //                 prenom: '',
    //                 nom: '',
    //                 adresse: '',
    //                 email: '',
    //                 tel: '',
    //                 articles: cart
    //             });
    //             setShowDevisForm(false);
    //         } else {
    //             throw new Error('Impossible de soumettre le formulaire');
    //         }
    //     } catch {
    //         setLoading(false);
    //         setError(true);
    //     }
    // };

    const handleSubmitDevis = (e) => {
        e.preventDefault();
        const message = `
        Bonjour, je souhaite demander un devis. Voici mes informations :
        🛒 Articles :
        ${cart.map(item => `- ${item.name} (x${item.quantity})`).join('\n')}
        `;

        const phoneNumber = "212766074939"; // Replace with your WhatsApp number (with country code)
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        window.open(whatsappUrl, '_blank');
    };


    return (
        <div className='page_pannier'>
            <div className='navbar_panier position-relative'>
                <div className='last_nav_panier container-fluid d-none d-md-block' style={{ boxShadow: '#dfdfdf 0px 2px 2px' }}>
                    <div className='py-4 row' >
                        {/*<div className='col-11 row text-center'>
                            <div className='col-4 d-flex justify-content-center align-items-center'>
                                <LocalShippingOutlinedIcon sx={{ color: '#e63812' }} />
                                <strong style={{ fontSize: '16px', fontWeight: '700', color: '#000000de', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className='ms-2 mb-0'>Livraison Gratuite</strong>
                            </div>
                            <div className='col-4 d-flex justify-content-center align-items-center'>
                                <CurrencyExchangeIcon sx={{ color: '#e63812' }} />
                                <strong style={{ fontSize: '16px', fontWeight: '700', color: '#000000de', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className='ms-2 mb-0'>Retours sans tracas sous 30 jours
                                </strong>
                            </div>
                            <div className='col-4 d-flex justify-content-center align-items-center'>
                                <GppGoodOutlinedIcon sx={{ color: '#e63812' }} />
                                <strong style={{ fontSize: '16px', fontWeight: '700', color: '#000000de', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className='ms-2 mb-0'>12 Mois de Garantie</strong>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>

            <div className='my-3 p-md-3 '>
                <div className='container-fluid container-xxl'>
                    <div className='article_facture'>
                        <div className='row g-3'>
                            <div className='artticles_de_pannier col-12 col-md-8'>
                                {cart.map(item => (
                                    <div key={item.id} className='cardarticle  mb-2'>
                                        <div className='d-flex align-items-center align-items-sm-start justify-content-sm-between flex-column flex-sm-row p-3'>
                                            <div className='imageRticle my-3 d-flex flex-column'>
                                                <img style={{ maxWidth: '200px', width: '100%', aspectRatio: 1 }} src={item.image} alt={item.name} loading="lazy" />
                                            </div>
                                            <div className='priceArticle d-flex flex-column my-3'>
                                                <strong>Prix</strong>
                                                <p style={{ fontSize: '1.2em', fontWeight: "600" }} className='price mb-0 text-center'>{item.prix != 0 ? item.prix + 'MAD' : '_'} </p>
                                            </div>
                                            <div className='quantity d-flex flex-column my-3'>
                                                <strong className="text-center">Quantité</strong>
                                                <div className='qttCounter text-center my-4'>
                                                    <button onClick={() => dispatch(decreaseQuantity(item))}>-</button>
                                                    <span>{item.quantity}</span>
                                                    <button onClick={() => dispatch(increaseQuantity(item))}>+</button>
                                                </div>
                                                <div className='text-center'>
                                                    <button onClick={() => dispatch(removeFromCart(item))} className='removeBtn'>supprimer</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-2 productnamecard">
                                            <p className='mb-0'>{item.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='facture col-12 col-md-4 justify-content-end position-relative'>
                                <div className='facture-content p-3'>
                                    <div className='Sous_total d-flex justify-content-between align-items-center'>
                                        <strong>Sous total</strong>
                                        {soustotal != 0 ? <strong>{soustotal} MAD</strong> : <strong>_</strong>}
                                    </div>
                                    <div className='Zone_Livraison my-4'>
                                        <strong>Zone de Livraison</strong>
                                        <select
                                            className="mt-3"
                                            id="zone-select"
                                            value={Livraison.zone}
                                            onChange={handleChangezone}
                                        >
                                            <option value="">Sélectionner une zone</option>
                                            {Zonesdata?.map((zone) => (
                                                <option
                                                    key={zone.id}
                                                    value={zone.zone}
                                                    data-id={zone.id}
                                                    data-zone={zone.zone}
                                                    data-price={zone.price}
                                                >
                                                    {zone.zone} - {zone.price}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <hr />
                                    <div className='Total_Montant d-flex justify-content-between align-items-center'>
                                        <strong>Total</strong>
                                        {soustotal != 0 ? <strong>{soustotal} MAD</strong> : <strong>_</strong>}
                                    </div>
                                    <div className='facture_payment'>
                                        {/*{Livraison.zone ? (
                                            <Link
                                                to={`/checkout?zoneId=${Livraison.id}`}
                                                className='checkoutbtn d-flex align-items-center justify-content-center'
                                            >
                                                Continuer la commande
                                            </Link>
                                        ) : (
                                            <button
                                                className='checkoutbtn disabled-btn d-flex align-items-center justify-content-center'
                                                disabled
                                            >
                                                Continuer la commande
                                            </button>
                                        )}*/}
                                        <button
                                            className='checkoutbtn d-flex align-items-center justify-content-center'
                                            style={{ backgroundColor: 'black' }}
                                            onClick={handleSubmitDevis}
                                        >
                                            devis
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
