/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './index.css'
import Description from './Description';
import Details_technique from './Details_technique';
import Carousel from "react-multi-carousel";
import ContactForm from '../../components/contactForm/ContactForm';
import { useGetProduitsByIdQuery } from '../../store/ProduitsApi';
import { useLocation } from 'react-router-dom';
import QuestionResponse from './QuestionResponse';
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from 'react-share';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../store/Produits';
import DrawerPanier from '../../components/DrawerPanier';
import ShareIcon from '@mui/icons-material/Share';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import Loading from '../../components/Loading';




export default function Product() {
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);

    // for share
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    //////////
    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };


    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    const currentUrl = window.location.origin + location.pathname;
    const { data: product, isLoading: loading, isError } = useGetProduitsByIdQuery(id, 'tt');
    console.log(product)
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const handleAddToCart = () => {
        setIsDrawerOpen(true);
    };

    function getImageUrl(productRef, imageFilenames) {
        const validExtensions = /\.(jpg|jpeg|png|gif|webp)$/i;
        if (imageFilenames && imageFilenames.length > 0) {
            const validImages = imageFilenames.filter(filename => validExtensions.test(filename));
            if (validImages.length > 0) {
                return `https://www.kelmohub.com/product-images/${productRef}/${validImages[0]}`;
            }
        }
        return '/Images/default-image.jpg'; // Default fallback
    }

    const [mainImage, setMainimage] = useState('');
    const [imageUrls, setimageUrls] = useState([]);
    useEffect(() => {
        if (product) {
            const validExtensions = /\.(jpg|jpeg|png|gif|webp)$/i;

            const filteredImages = (product?.ImageFilenames || []).filter(filename =>
                validExtensions.test(filename)
            );

            setMainimage(getImageUrl(product?.ProductRef, filteredImages));
            setimageUrls(
                filteredImages.map(filename => getImageUrl(product?.ProductRef, [filename]))
            );
        }
    }, [product]);
    // Dependency array ensures this runs when `product` changes
    const [activeSection, setActiveSection] = useState('Description');

    if (loading) {
        return <div><Loading /></div>
    }

    function getVideoIdFromUrl(url) {
        const regex = /(?:https?:\/\/(?:www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const matches = url.match(regex);
        return matches && matches[1] ? matches[1] : null;
    }


    return (
        <div className='Productpage'>
            <div className='productpagesection1 my-5'>
                <div className='container-fluid container-md'>
                    <div className='row my-5'>
                        <div className='col-12 col-lg-6'>
                            <div className='d-flex justify-content-start align-items-start'>
                                <div className='d-flex align-items-start gap-3'>
                                    <div className='d-flex flex-column gap-3 rounded'>
                                        {imageUrls.slice(0, 4).map((item, index) => (
                                            <img
                                                onMouseEnter={() => setMainimage(item)}
                                                key={index}
                                                src={item}
                                                alt={`Product image ${index + 1}`}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    cursor: 'pointer',
                                                    border: '1px solid #d7d7d7',
                                                }}
                                                className='rounded'
                                                loading="lazy"
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className='p-2'>
                                    <img
                                        src={mainImage}
                                        alt='Main product'
                                        style={{ width: '100%', aspectRatio: 1, maxWidth: '500px' }}
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                            <div>
                                <div className='d-flex flex-column flex-md-row justify-content-between align-items-start'>
                                    <h1 className='productname title'>{product.ProductLabel} {product.ProductRef}</h1>
                                </div>
                                <div className='shareicons'>
                                    <div>
                                        {/* Main Share Button */}
                                        <IconButton
                                            onClick={handleClick}
                                            style={{
                                                backgroundColor: 'black',
                                                color: 'white',
                                                borderRadius: '50%',
                                                padding: '10px',
                                            }}
                                        >
                                            <ShareIcon fontSize="medium" />
                                        </IconButton>

                                        {/* MUI Menu */}
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'share-button',
                                            }}
                                        >
                                            {/* Facebook Share Option */}
                                            <MenuItem onClick={handleClose}>
                                                <FacebookShareButton url={currentUrl} className="m-1">
                                                    <FacebookIcon size={32} style={{ borderRadius: '10px' }} />
                                                    <span style={{ marginLeft: '10px' }}>Facebook</span>
                                                </FacebookShareButton>
                                            </MenuItem>

                                            {/* WhatsApp Share Option */}
                                            <MenuItem onClick={handleClose}>
                                                <WhatsappShareButton url={currentUrl} className="m-1">
                                                    <WhatsappIcon size={32} style={{ borderRadius: '10px' }} />
                                                    <span style={{ marginLeft: '10px' }}>WhatsApp</span>
                                                </WhatsappShareButton>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </div>

                                <div className='caractiristique d-flex flex-column my-4'>
                                    <div className={isExpanded ? '' : 'collapsed'}>
                                        <p
                                            className='text-start'
                                            dangerouslySetInnerHTML={{
                                                __html: product.shortDescription,
                                            }}
                                        ></p>
                                    </div>
                                    {/*                                    <button onClick={toggleDescription} className="toggle-button">
                                        {isExpanded ? 'Afficher moins' : 'Afficher plus'}
                                    </button>*/}
                                </div>
                                <div className=''>
                                    {product.Price > 0 && <p className='title'>{product.Price} MAD</p>}
                                </div>
                                <div className='row btn_devis_fiche my-3'>
                                    <div className='col-12 col-md-6'>
                                        <div className='dmd_devis'>
                                            <button
                                                style={{ backgroundColor: 'black', color: 'white' }}
                                                onClick={() => {
                                                    const phoneNumber = "+212766074939";
                                                    const message = encodeURIComponent(`Bonjour, je voudrais demander un devis pour : ${currentUrl}`);
                                                    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
                                                }}
                                            >
                                                Demander un devis
                                            </button>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-6'>
                                        <div className='dmd_fiche'>
                                            <button
                                                style={{ backgroundColor: 'black', color: 'white' }}
                                                onClick={() => {
                                                    const phoneNumber = "+212766074939";
                                                    const message = encodeURIComponent(`Bonjour, je voudrais demander une fiche technique pour : ${currentUrl}`);
                                                    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
                                                }}
                                            >
                                                Demander une fiche technique
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='addTopanier'>
                                    <button
                                        onClick={() => {
                                            dispatch(addToCart({
                                                id: product.ProductId,
                                                quantity: 1,
                                                prix: product.Price,
                                                ref: product.ProductRef,
                                                name: product.ProductLabel,
                                                image: getImageUrl(product.ProductRef, product.ImageFilenames)
                                            }));
                                            handleAddToCart();
                                        }}
                                    >Ajouter au panier</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="video-wrapper">
                        <iframe
                            src={`https://www.youtube.com/embed/${getVideoIdFromUrl(product.VideoUrls[0])}?loop=1&playlist=${getVideoIdFromUrl(product.VideoUrls[0])}&controls=1`}
                            frameBorder="0"
                            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="video-container"
                        />
                    </div>
                    <hr />

                    <div className='aboutproduct my-4'>
                        <div className='aboutproductbtns'>
                            <button
                                className={`me-3 ${activeSection === 'Description' ? 'clicked' : ''}`}
                                onClick={() => setActiveSection('Description')}
                            >
                                Description
                            </button>
                            <button
                                className={`me-3 ${activeSection === 'Details_technique' ? 'clicked' : ''}`}
                                onClick={() => setActiveSection('Details_technique')}
                            >
                                Détails technique
                            </button>
                            <button
                                className={`me-3 ${activeSection === 'QuestionResponse' ? 'clicked' : ''}`}
                                onClick={() => setActiveSection('QuestionResponse')}
                            >
                                Questions & réponses
                            </button>
                            {/*<button
                                className={`me-3 ${activeSection === 'Avis' ? 'clicked' : ''}`}
                                onClick={() => setActiveSection('Avis')}
                            >
                                Avis
                            </button>*/}
                        </div>

                        <div>
                            {activeSection === 'Description' && <Description Description={product.longDescription} />}
                            {activeSection === 'Details_technique' && <Details_technique />}
                            {activeSection === 'QuestionResponse' && <QuestionResponse id={id} />}
                        </div>
                    </div>

                    {/*                    <div className='relatedproduits'>
                        <h1 className='title text-center'>Produits liés à cet article</h1>
                        <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={false}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"

                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                        >
                            {[1, 2, 3, 4, 5].map(item => (
                                <div key={item} className="mx-3">
                                    <img src={'/Images/produit.jpg'} className="card-img-top" alt="product" />
                                    <div className="card-body">
                                        <p className="card-text mb-1 related_product_name">SBV240TS Plateau Vide Séparateur</p>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <span className='related_productPrice'>4000 Dhs</span>
                                        </div>
                                        <div className='my-3'>
                                            <button className='addTopanier'>ajouter au panier</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>*/}

                    <div className='contactForsection p-0 p-md-5'>
                        <h2 className='title text-center'>De quoi avez vous besoins ?</h2>
                        <p className='subtitle text-center'>Nous vous répondrons dans les plus brefs délais</p>
                        <ContactForm />
                    </div>
                </div>
            </div>
            <DrawerPanier isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
        </div>
    )
}
