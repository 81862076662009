/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import { Link } from 'react-router-dom'
import './index.css'
import SlideVideo from './SlideVideo'
import { Helmet } from 'react-helmet-async';



export default function NosServices({ seoData }) {

    const popularMachines = [
        { id: 5388, name: 'Ensacheuse auto a 2 peseuses pour produits granuleux 520', slug: 'Ensacheuse auto a 2 peseuses pour produits granuleux 520', img: 'https://www.kelmohub.com/product-images/000.000.576/000.000.576-Ensacheuse%20auto%20a%202%20peseuses%20pour%20produits%20granuleux%20520%201.png' },
        { id: 5232, name: 'convoyeur et support pour le dateur portatif', slug: 'convoyeur-et-support-pour-le-dateur-portatif', img: 'https://www.kelmohub.com/product-images/000.000.420/000.000.420-convoyeur%20et%20support%20pour%20le%20dateur%20portatif%201.png' },
        { id: 4898, name: 'Ensacheuse automatique horizontale flow pack 350', slug: 'Ensacheuse-automatique-horizontale-flow-pack-350', img: 'https://www.kelmohub.com/product-images/000.000.1093/000.000.1093-49%20-%20Ensacheuse%20automatique%20horizontale%20flow%20pack%20350%20(1).png' },
        { id: 5734, name: 'Etiqueteuse automatique pour bouteilles cylindriques', slug: 'Etiqueteuse-automatique-pour-bouteilles-cylindriques', img: 'https://www.kelmohub.com/product-images/000.000.926/000.000.926-Etiqueteuse%20automatique%20pour%20bouteilles%20cylindriques%201.png' },
    ];

    console.log(seoData)

    return (
        <div className='NosServices'>
            <Helmet>
                <title>{seoData.meta_title || 'Default Title'}</title>
                <meta name="description" content={seoData.meta_description || 'Default description'} />
                <meta name="keywords" content={seoData.focus_keyword || 'default keywords'} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="sectionheader mb-2">
                <div className='imagebanner'>
                    <img src={'/Images/nos_service/0 - Banner nos services.png'} alt="Banner" loading="eager" />
                    <div className='titreonBanner'>
                    </div>
                </div>
            </div>

            <div className='sectionicons mb-5'>
                <h1 style={{ color: '#ffffff00', position: 'absolute' }} className=' text-center mb-0'>{seoData.H1}</h1>
                <div>
                    <p style={{ color: '#ffffff00', position: 'absolute' }} className='text-center mb-0'>{seoData.paragraph}</p>
                </div>

                <div className='iconsNosServices d-flex justify-content-center flex-wrap py-2'>
                    <div className="service-item text-center mx-3">
                        <a href="#conseil">
                            <img src='/Images/nos_service/conseil.png' className='service-icon' />
                            <p className='service-title mb-0'>CONSEIL <br />ET ACCOMPAGNEMENT</p>
                        </a>
                    </div>
                    <div className="service-item text-center mx-3">
                        <a href="#livraison">
                            <img src='/Images/nos_service/Livraison.png' className='service-icon' />
                            <p className='service-title mb-0'>LIVRAISON</p>
                        </a>
                    </div>
                    <div className="service-item text-center mx-3">
                        <a href="#livraison">
                            <img src='/Images/nos_service/Installation.png' className='service-icon' />
                            <p className='service-title mb-0'>INSTALLATION</p>
                        </a>
                    </div>
                    <div className="service-item text-center mx-3">
                        <a href="#livraison">
                            <img src='/Images/nos_service/Mise en marche.png' className='service-icon' />
                            <p className='service-title mb-0'>MISE EN MARCHE</p>
                        </a>
                    </div>
                    <div className="service-item text-center mx-3">
                        <a href="#reception">
                            <img src='/Images/nos_service/Reception.png' className='service-icon' />
                            <p className='service-title mb-0'>RECEPTION</p>
                        </a>
                    </div>
                </div>



            </div>
            <div className='container-fluid container-md'>

                <div className='nosServicescontent my-5'>
                    <div className='sectionconseil'>
                        <div id='conseil' className='row align-items-center'>
                            <div className='col-12 col-lg-6 order-2 order-lg-1'>
                                <div className=''>
                                    <p> Nos technico-commerciaux sont à votre
                                        disposition pour vous guider tout au long de votre
                                        processus d'achat et d'optimisation de production.</p>

                                    <ul>
                                        <li><i>Analyse de vos besoins : </i> Nous étudions votre
                                            situation pour vous proposer la solution la plus
                                            adaptée
                                        </li>

                                        <li><i>Proposition sur mesure :  </i>  Nos experts vous
                                            recommandent les machines qui répondent le
                                            mieux à vos attentes.
                                        </li>

                                        <li><i>Suivi personnalisé :  </i> {`Vous bénéficiez d'un
                                    accompagnement sur le long terme pour
                                    garantir votre satisfaction.`}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6 order-1 order-lg-2'>
                                <div>
                                    <img src='/Images/nos_service/1 - Accompagnement et conseil.png' alt='Accompagnement et conseil' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='sectionlivraison'>
                        <div id='livraison' className='row align-items-center'>
                            <div className='col-12 col-lg-6'>
                                <div>
                                    <img src='/Images/nos_service/2 - Livraison.png' alt='Livraison' />
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <div className=''>
                                    <p>{`Nous nous chargeons de la livraison et de l'installation de vos équipements, tout en veillant à leur mise en marche dans les meilleures conditions. `}</p>
                                    <ul>
                                        <li><i>Livraison rapide et sécurisée : </i> Nous nous assurons que vos machines arrivent dans les meilleures conditions.
                                        </li>

                                        <li><i>Installation par des experts :  </i>  Nos techniciens installent vos équipements et effectuent un premier test de fonctionnement.
                                        </li>

                                        <li><i>Mise en marche complète  :  </i> Nous mettons tout en œuvre pour que vos machines soient opérationnelles dès leur arrivée.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='sectionReception '>
                        <div id='reception' className='row align-items-center'>
                            <div className='col-12 col-lg-6 order-2 order-lg-1'>
                                <div className=''>
                                    <p>{`Dans le cadre des marchés publics, nous assurons un service de réception conforme aux exigences des appels d'offres. Nous effectuons une inspection détaillée et vous fournissons un rapport complet de réception. `}</p>
                                    <ul>
                                        <li><i>Conformité aux exigences des marchés publics  : </i> Nous nous assurons que chaque étape de la réception est en accord avec les critères des appels d’offres.
                                        </li>

                                        <li><i>Rapport détaillé de réception  :  </i>   Nous vous transmettons un rapport détaillant la conformité des équipements aux spécifications.
                                        </li>

                                        <li><i>Assistance pour la validation  :  </i> : {`Nous vous accompagnons dans la procédure de validation de la réception par l'entité publique. `}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6 order-1 order-lg-2'>
                                <div>
                                    <img src='/Images/nos_service/5 - Réception.png' alt='Réception' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: '#f1f1f1', borderRadius: '8px' }} className='resourcesEducatives p-3 my-5'>
                    <h2 className='title text-center mb-4'>RESSOURCES EDUCATIVES</h2>
                    <p>Pour vous aider à maximiser l’utilisation de vos équipements, nous mettons à votre disposition une série de ressources pratiques : </p>
                    <ul>
                        <li><i> Vidéos de démonstration : </i>Regardez nos vidéos pour comprendre facilement comment utiliser chaque machine.</li>
                        <li><i> Fiches techniques détaillée : </i> Accédez à des documents techniques complets sur chaque modèle. </li>
                        <li><i> Tutoriels et conseils : </i>  Bénéficiez de nos guides et recommandations pour optimiser vos machines.  </li>
                    </ul>
                    <div className='my-5'>
                        <SlideVideo />
                    </div>
                </div>

                <div className='my-5'>
                    <h2 className='title text-center mb-4'>MACHINES ET EQUIPMENTS SUR COMMANDE </h2>
                    <div className='my-5'>
                        <h3 className='popularmachinestitle'>Popular</h3>
                        <div className='linepopularmachines '></div>
                    </div>
                    <div className='row my-6'>
                        {popularMachines.map(item => (
                            <div key={item.id} className="col-12 col-sm-6 col-md-3">
                                <div className="mb-2">
                                    <Link to={`/produit/${(item.slug)}?id=${item.id}`} className="position-relative">
                                        <img
                                            style={{ aspectRatio: 1, width: "100%" }}
                                            className="card-img-top popularMachines"
                                            loading="lazy"
                                            src={item.img}
                                            alt={item.name}
                                        />
                                    </Link>
                                    <div className="cardbody p-1">
                                        {/*<div className="cardtitle">
                                            <p
                                                style={{
                                                    lineHeight: "25px",
                                                    fontWeight: "600"
                                                }}
                                                className="name_pro_acti_p_mv towLine mb-1"
                                            >
                                                {item.name}
                                            </p>
                                        </div>*/}
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


