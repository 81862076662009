/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// baseUrl: 'https://honeydew-horse-869124.hostingersite.com/backend/public',
// Create an API slice
export const CommunicationApi = createApi({
    reducerPath: 'CommunicationApi',
    baseQuery: fetchBaseQuery({
    // baseUrl: 'http://127.0.0.1:8000/api',
    baseUrl: 'https://smab-co.com/backend/public/api',
    // baseUrl: 'https://mintcream-crocodile-587037.hostingersite.com/public/api',
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('userToken');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCommentsWithReplies: builder.query({
            query: (ProductId) => ({
                url: `/products/${ProductId}/comments`,
                method: 'GET',
            })
        }),

        newComment: builder.mutation({
            query: (newCommentData) => ({
                url: `/top-level/comments`,
                method: 'POST',
                body: newCommentData
            })
        }),

        sendEmail: builder.mutation({
            query: (formData) => ({
                url: `/send-email`,
                method: 'POST',
                body: formData
            })
        }),


        ContactUs: builder.mutation({
            query: (formData) => ({
                url: `/contact-us`,
                method: 'POST',
                body: formData
            })
        }),

        demandeDevis: builder.mutation({
            query: (formData) => ({
                url: `/demande-devis`,
                method: 'POST',
                body: formData
            })
        }),

    }),
});

export const { useGetCommentsWithRepliesQuery, useNewCommentMutation, useSendEmailMutation, useDemandeDevisMutation } = CommunicationApi;
