import './index.css'
export default function ProjectDetails() {
    return (
        <div className="ProjectDetails position-relative my-5">
            <div className="container-fluid container-xxl">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div>
                            <div className="text_discriptif mb-2 p-4">
                                <h1 className="title">Bosch Car Service au service de votre batterie</h1>
                                <p className="subtitle">Lors d’un départ en vacances ou d’un long trajet, il est toujours important de vérifier l’état de son véhicule et tout particulièrement votre batterie. Pour connaître son état et faire le nécessaire si besoin, nos experts Bosch Car Service pourront vous proposer un diagnostic batterie pour partir sur la route en toute tranquillité.</p>
                            </div>

                            <div className='imageprojet p-4'>
                                <img src={'/Images/activites.jpeg'} className="card-img-top" alt="projet" loading="lazy"/>
                            </div>

                            <div className="text_discriptif mb-2 p-4">
                                <h1 className="title">Cuisine et Maison</h1>
                                <p className='subtitle'>
                                    {`VEVOR vous propose des produits faciles à utiliser dans la cuisine, en mettant en avant des conceptions ingénieuses et de haute qualité. Si vous devez avoir un accès fréquent à votre cuisine, ne manquez pas ces outils de cuisine abordables dans diverses catégories, y compris, mais sans s'y limiter, Meubles de Restaurant, Équipements et Fournitures de Restauration, Équipement de Cuisine et Réfrigération et Équipement de Glace. Préparez-vous à profiter d'une préparation rapide, facile et amusante des aliments`}
                                </p>

                                <p className='subtitle'>
                                    {`Toutes sortes de fournitures de cuisine sont disponibles ici, telles que des tables de travail en acier inoxydable, des bourreuses de saucisses électriques, des distillateurs d'alcool, des machines à granulés, etc. Leur apparence attrayante, leurs couleurs élégantes et leur conception multifonctionnelle contribuent à rendre votre cuisine plus distinctive tout en la gardant bien rangée et belle.`}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="Produit_en_relation">
                            <h1 className='title text-center'>Produit en relation</h1>
                            <div style={{ backgroundColor: "#fff" }} className='products py-3'>
                                {[1, 2, 3].map((item, index) => (
                                    <div key={index} className="produit d-flex border flex-column flex-md-row  mb-3">
                                        <div className="" style={{ width: '150px', height: '150px' }}>
                                            <img style={{ width: '150px', height: '150px' }} src={'/Images/produit.jpg'} className="img-fluid rounded-start" alt="project" loading="lazy"/>
                                        </div>
                                        <div className='p-3'>
                                            <div className="">
                                                <h5 className="subtitle">SBV240TS Plateau Vide Séparateur</h5>
                                            </div>
                                            <div>
                                                <button className='addToCart'>Ajouter au panier</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <img style={{ width: '100%',height:'100%' }} src={'/Images/Mob_3___57624ab72ba393bff9f846104d46406b.webp'} alt='projet' loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
