import './index.css';
export default function MentionLegal() {
    return (
        <div style={{ backgroundColor: '#e8eaed' }} className="MentionLegal p-3">
            <div className="container-fluid container-lg">
                <div className="MentionLegalContent">
                    <h1>Mentions légales</h1>
                    <p>Ce Site est édité par la société SOMABRI, Société au capital de 300,000.00 DH dirhams, dont le siège social est situé à 21, bd Lahcen Ou Idder 20000 Casablanca, et dont les informations légales sont les suivantes : </p>
                    <strong>Identité </strong>
                    <ul>
                        <li><i>Registre de Commerce</i> : 31259</li>
                        <li><i>Taxe Professionnelle</i> : 33310350</li>
                        <li><i>IF</i> : 1048301</li>
                        <li><i>CNSS</i> : 1483304</li>
                        <li><i>Téléphone</i> : 0522312056</li>
                        <li><i>N° de déclaration à la CNDP</i> : en cours de traitement</li>
                    </ul>

                    <strong>Modifications des mentions légales </strong>
                    <p className="mb-0">{`Les présentes mentions légales sont consultables en ligne sur le site.`}</p>
                    <p className="mb-0">{`Elles sont susceptibles d'être modifiées sans autre formalité que la mise en ligne d'une nouvelle version modifiée, seule cette dernière version étant retenue.`}</p>
                    <strong>Compétence juridictionnelle et loi applicable </strong>
                    <p>Tout litige portant sur l’interprétation ou l’exécution des présentes sera soumis à la compétence exclusive du Tribunal de Commerce de Casablanca, la loi marocaine étant applicable au fond et à la procédure. </p>
                </div>
            </div>
        </div>
    )
}
