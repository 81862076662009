/* eslint-disable react/prop-types */
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useNavigate } from 'react-router-dom';
export default function NestedSousActivite({ selectedCategory, setShowSousActivites }) {
    const navigate = useNavigate()
    return (
        <div className='Side_nav_sous_avtivites'>
            <div className='Side_nav_sous_avtivites'>
                <ul className='p-0'>
                    <li onClick={() => setShowSousActivites(false)} style={{ background: "#e63812" }} className='d-flex align-items-center title_list p-2 '>
                        <div><ArrowBackIosIcon style={{ color: "#fff" }} className='title_list_back_icon' /></div>
                        <h2 style={{ color: "#fff" }} className='sous_activites_title ms-2 mb-0'>{selectedCategory.name}</h2>
                    </li>

                    <li className='activite_image_side_nav'>
                        <div>
                            <img src={selectedCategory.image} alt={selectedCategory.name} loading="lazy"/>
                        </div>
                    </li>

                    {selectedCategory.subcategories.map((item, index) => (
                        <li key={index} className='py-2 px-3'>
                            <Link to={`/sous-activite/${item.slug}`} type='button' data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasExample"
                                aria-controls="offcanvasExample" onClick={() => {
                                    navigate(`/sous-activite/${item.slug}`)
                                    setShowSousActivites(false)
                                }}>{item.name}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}