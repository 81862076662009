/* eslint-disable react/prop-types */
// /* eslint-disable react/prop-types */
// /* eslint-disable no-unused-vars */
import { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SideNavSousActivites from './SideNavSousActivites';
// import CloseIcon from '@mui/icons-material/Close';



import Drawer from '@mui/material/Drawer';

export default function DrawerPanier({ isDrawerOpen, setIsDrawerOpen, showSousActivites, setShowSousActivites }) {
  const [selectedCategory, setSelectedCategory] = useState({ id: null, name: '', subcategories: [] });

  // Function to handle clicking a category
  const handleCategoryClick = (id, name, slug, image, subcategories) => {
    setSelectedCategory({ id, name, slug, image, subcategories });
    setShowSousActivites(true);
  };
  const categories = [
    {
      id: 6,
      name: 'Packaging',
      icon: '/Images/activites/packaging.png',
      image: '/Images/activites/activites6.jpg',
      slug: "packaging",
      subcategories: [
        { id: 601, name: 'Ensachage', slug: 'Ensachage' },
        { id: 602, name: 'Marquage', slug: 'Marquage' },
        { id: 603, name: 'Etiquetage', slug: 'Etiquetage' },
        { id: 604, name: 'Remplissage et dosage', slug: 'Remplissage-et-dosage' },
        { id: 605, name: 'Scellage', slug: 'Scellage' },
        { id: 606, name: 'Sertissage et Bouchage', slug: 'Sertissage-et-Bouchage' }
      ]
    },
    {
      id: 5,
      name: 'Nettoyage et Séparation',
      icon: '/Images/activites/nettoyage-et-separation.png',
      image: '/Images/activites/activites5.jpg',
      slug: "nettoyage-et-separation",
      subcategories: [
        { id: 501, name: 'Séparation par densité', slug: 'separation-densite' },
        { id: 502, name: 'Nettoyage humide', slug: 'nettoyage-humide' }
      ]
    },
    {
      id: 3,
      name: 'Séchage et torréfaction',
      icon: '/Images/activites/sechage-et-torrefaction.png',
      image: '/Images/activites/activites3.jpg',
      slug: "sechage-et-torrefaction",
      subcategories: [
        { id: 301, name: 'Séchage à l’air', slug: 'sechage-air' },
        { id: 302, name: 'Torréfaction moyenne', slug: 'torrefaction-moyenne' }
      ]
    },
    {
      id: 1,
      name: 'Broyage et mouture',
      icon: '/Images/activites/Broyage-et-mouture.png',
      image: '/Images/activites/activites.jpg',
      slug: "Broyage-et-mouture",
      subcategories: [
        { id: 101, name: 'Mouture fine', slug: 'mouture-fine' },
        { id: 102, name: 'Mouture grossière', slug: 'mouture-grossiere' }
      ]
    },
    {
      id: 2,
      name: 'Extraction des fruits',
      icon: '/Images/activites/Extraction-des-fruits.png',
      image: '/Images/activites/activites2.jpg',
      slug: "Extraction-des-fruits",
      subcategories: [
        { id: 201, name: 'Pressage à froid', slug: 'pressage-a-froid' },
        { id: 202, name: 'Pressage à chaud', slug: 'pressage-a-chaud' }
      ]
    },
    {
      id: 4,
      name: 'Extraction des Huiles',
      icon: '/Images/activites/extraction-des-huiles.png',
      image: '/Images/activites/activites4.jpg',
      slug: "extraction-des-huiles",
      subcategories: [
        { id: 401, name: 'Extraction par solvant', slug: 'extraction-solvant' },
        { id: 402, name: 'Extraction mécanique', slug: 'extraction-mecanique' }
      ]
    }
  ];

  const handleMouseLeave = () => {
    setIsDrawerOpen(false);
    setShowSousActivites(false);
  };

  return (
    <Drawer
      className='drawer_activites'
      anchor="left"
      size="md"
      variant="temporary"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      PaperProps={{
        style: {
          height: '100%',
          overflow: 'auto',
          width: '90%',
          maxWidth: '350px',
        },
      }}
    >
      {/*<div className='d-flex justify-content-end p-3'>
      <CloseIcon sx={{ fontSize:40,cursor:'pointer' }} onClick={() => {setIsDrawerOpen(false)
        setShowSousActivites(false)
      }}/>
    </div>*/}

      <div className='side_nav_activites' onMouseLeave={handleMouseLeave}>
        {!showSousActivites && (
          <ul className='p-0'>
            {categories.map((category) => (
              <li key={category.id}>
                <div
                  className='d-flex justify-content-between align-items-center py-2 px-3'
                  onClick={() => handleCategoryClick(category.id, category.name, category.slug, category.image, category.subcategories)}
                >
                  <div className='d-flex align-items-center justify-content-start'>
                    <img className='me-2' src={category.icon} alt={category.name} loading="lazy" style={{ width: '30px', height: '30px' }} />
                    <p className='activite_name_side_nav mb-0'>{category.name}</p>
                  </div>
                  <span><ArrowForwardIosIcon sx={{ color: '#9a9a9a', fontSize: 20 }} /></span>
                </div>
              </li>
            ))}
          </ul>
        )}

        {/* Conditionally show SideNavSousActivites */}
        {showSousActivites && (
          <div className="">
            <SideNavSousActivites selectedCategory={selectedCategory} setShowSousActivites={setShowSousActivites} />
          </div>
        )}
      </div>
    </Drawer>
  );
}

