
export default function Details_technique() {
    return (
        <div>
            <div className="my-4">
                <h2 className="title py-3">Détails technique</h2>
                {/*<div className="table_Details_technique">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                        <div key={item} className="row mb-2 " >
                            <div className="p-3 col-5 col-md-4 property" style={{ backgroundColor: '#f2f2f2' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>Type décran</p>
                            </div>
                            <div className="p-3 col-7 col-md-6 value" style={{ backgroundColor: '#f9f9f9' }}>
                                <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>32 po / 81,3 cm</p>
                            </div>
                        </div>
                    ))}
                </div>*/}

                <div className="table_Details_technique">
                {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                    <div key={item} className="row mb-2 " >
                        <div className="p-3 col-5 col-md-4 property" style={{ backgroundColor: '#f2f2f2' }}>
                            <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>Type décran</p>
                        </div>
                        <div className="p-3 col-7 col-md-6 value" style={{ backgroundColor: '#f9f9f9' }}>
                            <p className="mb-0" style={{ fontSize: '18px', fontWeight: '600' }}>32 po / 81,3 cm</p>
                        </div>
                    </div>
                ))}
            </div>
            </div>
        </div>
    )
}
