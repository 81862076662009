
import { Outlet } from 'react-router-dom'

import Sidebarr from '../components/navbar/adminComponents/navBar/Sidebarr'
import Navbar from '../components/navbar/adminComponents/navBar/Navbar'

export default function AdminLayout() {
  return (
    <div style={{ backgroundColor:'#f8f8fb',minHeight:'100vh' }}>
        <Navbar />
        <Sidebarr />
        <Outlet />
    </div>
  )
}
