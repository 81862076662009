/* eslint-disable no-unused-vars */
import { Link } from 'react-router-dom';
import './index.css';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import TranslateIcon from '@mui/icons-material/Translate';
import WidgetsSharpIcon from '@mui/icons-material/WidgetsSharp';
import SideNavActivites from './sideNav/SideNavActivites';
import { useState, useEffect } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import { selectCartCount } from '../../../store/Produits';
import axios from 'axios';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { trackSearch } from '../../../services/facebookPixel';


export default function LargeNav() {
    const cartCount = useSelector(selectCartCount);
    const [showSousActivites, setShowSousActivites] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [produits, setProduits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const token = "F(7icy3t(cuF'6+QOFL#=)LOCK=Ht/j#;P@(:YjbkOmDU8#l-4E=hQr*aq*8aerV";

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(searchQuery);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    useEffect(() => {
        if (debouncedQuery) {
            setIsLoading(true);
            setIsError(false);
            axios.get(`https://smabapi.qalqul.io/product/search?name=${debouncedQuery}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
                .then(response => {
                    setProduits(response.data.products);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsError(true);
                    setIsLoading(false);
                });
        } else {
            setProduits([]);
        }
    }, [debouncedQuery]);

    const handleMouseEnter = () => {
        setIsDrawerOpen(true);
    };

    const handleMouseLeave = () => {
        setShowSousActivites(false);
        setIsDrawerOpen(false);
    };

    const toSlug = (label) => {
        return label
            .toLowerCase()
            .replace(/ /g, '-')         // Replace spaces with hyphens
            .replace(/[^\w-]+/g, '');   // Remove all non-word characters
    };
    return (
        <>
            <div className="top_nav_lg py-2 pb-0">
                <div className='container-fluid d-flex justify-content-between align-items-center'>
                    <div className='logo_temp'>
                        <div className='me-3'>
                            <Link to={'/'}>
                                <img alt='logo' src='/Images/LOGO-SMAB-CROP-1.png' style={{ width: '100%', height: '50px' }} loading="eager" />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='middle_navebar py-2'>
                    <div className='container-fluid'>
                        <div className='row justify-content-start align-items-center'>
                            <div className='links col-6 position-relative'>
                                <input
                                    className='w-100 py-1'
                                    type='text'
                                    placeholder='recherche...'
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                {/*                                <select
                                    className='category-select'
                                    value=""
                                >
                                    <option value="">Toutes les catégories</option>
                                    <option key={2} value={2}>
                                        packeging
                                    </option>
                                    <option key={3} value={3}>
                                        packeging
                                    </option>
                                    <option key={4} value={4}>
                                        packeging
                                    </option>
                                    <option key={5} value={5}>
                                        packeging
                                    </option>
                                    <option key={6} value={6}>
                                        packeging
                                    </option>
                                    <option key={7} value={7}>
                                        packeging
                                    </option>
                                </select>*/}
                                {debouncedQuery && (
                                    <div className='search-results bg-white p-2'>
                                        {isLoading ? (
                                            <div className='p-2'>Chargement...</div>
                                        ) : isError ? (
                                            <div className='p-2'>Erreur lors du chargement</div>
                                        ) : produits && produits.length > 0 ? (
                                            produits.map(produit => (
                                                <div className='' key={produit.ProductId}>
                                                    <Link
                                                        style={{ fontSize: '1.1em', fontWeight: '500' }}
                                                        to={`/produit/${toSlug(produit.ProductLabel)}?id=${produit.ProductId}`}
                                                        className='search-result-item'
                                                        onClick={() => {
                                                            setSearchQuery(''); // Clear search input
                                                            trackSearch(produit); // Track product click event
                                                        }}
                                                    >
                                                        <SearchIcon sx={{ color: 'black' }} />
                                                        {produit.ProductLabel}
                                                    </Link>
                                                </div>
                                            ))
                                        ) : (
                                            <div className='p-2'>Aucun produit trouvé</div>
                                        )}
                                    </div>
                                )}

                            </div>
                            <div className='links col-1'>
                                <Link to={'/panier'} className='pannier_icon_lg_nav position-relative'>
                                    <ShoppingCartIcon sx={{ fontSize: 30, color: '#fff' }} />
                                    {cartCount > 0 && <span className='number_aticle_pannier'>{cartCount}</span>}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between third_nave py-0">
                    <div className="d-flex justify-content-start">
                        <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <div className="activites_menu_third_nav justify-content-start d-flex align-items-center px-5">
                                <span className=""><WidgetsSharpIcon /></span>
                                <p>Nos activites</p>
                            </div>
                            <div className={`position-relative side_nave`}>
                                <SideNavActivites showSousActivites={showSousActivites} setShowSousActivites={setShowSousActivites} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                            </div>
                        </span>
                        <div className="">
                            <ul className="d-flex align-items-center justify-content-center menu_item m-0 p-0">
                                <li className="nav-item d-flex align-items-center">
                                    <Link to={'/nos-services'}>Nos services</Link>
                                </li>
                                <li className="nav-item d-flex align-items-center">
                                    <Link to={'/nos-conseils'}>Nos conseils</Link>
                                </li>
                                {/*                                <li className="nav-item d-flex align-items-center">
                                    <Link to={'/'}>Banque à projet</Link>
                                </li>*/}
                                <li className="nav-item d-flex align-items-center">
                                    <Link to={'/a-propos-de-nous'}>A propos de nous</Link>
                                </li>
                            </ul>
                        </div>

                        <div style={{ alignContent: 'end' }} className='d-flex me-2'>
                            <div className='me-2 divmysmablogin'>
                                <Link to={'/login'} className='mysmablogin d-flex justify-content-start align-items-center'>
                                    My SMAB
                                    <AccountCircleIcon sx={{ fontSize: 30, marginLeft: '10px' }} />
                                </Link>
                            </div>

                            <div className='divmysmabsupport me-2'>
                                <Link to={'/contactez-nous'} className='mysmabsupport d-flex justify-content-start align-items-center'>
                                    contact
                                    <ContactSupportIcon sx={{ fontSize: 30, marginLeft: '10px' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
