import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// baseUrl: 'https://mintcream-crocodile-587037.hostingersite.com/backend/public/api',
// Create an API slice
export const ProduitsApi = createApi({
    reducerPath: 'ProduitsApi',
    baseQuery: fetchBaseQuery({
    // baseUrl: 'http://127.0.0.1:8000/api',
    baseUrl: 'https://smab-co.com/backend/public/api',
    // baseUrl: 'https://mintcream-crocodile-587037.hostingersite.com/public/api',

        prepareHeaders: (headers) => {
            const token = "F(7icy3t(cuF'6+QOFL#=)LOCK=Ht/j#;P@(:YjbkOmDU8#l-4E=hQr*aq*8aerV"
            if (token) {
                headers.set('Authorization', `${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getProduitsBySousCategory: builder.query({
            query: (soucategory) => ({
                url: `https://smabapi.qalqul.io/category/${soucategory}/product`,
                method: 'GET',
            }),
            keepUnusedDataFor: 6000,
        }),

        getProduitsById: builder.query({
            query: (id, soucategory) => ({
                url: `https://smabapi.qalqul.io/product/${id}/${soucategory}`,
                method: 'GET',
            })
        }),

        // getProduitByName: builder.query({
        //     query: (name) => ({
        //         url: `https://smabapi.qalqul.io/product/search?name=${name}`,
        //         method: 'GET',
        //     })
        // })


    }),
});

export const { useGetProduitsBySousCategoryQuery, useGetProduitsByIdQuery } = ProduitsApi;
