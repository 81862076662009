import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Badge from '@mui/material/Badge';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Pusher from 'pusher-js';
import './index.css';

export default function NavBar() {
    const [notifications, setNotifications] = useState([]); // Store notifications
    const [notificationCount, setNotificationCount] = useState(0); // Store the count of unread notifications
    const [showDropdown, setShowDropdown] = useState(false); // State to toggle the dropdown visibility

    useEffect(() => {
        const pusher = new Pusher('86f7ada68b3b902feb45', {
            cluster: 'mt1', // Use your Pusher cluster
            forceTLS: true,
        });

        // Subscribe to the admin channel
        const channel = pusher.subscribe('admin-channel');

        // Listen for 'new-comment-posted' event
        channel.bind('new-comment-posted', function (data) {
            // Assuming 'data.comment' contains the comment details and 'data.page_url' contains the URL
            const { comment, page_url } = data;

            // Update notifications state with both comment and page_url
            setNotifications((prevNotifications) => [
                ...prevNotifications,
                {
                    userName: comment.userName,
                    pageUrl: page_url, // Include the page URL
                    comment: comment.content,
                },
            ]);

            // Update the notification count
            setNotificationCount((prevCount) => prevCount + 1);
        });

        // Clean up the subscription when the component unmounts
        return () => {
            pusher.unsubscribe('admin-channel');
        };
    }, []);


    const handleNotificationClick = () => {
        // Toggle the dropdown visibility
        setShowDropdown(!showDropdown);

        // Optionally, reset notifications count when clicked
        if (showDropdown) {
            setNotificationCount(0); // Reset count when the dropdown is opened
        }
    };

    return (
        <div className='admin_nav position-relative py-2'>
            <div className='container-fluid'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='start_content d-flex align-items-center'>
                        <IconButton data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                            <MenuOutlinedIcon sx={{ color: "#fff", fontSize: 30 }} />
                        </IconButton>

                        <div>
                            <img src={'/Images/LOGO-SMAB-CROP-1.png'} style={{ height: '30px' }} alt='logo' loading="eager" />
                        </div>
                    </div>

                    <div className='last_content d-flex align-items-center'>
                        <div>
                            <IconButton onClick={handleNotificationClick}>
                                <Badge badgeContent={notificationCount} color="error">
                                    <NotificationsIcon sx={{ color: '#fff', fontSize: 30 }} />
                                </Badge>
                            </IconButton>
                        </div>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <AccountCircle sx={{ color: '#fff', fontSize: 30 }} />
                            </IconButton>
                        </div>
                    </div>
                </div>

                {/* Notification dropdown */}
                {showDropdown && (
                    <div className='notifications-dropdown p-3'>
                        {notifications.length === 0 ? (
                            <div>Aucune nouvelle notification</div>
                        ) : (
                            notifications.map((notification, index) => (
                                <div key={index}>
                                    <div className=''>
                                        <p className='mb-0'>
                                            <strong>{notification.userName} </strong>
                                            a commenté le produit:
                                        </p>
                                        <a href={notification.pageUrl} target="_blank" rel="noopener noreferrer">
                                            {notification.pageUrl}
                                        </a>
                                    </div>
                                    <div className=' comentContent'>
                                        <p className='mb-0 p-2'>{notification.comment}</p>
                                    </div>
                
                                    {/* Add bottom border conditionally based on the number of notifications */}
                                    {notifications.length > 1 && index !== notifications.length - 1 && (
                                        <div style={{ borderBottom: '1px solid #ccc', margin: '10px 0' }}></div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                )}
                

            </div>
        </div>
    );
}
