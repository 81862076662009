/* eslint-disable react/prop-types */
import { Navigate } from "react-router-dom";
import { useAdminCheckValidityTokenQuery } from "../store/AuthAdminApi";
import { useSelector, useDispatch } from "react-redux";
import { logoutadmin } from "../store/Auth";
import Loading from "../components/Loading";

const AdminPrivateRoute = ({ children }) => {
    const dispatch = useDispatch();
    const adminToken = useSelector((state) => state.Auth.adminToken);
    const { data: checkValidity, isLoading: validityLoading, isError } = useAdminCheckValidityTokenQuery(adminToken);

    if (validityLoading) {
        return <div><Loading /></div>;
    }

    if (isError || (checkValidity && checkValidity.status !== 'success')) {
        dispatch(logoutadmin());
        return <Navigate to="/admin-login" />;
    }

    return children;
};

export default AdminPrivateRoute;
