/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Drawer from "@mui/material/Drawer";
import DrawerLogin from "./DrawerLogin";
import DrawerRegister from "./DrawerRegister";
import {
    useGetCommentsWithRepliesQuery,
    useNewCommentMutation,
} from "../../store/CommunicationApi";
import { useUserCheckValidityTokenQuery } from "../../store/AuthUserApi";

const Comment = ({
    comment,
    onReply,
    showReplyInput,
    setReplyContent,
    replyContent,
    submitReply,
}) => {
    return (
        <div
            className={`comment-item ${comment.parent_id ? "reply" : "question"} mb-4`}
        >
            <div className="comment-header d-flex align-items-center">
                <AccountCircleIcon sx={{ fontSize: 30, color: "#bfbfbf" }} />
                <p className="mb-0 mx-2">{comment.userName || "Anonymous"}</p>
            </div>
            <div className="date-comment">
                <p className="mb-0 date-comment">
                    Commenté à: {new Intl.DateTimeFormat('fr-FR', { month: 'short', day: '2-digit', year: 'numeric' }).format(new Date(comment.created_at)).toUpperCase()}
                </p>
            </div>
            <p className="comment-content">{comment.content}</p>
            <button onClick={() => onReply(comment.id)} className="replybtninput p-0">
                Répondre
            </button>
            {showReplyInput === comment.id && (
                <div className="reply-form mt-3">
                    <textarea
                        value={replyContent}
                        onChange={(e) => setReplyContent(e.target.value)}
                        placeholder="Écrivez votre réponse..."
                        rows="3"
                        className="form-control"
                    ></textarea>
                    <div className="mt-2 d-flex justify-content-end">

                        <button
                            type="submit"
                            onClick={() => submitReply(comment.id)}
                            className="btn btn-primary"
                        >
                            Répondre
                        </button>
                    </div>
                </div>
            )}
            {comment.responses?.length > 0 && (
                <div className="replies">
                    {comment.responses.map((reply) => (
                        <Comment
                            key={reply.id}
                            comment={reply}
                            onReply={onReply}
                            showReplyInput={showReplyInput}
                            setReplyContent={setReplyContent}
                            replyContent={replyContent}
                            submitReply={submitReply}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default function QuestionResponse({ id }) {
    const { data: commentsReplays, isLoading, isError, refetch } =
        useGetCommentsWithRepliesQuery(id);
    const { data: checkuservalidaty,refetch: checkuservalidatyrefetch } = useUserCheckValidityTokenQuery();
    const [newCommentTopLevel] = useNewCommentMutation();
    const [showReplyInput, setShowReplyInput] = useState(null);
    const [replyContent, setReplyContent] = useState("");
    const [formComents, setFormComents] = useState(false);
    const [newComment, setNewComment] = useState("");
    const [isLoginDrawerOpen, setLoginDrawerOpen] = useState(false);
    const [drawerComponent, setDrawerComponent] = useState("login");

    const submitComment = async (e) => {
        e.preventDefault();
        const newCommentData = {
            product_id: id,
            content: newComment,
            created_at: new Date().toISOString(),
            parent_id: null,
            page_url: window.location.href,
        };

        if (!checkuservalidaty) {
            setLoginDrawerOpen(true);
        } else {
            try {
                const response = await newCommentTopLevel(newCommentData);
                if (response.data.status === 201) {
                    refetch();
                    setNewComment("");
                    setFormComents(false);
                }
            } catch (error) {
                console.error("Error submitting comment:", error);
            }
        }
    };


    const handleReply = (commentId) => {
        setShowReplyInput(commentId);
    };

    const submitReply = async (parentId) => {
        if (!replyContent.trim()) return;

        const replyData = {
            product_id: id,
            content: replyContent,
            parent_id: parentId,
            created_at: new Date().toISOString(),
            page_url: window.location.href,
        };

        if (!checkuservalidaty) {
            setLoginDrawerOpen(true);
        } else {
            try {
                const response = await newCommentTopLevel(replyData);
                if (response.data.status === 201) {
                    refetch();
                    setReplyContent("");
                    setShowReplyInput(null);
                }
            } catch (error) {
                console.error("Error submitting reply:", error);
            }
        }
    };

    if (isLoading) return <p>Loading comments...</p>;
    // if (isError) return <p>Error loading comments. Please try again later.</p>;

    return (
        <div className="QuestionResponse">
            <div className="my-3 d-flex flex-column">
                <h1 className="title my-3">Questions & réponses</h1>
            </div>

            <button
                className="posez_qs_btn"
                onClick={() => setFormComents(!formComents)}
            >
                Posez votre question
            </button>

            {formComents && (
                <div className="form_question">
                    <form onSubmit={submitComment} className="my-5">
                        <div className="form_question_head d-flex justify-content-between align-items-center">
                            <h2 className="mb-0">Posez une question</h2>
                        </div>
                        <div className="form_question_body d-flex flex-column">
                            <textarea
                                className="my-2 p-2"
                                rows="8"
                                placeholder="Votre question"
                                style={{ resize: "none" }}
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                            ></textarea>
                        </div>
                        <div className="form_question_btn d-flex justify-content-end">
                            <button className="submitqst mx-2" type="submit">
                                Soumettre
                            </button>
                            <button
                                className="resetqst"
                                type="button"
                                onClick={() => setFormComents(false)}
                            >
                                Annuler
                            </button>
                        </div>
                    </form>
                </div>
            )}

            <div className="comments">
                <div className="section_question my-3 py-3">
                    {commentsReplays?.data?.length > 0 ? (
                        commentsReplays.data.map((comment) => (
                            <Comment
                                key={comment.id}
                                comment={comment}
                                onReply={handleReply}
                                showReplyInput={showReplyInput}
                                setReplyContent={setReplyContent}
                                replyContent={replyContent}
                                submitReply={submitReply}
                            />
                        ))
                    ) : (
                        <p>Aucun commentaire disponible.</p>
                    )}
                </div>
            </div>

            <Drawer
                anchor="bottom"
                PaperProps={{ style: { maxHeight: "80vh", overflow: "auto" } }}
                open={isLoginDrawerOpen}
                onClose={() => setLoginDrawerOpen(false)}
            >
                {drawerComponent === "login" ? (
                    <DrawerLogin
                        setLoginDrawerOpen={setLoginDrawerOpen}
                        setDrawerComponent={setDrawerComponent}
                        checkuservalidatyrefetch={checkuservalidatyrefetch}
                    />
                ) : (
                    <DrawerRegister setDrawerComponent={setDrawerComponent} />
                )}
            </Drawer>
        </div>
    );
}