import LargeNav from "./LargeNav/LargeNav";
import SmallNav from "./SmallNav/SmallNav";

export default function Navbar() {
  return (
    <div className="Navbar">
      <div className="d-none d-xl-block">
        <LargeNav />
      </div>

      <div className="d-block d-xl-none">
        <SmallNav />
      </div>
    </div>
  )
}
