// @ts-nocheck
import React from 'react';
import './index.css';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useSelector } from 'react-redux';  // Import useSelector to access Redux store

const sidebarItems = [
    { id: 1, label: 'Dashboard', icon: <HomeIcon />, path: '/admin' },
    { id: 2, label: 'Orders', icon: <StorefrontIcon />, path: '/admin' },
    { id: 3, label: 'Admins', icon: <SupervisorAccountIcon />, path: '/admin/gestion-admins', roleRequired: 'super_admin'  },
    { id: 4, label: 'SEO', icon: <QueryStatsIcon />, path: '/admin/gestion-seo', roleRequired: 'super_admin' },  // Added roleRequired field
    // Add more items here with their respective icons and paths
];

export default function Sidebarr() {
    const adminRole = useSelector((state) => state.Auth.adminRole); // Assuming 'auth' holds the user info

    return (
        <>
            <div className="admin_offcanvas offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header pb-2">
                    <div className='start_content d-flex align-items-center'>
                        <div>
                            <img src={'/Images/LOGO-SMAB-CROP-1.png'} style={{ width: '100%', maxWidth: '100px' }} alt='logo' loading="eager" />
                        </div>
                        <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                            <CloseOutlinedIcon sx={{ color: "#fff", fontSize: 30 }} />
                        </IconButton>
                    </div>
                </div>
                <div className="offcanvas-body p-0">
                    <div className="list mt-3">
                        <ul className='p-0'>
                            {sidebarItems.map((item) => (
                                // Only render item if it doesn't require super_admin role or if the user has it
                                (!item.roleRequired || item.roleRequired === adminRole) && (
                                    <li
                                        key={item.id}
                                        className={`p-2 my-2 d-flex align-items-end position-relative`}
                                    >
                                        <Link className='d-flex align-items-end position-relative text-decoration-none' to={item.path}>
                                            <div className='me-3 sidenav_icon' style={{ color: '#fff' }}>
                                                {React.cloneElement(item.icon, { sx: { color: '#fff', fontSize: 30 } })}
                                            </div>
                                            <div style={{ color: '#fff', fontSize: '1.1em' }}>{item.label}</div>
                                        </Link>
                                    </li>
                                )
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
