import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../store/AuthUserApi"; // API call for password reset

export default function NewPassword() {
    const location = useLocation();
    const navigate = useNavigate();
    
    // Extract email and code from location state
    const { email, code } = location.state || {};
    
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false); // Loading state

    const [resetPassword] = useResetPasswordMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setMessage("");
        setLoading(true); // Start loading

        if (!newPassword || !confirmPassword) {
            setError("Veuillez remplir tous les champs.");
            setLoading(false);
            return;
        }

        if (newPassword !== confirmPassword) {
            setError("Les mots de passe ne correspondent pas.");
            setLoading(false);
            return;
        }

        try {
            const response = await resetPassword({ email, code, password: newPassword });

            if (response.data.status === 200) {
                setMessage("Mot de passe réinitialisé avec succès.");
                setTimeout(() => navigate("/login"), 2000); // Redirect after success
            }
        } catch (err) {
            setError(err.response?.data?.message || "Une erreur est survenue.");
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center min-vh-100 bg-light">
            <div className="card p-4 shadow" style={{ width: '100%', maxWidth: '400px' }}>
                <h2 style={{  fontSize: "24px", marginBottom: "20px", fontWeight: "bold", textTransform: "none" }} className="text-center mb-4">Créer un nouveau mot de passe</h2>
                
                {message && <div className="alert alert-success">{message}</div>}
                {error && <div className="alert alert-danger">{error}</div>}

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="form-control"
                            placeholder="Nouveau mot de passe"
                            required
                            disabled={loading} // Disable input while loading
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="form-control"
                            placeholder="Confirmer le mot de passe"
                            required
                            disabled={loading} // Disable input while loading
                        />
                    </div>
                    <button type="submit" className="btn btn-danger w-100" disabled={loading}>
                        {loading ? "Réinitialisation en cours..." : "Réinitialiser le mot de passe"}
                    </button>
                </form>
            </div>
        </div>
    );
}
