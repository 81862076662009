/* eslint-disable no-unused-vars */
import './index.css'
import { useGetAdminsQuery } from '../../../store/AuthAdminApi'
import { useGetProduitsBySousCategoryQuery } from '../../../store/ProduitsApi';

export default function Dashboard() {
  const { data: admins } = useGetAdminsQuery();
  const { data: packaging } = useGetProduitsBySousCategoryQuery('packaging');
  // const { data: Extraction_des_fruits } = useGetProduitsBySousCategoryQuery('Extraction des fruits');



  return (
    <div className="DashboardAdmin my-4">
      <div className="container">
        <div className=''>
          <div className="row g-3 categoresStatistics p-3">
            <div className="col-12 col-sm-6 col-lg-3 col-md-4">
              <div style={{ backgroundColor: '#ffe2e5' }} className="p-3 categoryContent">
                <div style={{ maxWidth: '35px', aspectRatio: '1', borderRadius: '50%', backgroundColor: '#fa5a7d' }} className='d-flex justify-content-center align-items-center mb-3'>
                  <i style={{ color: '#fff' }} className="fa-solid fa-box-open"></i>
                </div>
                <strong>Packaging</strong>
                <p className='my-2'>{packaging && packaging.counts} Produits</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 col-md-4">
              <div style={{ backgroundColor: '#fff4de' }} className="p-3 categoryContent">
                <div style={{ maxWidth: '35px', aspectRatio: '1', borderRadius: '50%', backgroundColor: '#fa5a7d' }} className='d-flex justify-content-center align-items-center mb-3'>
                  <i style={{ color: '#fff' }} className="fa-solid fa-box-open"></i>
                </div>
                <strong>Extraction des fruits</strong>
                <p className='my-2'>0 Produits</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 col-md-4">
              <div style={{ backgroundColor: '#dcfce7' }} className="p-3 categoryContent">
                <div style={{ maxWidth: '35px', aspectRatio: '1', borderRadius: '50%', backgroundColor: '#fa5a7d' }} className='d-flex justify-content-center align-items-center mb-3'>
                  <i style={{ color: '#fff' }} className="fa-solid fa-box-open"></i>
                </div>
                <strong>SÉCHACE ET TORRÉFACTION</strong>
                <p className='my-2'>0 Produits</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 col-md-4">
              <div style={{ backgroundColor: '#f3e8ff' }} className="p-3 categoryContent">
                <div style={{ maxWidth: '35px', aspectRatio: '1', borderRadius: '50%', backgroundColor: '#fa5a7d' }} className='d-flex justify-content-center align-items-center mb-3'>
                  <i style={{ color: '#fff' }} className="fa-solid fa-box-open"></i>
                </div>
                <strong>EXTRACTION DES HUILES</strong>
                <p className='my-2'>0 Produits</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 col-md-4">
              <div style={{ backgroundColor: '#ffcf00a3' }} className="p-3 categoryContent">
                <div style={{ maxWidth: '35px', aspectRatio: '1', borderRadius: '50%', backgroundColor: '#fa5a7d' }} className='d-flex justify-content-center align-items-center mb-3'>
                  <i style={{ color: '#fff' }} className="fa-solid fa-box-open"></i>
                </div>
                <strong>NETTOYAGE ET SÉPARATION</strong>
                <p className='my-2'>0 Produits</p>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 col-md-4">
              <div style={{ backgroundColor: '#5d5fef8c' }} className="p-3 categoryContent">
                <div style={{ maxWidth: '35px', aspectRatio: '1', borderRadius: '50%', backgroundColor: '#fa5a7d' }} className='d-flex justify-content-center align-items-center mb-3'>
                  <i style={{ color: '#fff' }} className="fa-solid fa-box-open"></i>
                </div>
                <strong>Broyage et mouture</strong>
                <p className='my-2'>0 Produits</p>
              </div>
            </div>
          </div>
        </div>

        <div className='usersStatistics my-5'>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div style={{ backgroundColor: '#fff',borderRadius:'10px' }} className='GestionAdmins_body p-3'>
                <table className="table align-middle mb-0 bg-white">
                  <thead className="bg-light">
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>{`Rôle d'utilisateur`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(admins?.admins && admins.admins.length > 0) ? (
                      admins.admins.map((admin) => (
                        <tr key={admin.id}>
                          <td>
                            <p className="fw-bold mb-1">{admin.name}</p>
                          </td>
                          <td>
                            <p className="fw-bold mb-1">{admin.email}</p>
                          </td>
                          <td>
                            <p className="fw-bold mb-1">{admin.role}</p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">
                          <p className="text-center">No admins available</p>
                        </td>
                      </tr>
                    )}
                  </tbody>

                </table>
              </div>
            </div>
            <div className='col-12 col-lg-6'></div>
          </div>
        </div>
      </div>
    </div>
  )
}
