/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/jsx-pascal-case */
import Footer_lg from './Footer_lg'
import './index.css'
import Footer_small_lg from './Footer_small_lg'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
      <div className='top_footer p-2'>
        <div className='container-fluid d-flex align-items-center'>
          <Link to={'/'}><img src={'/Images/LOGO-SMAB-CROP-1.png'} style={{ width: '100%', height: '50px', maxWidth: '200px' }} alt='logo' loading="eager"/></Link>
          <h3 className='mb-0 ms-3 ms-md-4'>Des solutions avancées pour une production optimisée.</h3>
        </div>
      </div>
      <div className='footer'>
        <div className='container-fluid container-xl'>
          <span className='d-none d-lg-block'><Footer_lg /></span>
          <span className='d-lg-none'><Footer_small_lg /></span>
          <div className='line mb-3'></div>
          <div>
            <div className='row align-items-center'>
              <div className='col-12 col-md-6 '>
                <p style={{ color:'#3d3d3d',fontWeight:"600" }} className='px-3'>©2025 SMAB Tous Droits Réservés +212 766-074939 (Du Lundi au Vendredi, 08:30-17:00)</p>
              </div>
              <div className='col-12 col-md-6'>
                <div className='d-flex align-items-center justify-content-end p-3'>
                  <img className='ms-2' style={{ width:"51px", height:'36px' }} src={'/Images/logo_cmi.png'} alt='logo cmi' loading="lazy"/>
                  <img className='ms-2' style={{ width:"51px", height:'36px' }} src={'/Images/secure_code_logo.png' } alt='secure code logo' loading="lazy"/>
                  <img className='ms-2' style={{ width:"51px", height:'36px' }} src={'/Images/tn_verified_by_visa.png'} alt='verified by visa' loading="lazy"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
