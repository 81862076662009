import { useState } from "react";
import { Link } from "react-router-dom";

export default function DescriptionComponent() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const itemData = [
    {
      img: "/Images/packaging description/Ensachage.png",
      title: "Ensachage",
      link: "/sous-activite/Ensachage",
      description: "Conditionnez vos produits rapidement et avec précision dans des sachets adaptés.",
    },
    {
      img: "/Images/packaging description/Marquage.png",
      title: "Marquage",
      link: "/sous-activite/Marquage",
      description: "Imprimez durablement les informations essentielles (dates, codes-barres, logos).",
    },
    {
      img: "/Images/packaging description/Sertissage et Bouchage.png",
      title: "Sertissage et Bouchage",
      link: "/sous-activite/Sertissage-et-Bouchage",
      description: "Sécurisez vos produits avec des systèmes de fermeture fiables.",
    },
    {
      img: "/Images/packaging description/Remplissage et dosage.png",
      title: "Remplissage et Dosage",
      link: "/sous-activite/Remplissage-et-Dosage",
      description: "Assurez un dosage précis et un remplissage optimal pour vos liquides, poudres et granulés.",
    },
    {
      img: "/Images/packaging description/Scellage.png",
      title: "Scellage",
      link: "/sous-activite/Scellage",
      description: "Garantissez une fermeture hermétique pour une meilleure conservation.",
    },
    {
      img: "/Images/packaging description/Etiquetage.png",
      title: "Étiquetage",
      link: "/sous-activite/Etiquetage",
      description: "Appliquez vos étiquettes de manière efficace sur tout type de surface.",
    },
  ];

  return (
    <div className="DescriptionComponent">
      <div className="row g-3">
        <div className="col-12 col-md-7 col-lg-6 d-none d-lg-block">
          <div className="d-flex justify-content-center">
            <img style={{ width: "100%", maxWidth: "600px", borderRadius: "10px" }} src="/Images/packaging description/PACKAGING2.png" alt="Packaging" />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div>
            <div className="row g-3 align-items-center">
              <div>
                <h2 style={{ fontSize: "30px", fontWeight: "700", textAlign: "center" }}>
                  Optimisez votre processus de Packaging avec nos solutions performantes
                </h2>
                <p>
                  Nous vous proposons une large gamme de machines de packaging, conçues pour
                  améliorer chaque étape de votre production.
                  Découvrez nos principales catégories :
                </p>
              </div>
              {itemData.map((item, index) => (
                <div
                  key={index}
                  className="col-12 col-sm-6 col-md-4 position-relative"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  style={{ cursor: "pointer" }}
                >
                  <Link to={item.link}  rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                    <div style={{ position: "relative", overflow: "hidden", borderRadius: "10px" }}>
                      <img style={{ width: "100%", transition: "transform 0.3s ease-in-out" }} src={item.img} alt={item.title} />
                      {hoveredIndex === index && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: "rgba(255, 255, 255, 0.85)",
                            color: "black",
                            padding: "10px",
                            fontWeight: 600,
                            textAlign: "center",
                            fontSize: "16px",
                            transition: "opacity 0.3s ease-in-out",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item.description}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              ))}
              <div>
                <p>
                  Nos machines allient efficacité, robustesse et innovation pour vous offrir
                  des solutions sur mesure adaptées à votre activité.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
