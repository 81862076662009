import ReactPixel from "react-facebook-pixel";


// Track Add to Cart Event
export const trackAddToCart = (product) => {
    ReactPixel.track("AddToCart", {
        content_id: product.ref, // Correct property name
        content_name: product.name,
        value: product.price,
    });
};

// Track search Event
export const trackSearch = (product) => {
    console.log(product);
    ReactPixel.track("Search", {
        content_name: product.ProductLabel,
        content_ids: product.ProductId,
        
    });
};

// Track Purchase Event
export const trackPurchase = (order) => {
    ReactPixel.track("Purchase", {
        value: order.total,
        contents: order.items.map((item) => ({
            id: item.id,
            ref: item.ref,
            name: item.name,
            quantity: item.quantity,
            price: item.price,
        })),
    });
};

// Track Custom Event (for anything you want)
export const trackCustomEvent = (eventName, data) => {
    ReactPixel.track(eventName, data);
};
