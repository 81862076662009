import { Link } from "react-router-dom";

export default function BlogId2() {
    return (
        <div className="BlogDetail blogid2">
            <div className="container-fluid container-xl">
                <h1 className="title BlogDetailTitle text-start">{`Révolutionner la Production d'Amlou : Le Rôle des Machines Modernes`}</h1>
                <hr />
                <p className="mb-0">{`Le Maroc est un pays riche en traditions culinaires, et l'une des plus emblématiques est la fabrication de l'amlou, cette pâte à tartiner à base d'amandes, d'huile d'argan et de miel. Grâce aux avancées technologiques et à l'innovation, la production de cette délicatesse se modernise, répondant ainsi à la demande croissante tout en préservant la qualité artisanale qui fait son succès`}</p>

                <ul className="p-0 my-3">
                    <li>
                        <h2>Le Moulin à Meules : Une Révolution pour l’Optimisation de la Production d’Amlou au Maroc </h2>
                        <p>{`La production d’Amlou repose sur des ingrédients de qualité, mais également sur des processus optimisés. L’intégration de machines comme le moulin à beurre a transformé l’industrie. Ces machines, fabriquées entièrement en acier inoxydable et équipées de meules en pierre émeri, sont idéalement conçues pour l’écrasement des produits oléagineux afin d’en extraire des pâtes ou des beurres`}</p>
                        <p>Elles permettent de produire non seulement l’amlou, mais aussi des préparations à base de noix, de noix de cajou, de cacahuètes, de pistaches, et d’autres graines oléagineuses.</p>
                        <img className="my-2" src="/Images/blogs/blogid2amlo.png" alt="machine de la Production d'Amlou" loading="lazy" />
                    </li>

                    <strong>Ces machines modernes offrent plusieurs avantages :</strong>
                    <div className="ps-4">
                        <li>Production optimisée : Elles augmentent la capacité de production tout en réduisant le temps de traitement (30 – 40 kg par heure).</li>
                        <li>Contrôle de qualité : Elles préservent les nutriments des ingrédients tout en assurant une hygiène stricte.</li>
                        <li>Personnalisation : Elles permettent de réguler la viscosité de la préparation selon le degré souhaité, offrant ainsi des textures variées.</li>
                        <li>Facilité d’utilisation et de nettoyage : Leur conception simple réduit les contraintes opérationnelles.</li>
                    </div>
                    <li>
                        <h2>{`Le Rôle de SMAB dans l'Agro-Food au Maroc`}</h2>
                        <p className="mb-0">{`Au Maroc, des acteurs comme SMAB jouent un rôle clé dans l’équipement des producteurs.`}</p>
                        <p className="">{` Ces machines, adaptées à la transformation des graines oléagineuses, offrent une solution pratique pour les artisans, les coopératives et les entreprises cherchant à augmenter leur rendement tout en conservant la qualité artisanale d’Amlou.`}</p>
                        <p>Les machines modernes comme le moulin à beurre permettent de traiter les graines oléagineuses
                            sans altérer leurs propriétés nutritionnelles, tout en garantissant une production durable et
                            respectueuse de l’environnement. Ces machines sont également adaptées pour transformer
                            d’autres types de noix et graines, permettant ainsi de diversifier les produits et de répondre à une
                            demande variée.
                        </p>
                        <div>
                            <iframe
                                width="560"
                                height="315"
                                style={{ maxWidth: "100%" }}
                                src="https://www.youtube.com/embed/tLkbb6xmmGk?si=obtzrCOZAZMIU3Ic"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </li>


                    <li>
                        <h2>{`Vers une Production Plus Responsable et Durable`}</h2>
                        <p>{`L’innovation dans le domaine de la production d’Amlou ne se limite pas aux machines. Elle englobe également des pratiques respectueuses de l’environnement.`}</p>
                        <p>{`Dans le cas de la fabrication industrielle de pâte à tartiner comme celle d’Amlou, les exigences en matière de qualité hygiénique deviennent cruciales. À la différence des méthodes artisanales, les processus industriels s’appuient sur des normes rigoureuses pour garantir la sécurité alimentaire. Ces mesures assurent une production conforme aux normes les plus strictes, tant pour le marché local qu’international.`}</p>
                        <p>{`Grâce à des technologies modernes telles que le moulin à beurre des graines oléagineuses, le Maroc a su faire évoluer la production de cette pâte à tartiner emblématique. En associant traditions culinaires et innovation, l’industrie d’Amlou se positionne comme un modèle de production optimisée et durable. Les graines oléagineuses marocaines continuent ainsi de rayonner, faisant d’Amlou un véritable ambassadeur de la gastronomie locale.`}</p>
                    </li>

                    <div className="">
                        <img src="/Images/blogs/blog Production Amlou2.jpg" alt="machine de la Production d'Amlou" loading="lazy" />
                    </div>
                    <li>
                        <h2>{`Découvrez nos Solutions pour le Broyage et la Mouture`}</h2>
                        <p>{`En tant que référence dans le domaine du broyage et de la mouture, SMAB propose une gamme complète de machines et d’équipements adaptés aux besoins des producteurs des pâtes à tartiner à base de graines oléagineuses, comme celle d’Amlou, de beurre de cacahuète ou de noix de cajou..`}</p>
                        <p>Découvrez <Link to={'/activite/Broyage-et-mouture'}>nos différentes solutions de broyage et mouture </Link>et optimiser votre production.</p>
                        <p>{`Nos équipements allient performance, durabilité et respect des normes hygiéniques les plus strictes.`}</p>
                    </li>
                </ul>
            </div>
        </div>)
}
