import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthAdminProtectedRoute = () => {
    const isAuthenticated = useSelector((state) => state.Auth.adminToken);

    if (isAuthenticated) {
        return <Navigate to="/admin" />;
    }

    return <Outlet />;
};

export default AuthAdminProtectedRoute;
