/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NestedSousActivite from './NestedSousActivite';

export default function SmallNav() {
  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showSousActivites, setShowSousActivites] = useState(false);

  const categories = [
    {
      id: 6,
      name: 'packaging',
      image: '/Images/activites/activites6.jpg',
      slug: "packaging",
      subcategories: [
        { id: 601, name: 'Ensachage', slug: 'Ensachage' },
        { id: 602, name: 'Marquage', slug: 'Marquage' },
        { id: 603, name: 'Etiquetage', slug: 'Etiquetage' },
        { id: 604, name: 'Remplissage et dosage', slug: 'Remplissage-et-dosage' },
        { id: 605, name: 'Scellage', slug: 'Scellage' },
        { id: 606, name: 'Sertissage et Bouchage', slug: 'Sertissage-et-Bouchage' }
      ]
    },
    {
      id: 5,
      name: 'Nettoyage et Séparation',
      image: '/Images/activites/activites5.jpg',
      slug: "nettoyage-et-separation",
      subcategories: [
        { id: 501, name: 'Séparation par densité', slug: 'separation-densite' },
        { id: 502, name: 'Nettoyage humide', slug: 'nettoyage-humide' }
      ]
    },
    {
      id: 3,
      name: 'Séchage et torréfaction',
      image: '/Images/activites/activites3.jpg',
      slug: "sechage-et-torrefaction",
      subcategories: [
        { id: 301, name: 'Séchage à l’air', slug: 'sechage-air' },
        { id: 302, name: 'Torréfaction moyenne', slug: 'torrefaction-moyenne' }
      ]
    },
    {
      id: 1,
      name: 'Broyage et mouture',
      image: '/Images/activites/activites.jpg',
      slug: "Broyage-et-mouture",
      subcategories: [
        { id: 101, name: 'Mouture fine', slug: 'mouture-fine' },
        { id: 102, name: 'Mouture grossière', slug: 'mouture-grossiere' }
      ]
    },
    {
      id: 2,
      name: 'Extraction des fruits',
      image: '/Images/activites/activites2.jpg',
      slug: "Extraction-des-fruits",
      subcategories: [
        { id: 201, name: 'Pressage à froid', slug: 'pressage-a-froid' },
        { id: 202, name: 'Pressage à chaud', slug: 'pressage-a-chaud' }
      ]
    },
    {
      id: 4,
      name: 'Extraction des Huiles',
      image: '/Images/activites/activites4.jpg',
      slug: "extraction-des-huiles",
      subcategories: [
        { id: 401, name: 'Extraction par solvant', slug: 'extraction-solvant' },
        { id: 402, name: 'Extraction mécanique', slug: 'extraction-mecanique' }
      ]
    }
  ];
  

  // Function to handle category selection
  const handleCategoryClick = (category) => {
    setSelectedCategory(category); 
    setShowSousActivites(true); 
  };

  const closeDrawer = () => {
    const offcanvas = document.getElementById('offcanvasExample');
    const bsOffcanvas = bootstrap.Offcanvas.getInstance(offcanvas);
    if (bsOffcanvas) {
      bsOffcanvas.hide();
    }
  };

  return (
    <div className='top_nav_lg smallNav_side'>
      <div className='container-fluid d-flex justify-content-between align-items-center p-2'>
        <div className='logo_temp'>
          <div className='me-3'>
            <Link to={'/'}>
              <img
                alt='logo'
                src='/Images/LOGO-SMAB-CROP-1.png'
                style={{ width: '100%', height: '50px', maxWidth: '200px' }}
                loading="eager"
              />
            </Link>
          </div>
        </div>
        <button
          className="btn d-flex justify-content-center align-items-center"
          style={{ border: "3px solid #fff",width:'50px', }}
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <MenuIcon sx={{ color: '#fff', fontSize: '2em' }} />
        </button>
      </div>

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div
          style={{ height: '73px' }}
          className="offcanvas-header d-flex justify-content-between p-3"
        >
          <img
            alt='logo'
            src='/Images/LOGO-SMAB-CROP-1.png'
            style={{ width: '100%', height: '50px', maxWidth: '200px' }}
            loading="eager"
          />
          <CloseIcon
            sx={{ color: '#fff', fontSize: 40, cursor: 'pointer' }}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body sideitemlink p-0">

          {
            showSousActivites ? (
              <NestedSousActivite
                selectedCategory={selectedCategory}
                setShowSousActivites={setShowSousActivites}
              />
            ) : (
              <ul className='p-0'>
                <li
                  className='d-flex align-items-center'
                  onClick={() => setShowCategories(!showCategories)}
                  style={{ cursor: 'pointer' }}
                >
                  <span>Nos activités</span>
                  <KeyboardArrowRightIcon />
                </li>
                <ul
                  className={`category-list sideitemcategory ${showCategories ? 'expanded' : ''}`}
                >
                  {categories.map((category) => (
                    <li key={category.id} onClick={() => handleCategoryClick(category)}>
                      <span>{category.name}</span>
                    </li>
                  ))}
                </ul>
                <li>
                  <Link to={'/nos-services'}>Nos services</Link>
                </li>
                <li>
                  <Link to={'/'}>Banque à projet</Link>
                </li>
                <li>
                  <Link to={'/nos-conseils'}>Nos conseils</Link>
                </li>
                <li>
                  <Link to={'/a-propos-de-nous'}>A propos de nous</Link>
                </li>

                <li>
                <Link to={'/contactez-nous'}>contactez-nous</Link>
              </li>
              </ul>
            )
          }
        </div>
      </div>

    </div>
  );
}
