import { useState } from 'react';
import './index.css';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'; // Import MUI components
import { useGetAdminsQuery, useAddAdminMutation, useUpdateAdminMutation, useDeleteAdminMutation } from '../../../store/AuthAdminApi'; // Assuming you have a delete mutation

export default function GestionAdmins() {
    const [showModal, setShowModal] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        role: 'admin',
    });
    const [alert, setAlert] = useState({ open: false, type: '', message: '' }); // State for Snackbar alerts
    const [showDeleteDialog, setShowDeleteDialog] = useState(false); // State for delete confirmation dialog
    const [searchQuery, setSearchQuery] = useState(''); // State for search input

    const { data: admins, refetch } = useGetAdminsQuery();
    const [addAdmin] = useAddAdminMutation();
    const [updateAdmin] = useUpdateAdminMutation();
    const [deleteAdmin] = useDeleteAdminMutation(); // Assuming you have this mutation

    // Handle closing the Snackbar alert
    const handleCloseAlert = () => setAlert({ ...alert, open: false });

    // Handle modal close
    const handleClose = () => {
        setShowModal(false);
        setSelectedAdmin(null);
        setFormData({
            name: '',
            email: '',
            password: '',
            role: 'admin',
        });
    };

    // Handle modal open for adding a new admin
    const handleShow = () => {
        setSelectedAdmin(null);
        setFormData({
            name: '',
            email: '',
            password: '',
            role: 'admin',
        });
        setShowModal(true);
    };

    // Handle modal open for editing an admin
    const handleEdit = (admin) => {
        setSelectedAdmin(admin);
        setFormData({
            name: admin.name,
            email: admin.email,
            password: '',
            role: admin.role,
        });
        setShowModal(true);
    };

    // Handle form input changes
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = async () => {
        try {
            if (selectedAdmin) {
                // Update existing admin
                const response = await updateAdmin({ id: selectedAdmin.id, ...formData });
                if (response.data.status === 200) {
                    refetch(); // Refetch admin list after update
                    setAlert({ open: true, type: 'success', message: 'Admin updated successfully!' });
                }
            } else {
                // Add new admin
                const response = await addAdmin(formData);
                if (response.data.status === 201) {
                    refetch(); // Refetch admin list after addition
                    setAlert({ open: true, type: 'success', message: 'Admin added successfully!' });
                }
            }
            handleClose(); // Close the modal after successful operation
        } catch (error) {
            setAlert({ open: true, type: 'error', message: 'Error submitting form!' });
            console.error('Error submitting form:', error);
        }
    };

    // Handle delete confirmation dialog
    const handleDelete = (admin) => {
        setSelectedAdmin(admin);
        setShowDeleteDialog(true);
    };

    // Handle admin deletion
    const handleConfirmDelete = async () => {
        try {
            const response = await deleteAdmin(selectedAdmin.id);
            if (response.data.status === 200) {
                refetch(); // Refetch admin list after deletion
                setAlert({ open: true, type: 'success', message: 'Admin deleted successfully!' });
            }
            setShowDeleteDialog(false); // Close the delete confirmation dialog
        } catch (error) {
            setAlert({ open: true, type: 'error', message: 'Error deleting admin!' });
            console.error('Error deleting admin:', error);
        }
    };

    // Handle closing the delete confirmation dialog
    const handleCancelDelete = () => {
        setShowDeleteDialog(false);
        setSelectedAdmin(null);
    };

    // Handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter admins based on search query (name, email, or role)
    const filteredAdmins = admins?.admins?.filter((admin) => {
        return (
            admin.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            admin.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            admin.role.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    return (
        <div className='GestionAdmins'>
            <div className='container-fluid px-lg-5'>
                <div className='GestionAdmins_header d-flex justify-content-between py-4'>
                    <h1 className='title'>Gestion des admins</h1>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='searchAdmin'>
                            <input
                                placeholder="Rechercher"
                                type='text'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <button><SearchIcon /></button>
                        </div>
                        <button className='addAdmin' onClick={handleShow}>
                            Ajouter un admin
                        </button>
                    </div>
                </div>
                <div className='GestionAdmins_body'>
                    <table className="table align-middle mb-0 bg-white">
                        <thead className="bg-light">
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>{`Rôle d'utilisateur`}</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredAdmins?.map((admin) => (
                                <tr key={admin.id}>
                                    <td>
                                        <p className="fw-bold mb-1">{admin.name}</p>
                                    </td>
                                    <td>
                                        <p className="fw-bold mb-1">{admin.email}</p>
                                    </td>
                                    <td>
                                        <p className="fw-bold mb-1">{admin.role}</p>
                                    </td>
                                    <td className='actionscell'>
                                        <button className='me-3 p-0' onClick={() => handleEdit(admin)}>
                                            <SettingsIcon sx={{ color: '#e63812', fontSize: 30 }} />
                                        </button>

                                        <button className='p-0' onClick={() => handleDelete(admin)}>
                                            <DeleteIcon sx={{ color: '#e63812', fontSize: 30 }} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal */}
            {showModal && (
                <div className="modal fade show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{selectedAdmin ? `Modifier l'admin` : `Ajouter un admin`}</h5>
                                <button type="button" className="btn-close" onClick={handleClose}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Nom</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Entrez le nom"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Entrez l'email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Mot de passe</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            placeholder="Entrez le mot de passe"
                                            value={formData.password}
                                            onChange={handleChange}
                                            disabled={selectedAdmin !== null} // Disable password field when editing an admin
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="role" className="form-label">Rôle</label>
                                        <select
                                            id="role"
                                            className="form-select"
                                            value={formData.role}
                                            onChange={handleChange}
                                        >
                                            <option value="admin">Admin</option>
                                            <option value="super_admin">Super Admin</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>Fermer</button>
                                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                    {selectedAdmin ? `Modifier` : `Ajouter`}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete confirmation dialog */}
            <Dialog open={showDeleteDialog} onClose={handleCancelDelete}>
                <DialogTitle>Confirmer la suppression</DialogTitle>
                <DialogContent>
                    <p>Êtes-vous sûr de vouloir supprimer cet admin ?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">Annuler</Button>
                    <Button onClick={handleConfirmDelete} color="secondary">Supprimer</Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for alerts */}
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
