/* eslint-disable react/prop-types */
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
export default function SideNavSousActivites({ selectedCategory, setShowSousActivites }) {
    console.log(selectedCategory)
    return (
        <div className='Side_nav_sous_avtivites'>
            <ul className='p-0'>
                <li onClick={() => setShowSousActivites(false)} style={{ background: "#e63812" }} className='d-flex align-items-center title_list p-2'>
                    <span><ArrowBackIosIcon style={{ color: "#fff" }} className='title_list_back_icon' /></span>
                    <h2 style={{ color: "#fff" }} className='sous_activites_title ms-2 mb-0'>{selectedCategory.name}</h2>
                </li>

                <li className='activite_image_side_nav'>
                    <Link className='' to={`/activite/${selectedCategory.slug}`}>
                        <img src={selectedCategory.image} alt={selectedCategory.name} loading="lazy" />
                    </Link>
                </li>

                {selectedCategory.subcategories.map((item, index) => (
                    <li key={index} className='sousactivites_link position-relative py-2 px-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className='activite_name_side_nav mb-0'>{item.name}</p>
                            <span><ArrowForwardIosIcon /></span>
                        </div>
                        <Link className='' to={`/sous-activite/${item.slug}`}></Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}