/* eslint-disable react/prop-types */
import { useState } from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
  const slides = [
    {
      image: "/Images/industrielle/agroFood.jpg",
      title: "Agro-food",
      description: "Solutions complètes pour l'optimisation et la transformation des produits destinés à l’alimentation humaine et animale, visant à améliorer l'efficacité et la qualité de vos opérations.",
    },
    {
      image: "/Images/industrielle/pharmaceutique.jpg",
      title: "Pharmaceutique",
      description: "Technologies avancées pour le conditionnement et la manipulation des produits pharmaceutiques, garantissant des normes de sécurité et de précision élevées Technologies avancées pour le conditionnement et la manipulation des produits pharmaceutiques, garantissant des normes de sécurité et de précision élevées.",
    },
    {
      image: "/Images/industrielle/cosmetique.jpg",
      title: "Cosmétique",
      description: "Équipements pour la production et le conditionnement de produits cosmétiques, assurant une conformité rigoureuse aux standards de qualité et de sécurité.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  return (
    <Slider {...settings}>
      {slides.map((item, index) => (
        <SlideItem key={index} item={item} />
      ))}
    </Slider>
  );
}

function SlideItem({ item }) {
  const [showFullText, setShowFullText] = useState(false);

  return (
    <div style={{  background: 'linear-gradient(to right, white, beige)',borderRadius: "8px" }} className="d-flex row m-0 align-items-center">
      {/* Image Section */}
      <div className="col-12 col-md-6 image">
        <img
          src={item.image}
          alt={item.title}
          style={{ width: "100%", maxWidth: "500px", height: "auto", borderRadius: "8px" }}
          loading="lazy"
        />
      </div>

      {/* Description Section */}
      <div className="simpleslide mt-5 mt-md-0 col-12 col-md-6 description text-center text-md-start">
        <strong className="title">{item.title}</strong>
        <p
          className={`mt-0  ${!showFullText ? "text-truncated" : ""}`}
          style={{ position: "relative", overflow: "hidden" }}
        >
          {item.description}
        </p>
        <button
          onClick={() => setShowFullText((prev) => !prev)}
          className="btn btn-primary mt-3"
        >
          {showFullText ? "Afficher moins" : "Afficher plus"}
        </button>
      </div>
    </div>
  );
}
