import { useState } from "react";
import { useForgetPasswordMutation, useVerifyCodeMutation } from "../../store/AuthUserApi";
import { useNavigate } from "react-router-dom";

export default function ForgetPassword() {
    const [email, setEmail] = useState("");
    const [code, setCode] = useState(["", "", "", "", "", ""]);
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false); // New state for loading

    const [forgetPassword] = useForgetPasswordMutation();
    const [verifyCode] = useVerifyCodeMutation();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");
        setLoading(true); // Disable form

        try {
            const response = await forgetPassword({ email });
            if (response.data.status === 201) {
                setShowCodeInput(true);
            }
        } catch (err) {
            setError(err.response?.data?.message || "Une erreur est survenue.");
        } finally {
            setLoading(false); // Re-enable form
        }
    };

    const handleCodeChange = (index, value) => {
        if (!/^\d*$/.test(value)) return;
        let newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value && index < 5) {
            document.getElementById(`code-${index + 1}`).focus();
        }
    };

    const handleCodeSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");
        setLoading(true); // Disable form

        const verificationCode = code.join("");

        try {
            const response = await verifyCode({ email, code: verificationCode });
            if (response.data.status === 200) {
                navigate("/new-password", {
                    state: { email, code: verificationCode }
                });
            }
        } catch (err) {
            setError(err.response?.data?.message || "Code invalide. Veuillez réessayer.");
        } finally {
            setLoading(false); // Re-enable form
        }
    };

    return (
        <div className="ForgetPassword" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <div className="container-fluid container-lg" style={{
                background: "#f9f9f9", padding: "20px", borderRadius: "10px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                width: "100%", maxWidth: "400px", textAlign: "center"
            }}>
                <h1 style={{ fontSize: "24px", marginBottom: "20px", fontWeight: "bold", textTransform: "none" }}>
                    {showCodeInput ? "Entrez le code" : "Récupérer le mot de passe"}
                </h1>

                {message && <div style={{ color: "green", marginBottom: "10px" }}>{message}</div>}
                {error && <div style={{ color: "red", marginBottom: "10px" }}>{error}</div>}

                {!showCodeInput ? (
                    <form onSubmit={handleSubmit}>
                        <div style={{ marginBottom: "15px" }}>
                            <input
                                type="email"
                                name="email"
                                placeholder="Entrez votre email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                disabled={loading} // Disable input when loading
                                style={{
                                    width: "100%", padding: "10px", borderRadius: "5px",
                                    border: "1px solid #ccc", outline: "none", background: loading ? "#e0e0e0" : "white"
                                }}
                            />
                        </div>
                        <div>
                            <button
                                type="submit"
                                disabled={loading} // Disable button when loading
                                style={{
                                    backgroundColor: loading ? "#ccc" : "#e63812",
                                    color: "white", padding: "10px 20px", border: "none",
                                    borderRadius: "5px", cursor: loading ? "not-allowed" : "pointer",
                                    width: "100%", fontSize: "16px", fontWeight: "bold"
                                }}
                            >
                                {loading ? "Envoi en cours..." : "Envoyer le code"}
                            </button>
                        </div>
                    </form>
                ) : (
                    <div>
                        <form onSubmit={handleCodeSubmit}>
                            <p style={{ marginBottom: "10px", fontSize: "14px", color: "#555" }}>
                                Entrez le code à 6 chiffres envoyé à votre email.
                            </p>
                            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                {code.map((digit, index) => (
                                    <input
                                        key={index}
                                        id={`code-${index}`}
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleCodeChange(index, e.target.value)}
                                        disabled={loading} // Disable input when loading
                                        style={{
                                            width: "40px", height: "50px", textAlign: "center",
                                            fontSize: "18px", borderRadius: "5px", border: "1px solid #ccc",
                                            outline: "none", background: loading ? "#e0e0e0" : "white"
                                        }}
                                    />
                                ))} 
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={loading} // Disable button when loading
                                    style={{
                                        backgroundColor: loading ? "#ccc" : "#e63812",
                                        color: "white", padding: "10px 20px", border: "none",
                                        borderRadius: "5px", cursor: loading ? "not-allowed" : "pointer",
                                        width: "100%", fontSize: "16px", fontWeight: "bold",
                                        marginTop: "20px"
                                    }}
                                >
                                    {loading ? "Vérification..." : "Envoyer"}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
}
