/* eslint-disable eqeqeq */
// @ts-nocheck
import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import './index.css';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLogoutMutation } from '../../store/AuthUserApi';
import { logoutUser } from '../../store/Auth';
import { useDispatch } from 'react-redux';

export default function Index() {
    const [listVisibility, setListVisibility] = useState({
        Mon_compte: true,
        Mes_actifs: true,
        Mes_projets: false,
        Mes_commandes: false
    });
    const dispatch = useDispatch();

    const [logout] = useLogoutMutation();
    const handleLogout = async () => {
        try {
            const response = await logout();
            if (response.data.status === 200) {
                dispatch(logoutUser());
            }
        } catch {
            console.log('error');
        }
    }
    const toggleListVisibility = (list) => {
        setListVisibility(prev => ({ ...prev, [list]: !prev[list] }));
    };



    return (
        <div className='user_space m-0 m-lg-5 position-relative' >
            <div className='logoutUser'>
                <button onClick={handleLogout} className='btn btn-danger'>
                    < LogoutIcon />
                </button>
            </div>
            <div className='container-fluid '>
                <div className='row'>
                    <div className='col-lg-4 col-xl-3 d-lg-block'>
                        <div className='side_menu_user  '>
                            <div className='center_information p-3'>
                                <Link className='' to={'/mon-compte'}>{`Centre d'information`}</Link>
                            </div>

                            <div className='Mon_compte px-4'>
                                <div onClick={() => toggleListVisibility('Mon_compte')} className='group_links_name mb-2 d-flex justify-content-between'>
                                    <p className='mb-0'>Mon compte</p>
                                    <span>
                                        <RemoveIcon className={listVisibility.Mon_compte ? 'dropdown' : 'd-none dropdown'} />
                                        <AddIcon className={listVisibility.Mon_compte ? 'd-none dropdown' : 'dropdown'} />
                                    </span>
                                </div>
                                {listVisibility['Mon_compte'] && (
                                    <ul className='ps-2'>
                                        <li>
                                            <Link to={'/mon-compte/adresses'}>Mes adresses</Link>
                                        </li>

                                        <li>
                                            <Link to={'/mon-compte/parametres'}>Paramètres</Link>
                                        </li>

                                        <li>
                                            <Link to={'/mon-compte/mot-de-passe'}>Mot de passe</Link>
                                        </li>


                                    </ul>
                                )
                                }
                            </div>

                            <div className='Mes_actifs  px-4'>
                                <div onClick={() => toggleListVisibility('Mes_actifs')} className='group_links_name mb-2 d-flex justify-content-between'>
                                    <p className='mb-0'>Mes actifs</p>
                                    <span>
                                        <RemoveIcon className={listVisibility.Mes_actifs ? 'dropdown' : 'd-none dropdown'} />
                                        <AddIcon className={listVisibility.Mes_actifs ? 'd-none dropdown' : 'dropdown'} />
                                    </span>
                                </div>
                                {listVisibility['Mes_actifs'] && (
                                    <ul className='ps-2'>
                                        <li>
                                            <Link to={'/'}>Mes codes de réduction</Link>
                                        </li>

                                        <li>
                                            <Link to={'/'}>Mes points de fidélité</Link>
                                        </li>

                                    </ul>
                                )}
                            </div>

                            <div className='Mes_projets px-4'>
                                <div onClick={() => toggleListVisibility('Mes_projets')} className='group_links_name mb-2 d-flex justify-content-between'>
                                    <p className='mb-0'>Mes projets</p>
                                    <span>
                                        <RemoveIcon className={listVisibility.Mes_projets ? 'dropdown' : 'd-none dropdown'} />
                                        <AddIcon className={listVisibility.Mes_projets ? 'd-none dropdown' : 'dropdown'} />
                                    </span>
                                </div>
                                {listVisibility['Mes_projets'] && (
                                    <ul className='ps-2'>
                                        <li>
                                            <Link to={'/'}>Mes projets favoris</Link>
                                        </li>

                                        <li>
                                            <Link to={'/'}>Mes simulations de projets</Link>
                                        </li>

                                    </ul>
                                )}
                            </div>

                            <div className='Mes_commandes px-4'>
                                <div onClick={() => toggleListVisibility('Mes_commandes')} className='group_links_name mb-2 d-flex justify-content-between'>
                                    <p className='mb-0'> Mes commandes</p>
                                    <span>
                                        <RemoveIcon className={listVisibility.Mes_commandes ? 'dropdown' : 'd-none dropdown'} />
                                        <AddIcon className={listVisibility.Mes_commandes ? 'd-none dropdown' : 'dropdown'} />
                                    </span>
                                </div>
                                {listVisibility['Mes_commandes'] && (
                                    <ul className='ps-2'>
                                        <li>
                                            <Link to={'/mon-compte/Historique-des-commandes'}>Mes commandes</Link>
                                        </li>

                                        <li>
                                            <Link to={'/'}>Commandes en attente</Link>
                                        </li>

                                        <li>
                                            <Link to={'/'}>Commandes expédiées</Link>
                                        </li>

                                        <li>
                                            <Link to={'/'}>Commandes retournées</Link>
                                        </li>

                                        <li>
                                            <Link to={'/'}>Demande</Link>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-8 col-xl-9 content_user_space'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div >
    );
}



