/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { trackAddToCart } from "../services/facebookPixel";

const initialState = {
  produits: [],
  cart: JSON.parse(localStorage.getItem('cart')) || [],
}

export const Produits = createSlice({
  name: 'Produits',
  initialState: initialState,
  reducers: {
    setProduits: (state, action) => {
      state.produits = action.payload
    },


    addToCart: (state, action) => {
      const item = action.payload;
      const existingItem = state.cart.find(cartItem =>
        cartItem.ref === item.ref 
      );
      
      if (existingItem) {
        existingItem.quantity += item.quantity;
      } else {
        state.cart.push(item);
        // Track AddToCart event ONLY when new item is added
        trackAddToCart({
          ref: item.ref,  // Fix property names
          name: item.name,
          price: item.prix  // Fix price key (your payload uses 'prix')
        });
      }
      
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },

    increaseQuantity: (state, action) => {
      const { id } = action.payload;
      const item = state.cart.find(cartItem =>
        cartItem.id === id 
      );
      if (item) {
        item.quantity += 1;
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }
    },

    decreaseQuantity: (state, action) => {
      const { id } = action.payload;
      const item = state.cart.find(cartItem =>
        cartItem.id === id 
      );

      if (item && item.quantity > 1) {
        item.quantity -= 1;
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }
    },

    removeFromCart: (state, action) => {
      const { id } = action.payload;
      state.cart = state.cart.filter(cartItem =>
        !(cartItem.id === id )
      );
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },

    removeAllFromCart: (state, action) => {
      state.cart = [];
      localStorage.setItem('cart', JSON.stringify(state.cart));
    }
  }
});



export const { setProduits, addToCart, increaseQuantity, decreaseQuantity, removeFromCart, removeAllFromCart  } = Produits.actions;
export default Produits.reducer;

export const selectCartCount = (state) => {
  return state.Produits.cart.length;
};

export const selectCartTotal = (state) => {
  return state.Produits.cart.reduce((total, item) => total + item.quantity * item.prix, 0);
};

