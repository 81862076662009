import { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import './index.css';
import { useSendSeoContentMutation, useGetPagesQuery } from '../../../store/SeoApi';
import SystemAlert from './SystemAlert';

export default function ManageSeo() {
    const [formData, setFormData] = useState({
        page_id: '',
        focusKeyword: '',
        h1: '',
        paragraphe: '',
        metaDescription: '',
        metaTitle: '',
    });

    const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });
    const { data: pages, refetch: refetchPages } = useGetPagesQuery();
    const [sendSeoContent] = useSendSeoContentMutation();

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handlePageSelect = (e) => {
        const selectedPageId = e.target.value;
        const selectedPage = pages.find((page) => page.page_id === parseInt(selectedPageId));

        setFormData({
            page_id: selectedPageId,
            focusKeyword: selectedPage?.focus_keyword || '',
            h1: selectedPage?.H1 || '',
            paragraphe: selectedPage?.paragraph || '',
            metaDescription: selectedPage?.meta_description || '',
            metaTitle: selectedPage?.meta_title || '',
        });
    };

    const validateData = () => {
        const { focusKeyword, h1, paragraphe, metaDescription } = formData;

        if (!focusKeyword.trim()) {
            return { valid: false, message: 'Le mot-clé principal ne peut pas être vide.' };
        }

        // Title length and keyword check
        if (h1.length > 60) {
            return { valid: false, message: 'Le titre (H1) ne doit pas dépasser 60 caractères.' };
        }
        if (!h1.toLowerCase().includes(focusKeyword.toLowerCase())) {
            return { valid: false, message: 'Le mot-clé principal doit être inclus dans le titre (H1).' };
        }

        // Meta description length and keyword check
        if (metaDescription.length > 160) {
            return { valid: false, message: 'La meta description ne doit pas dépasser 160 caractères.' };
        }
        if (!metaDescription.toLowerCase().includes(focusKeyword.toLowerCase())) {
            return { valid: false, message: 'Le mot-clé principal doit être inclus dans la meta description.' };
        }

        // Paragraph validation
        const totalCharacters = paragraphe.length;
        if (totalCharacters === 0) {
            return { valid: false, message: 'Le paragraphe ne peut pas être vide.' };
        }

        const first10PercentIndex = Math.ceil(totalCharacters * 0.1);
        const keywordIndex = paragraphe.toLowerCase().indexOf(focusKeyword.toLowerCase());
        if (keywordIndex === -1 || keywordIndex >= first10PercentIndex) {
            return { valid: false, message: `Le mot-clé principal doit commencer dans les premiers ${first10PercentIndex} caractères du paragraphe.` };
        }

        return { valid: true };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validation = validateData();
        if (!validation.valid) {
            setAlert({ open: true, severity: 'error', message: validation.message });
            return;
        }

        try {
            const response = await sendSeoContent(formData);
            if (response.data.status === 201) {
                setAlert({ open: true, severity: 'success', message: response.data.message });
                setFormData({
                    page_id: '',
                    focusKeyword: '',
                    h1: '',
                    paragraphe: '',
                    metaDescription: '',
                    metaTitle: '',
                });
                refetchPages()
            }
        } catch {
            setAlert({ open: true, severity: 'error', message: 'Erreur lors de la sauvegarde des données.' });
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setAlert({ ...alert, open: false });
    };

    return (
        <div className="ManageSeo py-5">
            <div className="container-fluid container-xl">
                <div className='row'>
                    <div className='col-12 col-md-7'>
                        <div className="content">
                            <h1 className="title">Gestion de SEO</h1>
                            <hr />
                            <div className="form ">
                                <form onSubmit={handleSubmit}>
                                    <div className="selectpages mb-4">
                                        <label className="form-label" htmlFor="page_id">Sélectionner une page</label>
                                        <select
                                            id="page_id"
                                            className="form-select"
                                            value={formData.page_id}
                                            onChange={handlePageSelect}
                                        >
                                            <option value="" disabled>Choisissez une page</option>
                                            {pages && pages.map((page) => (
                                                <option key={page.page_id} value={page.page_id}>
                                                    {page.page_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label" htmlFor="focusKeyword">Focus keyword</label>
                                                <input
                                                    type="text"
                                                    id="focusKeyword"
                                                    className="inputseopage"
                                                    value={formData.focusKeyword}
                                                    onChange={handleInputChange}
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label" htmlFor="h1">H1</label>
                                                <input
                                                    type="text"
                                                    id="h1"
                                                    className="inputseopage"
                                                    value={formData.h1}
                                                    onChange={handleInputChange}
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label className="form-label" htmlFor="paragraphe">Paragraphe</label>
                                        <textarea
                                            className="form-control"
                                            id="paragraphe"
                                            rows="4"
                                            value={formData.paragraphe}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>

                                    <div className="mb-4">
                                        <label className="form-label" htmlFor="metaTitle">Meta Title</label>
                                        <input
                                            type="text"
                                            id="metaTitle"
                                            className="inputseopage"
                                            value={formData.metaTitle}
                                            onChange={handleInputChange}
                                            style={{ width: '100%' }}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="form-label" htmlFor="metaDescription">Meta Description</label>
                                        <textarea
                                            className="form-control"
                                            id="metaDescription"
                                            rows="4"
                                            value={formData.metaDescription}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>

                                    <div className="submutbtn">
                                        <button type="submit">Sauvegarder</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-5'>
                        <div style={{ backgroundColor:'#fff',height:'100%',borderRadius:'10px' }} className='gestionNotifications p-3'>
                            <SystemAlert pages={pages}/>
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar
                open={alert.open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
