/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import './index.css';
import Drawer from '@mui/material/Drawer';
import { useState, useEffect } from "react";
import ContactForm from "../components/contactForm/ContactForm";
import { Helmet } from 'react-helmet-async';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
// import TikTokPixel from 'tiktok-pixel';

export default function MainLayout() {
  const location = useLocation();
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  // Initialize Facebook Pixel
  useEffect(() => {
    ReactPixel.init(import.meta.env.VITE_FB_PIXEL_ID);
    // TikTokPixel.init('CUISKKBC77UCOV2DP300'); // Replace with your actual TikTok Pixel ID
  }, []);

  // Track page views on route change
  useEffect(() => {
    // TikTokPixel.pageView();
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
    ReactPixel.pageView();
  }, [location.pathname]);

  // Scroll listener to track scroll progress
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (scrollTop / scrollHeight) * 100;
      setScrollPercentage(scrolled);
    };

    window.scrollTo(0, 0); // Scroll to top on page load or location change
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="MainLayout position-relative">
      {/* Canonical Tag for Dynamic Pages */}
      <Helmet>
        <link rel="canonical" href={`${window.location.origin}${location.pathname}`} />
      </Helmet>

      <div className="assistantbtn">
        <button onClick={() => setisDrawerOpen(true)} className='mysmabsupport d-flex justify-content-start align-items-center'>
          Assistance
          <SupportAgentIcon sx={{ fontSize: 30, marginLeft: '10px' }} />
        </button>
      </div>

      <Navbar />
      <Outlet />
      <Footer />

      <Drawer
        anchor="right"
        size="lg"
        open={isDrawerOpen}
        onClose={() => setisDrawerOpen(false)}
        PaperProps={{
          style: {
            maxHeight: '100%',
            overflow: 'auto',
            width: '90%',
            maxWidth: '1200px',
            padding: '20px'
          },
        }}
      >
        <ContactForm />
      </Drawer>

      {/* Scroll to Top Button */}
      <div
        className="backtotop"
        onClick={scrollToTop}
        style={{
          opacity: scrollPercentage > 0 ? 1 : 0.5, // Show the button when the user has scrolled
          transition: 'opacity 0.3s ease'
        }}
      >
        <div
          className="circle"
          style={{
            border: `8px solid conic-gradient(var(--primary-color) ${scrollPercentage}%, #e0e0e0 0%)` // Scroll progress in border
          }}
        />
        <ArrowUpwardIcon sx={{ fontSize: 30, color: '#fff' }} />
      </div>
    </div>
  );
}
