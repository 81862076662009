import { useState } from 'react';
import './password.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useChangePasswordUserMutation } from '../../../store/UserApi';

export default function Password() {
  const [changePasswordUser] = useChangePasswordUserMutation();
  const initialPasswords = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const [passwords, setPasswords] = useState(initialPasswords);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handlePasswordChange = (field, value) => {
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [field]: value,
    }));
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords((prevShowPasswords) => ({
      ...prevShowPasswords,
      [field]: !prevShowPasswords[field],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    if (passwords.newPassword !== passwords.confirmPassword) {
      setError('Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas.');
      setLoading(false);
      return;
    }
    console.log(passwords);
    try {
      const response = await changePasswordUser(passwords);
      if (response.data && response.data.status === 200) {
        setSuccess('Mot de passe mis à jour avec succès!');
        setPasswords(initialPasswords);
      } else {
        setError('Une erreur inattendue est survenue.');
      }
    } catch {
      setError('Échec de la mise à jour du mot de passe.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loading d-flex justify-content-center align-items-center">
          <CircularProgress style={{ width: 60, height: 60 }} />
        </div>
      )}

      <div className="password pt-4 m-3">
        <div>
          <h2 className="_section_title mb-4">Modifier le mot de passe</h2>
        </div>

        {error && (
          <Alert severity="error" className="mb-3">
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" className="mb-3">
            {success}
          </Alert>
        )}

        <form className="row" onSubmit={handleSubmit}>
          <div className="col-12 div_input_pass">
            {Object.keys(passwords).map((field) => (
              <div className="position-relative" key={field}>
                <input
                  type={showPasswords[field] ? 'text' : 'password'}
                  placeholder={
                    field === 'currentPassword'
                      ? 'Mot de passe actuel *'
                      : field === 'newPassword'
                        ? 'Nouveau mot de passe *'
                        : 'Confirmer le nouveau mot de passe *'
                  }
                  required
                  value={passwords[field]}
                  onChange={(e) => handlePasswordChange(field, e.target.value)}
                />
                <span className="VisibilityIcon" onClick={() => togglePasswordVisibility(field)}>
                  {showPasswords[field] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
            ))}
            <div className="change_psw_btn mt-3">
              <button type="submit">Sauvegarder les modifications</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
