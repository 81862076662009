/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//     baseUrl: 'https://mintcream-crocodile-587037.hostingersite.com/backend/public/api',

// Create an API slice
export const SeoApi = createApi({
    reducerPath: 'SeoApi',
    baseQuery: fetchBaseQuery({
    // baseUrl: 'http://127.0.0.1:8000/api',
    baseUrl: 'https://smab-co.com/backend/public/api',
    // baseUrl: 'https://mintcream-crocodile-587037.hostingersite.com/public/api',

        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('adminToken');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getPages: builder.query({
            query: () => ({
                url: `/get-pages`,
                method: 'GET',
            })
        }),


        sendSeoContent: builder.mutation({
            query: (FormData) => ({
                url: '/send-seo',
                method: 'POST',
                body: FormData,
            }),
        }),
        

    }),
});

export const { useGetPagesQuery, useSendSeoContentMutation } = SeoApi;
