import Slider from "react-slick";
import './index.css'

export default function SlideVdProjects() {
    const nosservices = [
        { id: 1, name: "Ils nous ont fait confiance : Bimbo", video: "https://www.youtube.com/embed/rHN63sxe61I?si=jIpI3wQqcq7ppL7G" },
        { id: 2, name: "Ils nous ont fait confiance : OFPPT", video: "https://www.youtube.com/embed/pfpbR4yBXfU?si=GfQqg9QZoZu5fcN0" }
    ];



    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1224,
                settings: { slidesToShow: 2 }
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 2 }
            },
            {
                breakpoint: 576,
                settings: { slidesToShow: 1 }
            }
        ]
    };

    return (
        <Slider {...settings}>
            {nosservices.map((item, index) => (
                <div key={index} className='video px-2'>
                    <div className="video-container videosProjects">
                            <iframe 
                                width="100%" 
                                height="305" 
                                src={item.video} 
                                title={item.name} 
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen
                            ></iframe>
                    </div>
                    
                </div>
            ))}
        </Slider>
    );
}
