/* eslint-disable no-unused-vars */
import { Link } from 'react-router-dom';
import './index.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ContactForm from '../../components/contactForm/ContactForm';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import DrawerPanier from '../../components/DrawerPanier';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../store/Produits';
import { useGetProduitsBySousCategoryQuery } from '../../store/ProduitsApi';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';


export default function SousActivite() {
    const { sousActiviteName } = useParams();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    // Replace "-" with a space
    const formattedName = sousActiviteName.replace(/-/g, ' ');
    
    const dispatch = useDispatch();
    const { data: produits, isLoading, error } = useGetProduitsBySousCategoryQuery(formattedName);

    
    if (isLoading) {
        return <div><Loading /></div>;
    }




    const itemsPerPage = 9; // Display 10 products per page
    const handleAddToCart = () => {
        setIsDrawerOpen(true);
    };

    const totalPages = produits ? Math.ceil(produits.products.length / itemsPerPage) : 1;
    const displayedProducts = produits ? produits.products.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) : [];

    console.log(displayedProducts)
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    const toSlug = (label) => {
        return label
            .toLowerCase()
            .replace(/ /g, '-')         // Replace spaces with hyphens
            .replace(/[^\w-]+/g, '');   // Remove all non-word characters
    };

    function getImageUrl(productRef, imageFilenames) {
        console.log(imageFilenames);
        const validImages = imageFilenames.filter(filename => !filename.endsWith('.pdf'));
        if (validImages.length > 0) {
            const imageName = validImages[0]; // You can change this index if needed
            return `https://www.kelmohub.com/product-images/${productRef}/${imageName}`;
        }
        return '/Images/default-image.jpg';
    }
    

    return (
        <div className='SousActivitePage my-5'>
            <div className='container-fluid container-lg position-relative'>
                <div className='row'>
                    {/*<div className='col-12 col-lg-3'>
                        <div className='filterSection p-3'>
                            <div className='text-center p-3 mb-3'>
                                <p className='mb-0 title'>Filtré par</p>
                            </div>
                            <hr />
                            {[1, 2, 3].map((item, index) => (
                                <div key={index}>
                                    <div className='p-2'>
                                        <h2 className='Caractiristique '>Poids</h2>
                                        <Select
                                            value={''}
                                            displayEmpty
                                            fullWidth
                                        >
                                            <MenuItem value="">
                                                <em>toute</em>
                                            </MenuItem>
                                            {[1, 2, 3].map((value, idx) => (
                                                <MenuItem key={idx} value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            ))}
                            <div className='p-2'>
                                <button className='resetall'>toute</button>
                            </div>
                        </div>
                    </div>*/}
                    <div className='col-12 '>
                        <div className='text-center text-md-start'>
                            <Link to={`/categoryslug/souscategoriesslug/comparer?products=${JSON.stringify([1, 2])}`} className='comparebtn'>
                                Comparer
                            </Link>
                        </div>
                        <div className='productssection'>
                            <div className='product_side row g-3'>
                            {displayedProducts.map(product => (
                                <div className='col-12 col-md-4 col-lg-3' key={product.ProductId}>
                                    <div className="card border">
                                        <Link to={`/produit/${toSlug(product.ProductLabel)}?id=${product.ProductId}`} className="card">
                                            <img 
                                                src={getImageUrl(product.ProductRef, product.ImageFilenames)} 
                                                className="card-img-top" 
                                                alt={product.ProductLabel || "Product Image"} 
                                                loading="lazy"
                                            />
                                        </Link>
                                        <div style={{ backgroundColor:'#e5e5e56b' }} className="card-body">
                                            <p className="card-text mb-1 sousactivite_product_name towLine">{product.ProductLabel}</p>
                                            {/*<div className='d-flex align-items-center justify-content-between'>
                                                <span className='price_sousact'>300 Dhs</span>
                                                <Checkbox
                                                />
                                            </div>*/}
                                            <button
                                                onClick={() => {
                                                    dispatch(addToCart({ 
                                                        id: product.ProductId, 
                                                        quantity: 1, 
                                                        prix: product.Price, 
                                                        ref: product.ProductRef, 
                                                        name: product.ProductLabel, 
                                                        image: getImageUrl(product.ProductRef, product.ImageFilenames) 
                                                    }));
                                                    handleAddToCart();
                                                }}
                                                className='addTopanier'>Ajouter au panier</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                            </div>
                            {/* Pagination Controls */}
                            <div className="pagination pagination-lg justify-content-center my-4">
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <li
                                            key={index + 1}
                                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                        >
                                            <button
                                                onClick={() => handlePageChange(index + 1)}
                                                className="page-link"
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

               {/* <div className='contact my-5'>
                    <div className='container my-5'>
                        <h1 className='title text-center'>De quoi avez-vous besoin ?</h1>
                        <p className='subtitle text-center'>Nous vous répondrons dans les plus brefs délais</p>
                        <ContactForm />
                    </div>
                </div>*/}
            </div>
            <DrawerPanier isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
        </div>
    );
}
