// @ts-nocheck
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useRegisterMutation } from '../../store/AuthUserApi';
import { useNavigate } from 'react-router-dom';
import './index.css'

export default function Register() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email_or_phone: '',
        password: '',
        password_confirmation: ''
    });

    const [Userregister]=useRegisterMutation();
    const navigate = useNavigate();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateEmailOrPhone = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\d{10}$/;
        return emailRegex.test(value) || phoneRegex.test(value);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        console.log(formData);
        if (!validateEmailOrPhone(formData.email_or_phone)) {
            setError('Veuillez entrer une adresse e-mail valide ou un numéro de téléphone à 10 chiffres.');
            setLoading(false);
            return;
        }
        if (formData.password !== formData.password_confirmation) {
            setError('Les mots de passe ne correspondent pas.');
            setLoading(false);
            return;
        }

        try{
            const response = await Userregister(formData)
            if(response.data.status===201){
                navigate('/login')
            }
        }catch(err){
            console.log(err)
        }finally{
            setLoading(false);
        }

    };

    return (
        <>
            {loading && (
                <div className='loading d-flex justify-content-center align-items-center'>
                    <CircularProgress style={{ width: 60, height: 60 }} />
                </div>
            )}

            <div style={{ margin:'70px auto' }} className='register_component p-3 text-center'>
                <div className='content_Login_component'>
                    <h2 className='title'>Créer un compte</h2>
                    <form className='' onSubmit={handleSubmit}>
                        {error && <div style={{ fontSize:'1.2em',fontWeight:'600',color:'red' }} className='error_message alert-danger p-3'>{error}</div>}
                        <div className='input_parent userName'>
                            <input type='text' placeholder={`Nom d'utilisateur`} name="name" value={formData.name} onChange={handleChange} required />
                        </div>

                        <div className='input_parent Email_phone_login'>
                            <input type='text' placeholder='Email ou Tél' name="email_or_phone" value={formData.email_or_phone} onChange={handleChange} required />
                        </div>

                        <div className='input_parent password_login'>
                            <input type='password' placeholder='mot de passe' name="password" value={formData.password} onChange={handleChange} required />
                        </div>

                        <div className='input_parent password_login'>
                            <input type='password' placeholder='confirmation de mot de passe' name="password_confirmation" value={formData.password_confirmation} onChange={handleChange} required />
                        </div>

                        <div className='checkboxs'>
                            <div className='politique d-flex align-items-center justify-content-center'>
                                <span className='d-flex align-items-center justify-content-start' style={{ maxWidth:"600px" }}>                                    
                                    <Checkbox required />
                                    <label style={{ fontSize: '1.2em', fontWeight: '600', marginLeft: '8px',textAlign:'start' }}>Acceptez les <Link to={'/CGV'} style={{ textDecoration: 'underline' }} className=''>termes et conditions et la politique</Link> de confidentialité SMAB</label></span>
                            </div>
                        </div>

                        <div className=''>
                            <button disabled={loading} className='registerbtn mt-3' type='submit'>{`S'inscrire`}</button>
                        </div>
                    </form>
                </div>
            </div>

        </>
    );
}
