import './index.css'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


export default function AboutUs() {
    return (
        <div className='AboutUs'>
            <div className="sectionheader mb-2">
                <div className='imagebanner'>
                    <img src={'/Images/about us/bg.png'} alt="Banner" loading="eager" />
                    <div style={{ width: '100%' }} className='titreonBanner'>
                        <img className='mb-3 d-none d-md-inline' src={'/Images/LOGO-SMAB-CROP-1.png'} />
                        <div className='mainTitle'>
                            <h1>
                                {`Des Solutions Avancées pour l'Industrie Agro-Food, Pharmaceutique, Cosmétique et Chimique`}
                            </h1>
                        </div>
                        <a className='calltoaction'
                            href="https://wa.me/212766074939"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            CONTACTEZ-NOUS
                        </a>
                    </div>
                </div>
            </div>


            <div className='sectionNosHistoire my-5'>
                <div className='container-fluid container-xl'>
                    <div className='row g-4 align-items-start'>
                        <div className='col-12 col-md-6'>
                            <div>
                                <img style={{ width: '100%' }} src='/Images/about us/histoire[1].png' alt='Histoire' />
                            </div>
                        </div>

                        <div className='col-12 col-md-6'>
                            <div className='NosHistoire'>
                                <h2 style={{ color: 'var(--primary-color)' }} className='mb-4 title'>NOTRE HISTOIRE</h2>
                                <div style={{ maxWidth: '800px' }}>
                                    <p>
                                        <span>SMAB, la marque de la Société Marocco
                                            Britannique, créée en 1971, s’est imposée
                                            comme un leader reconnu dans le secteur B2B.
                                        </span>
                                        <span>
                                            Spécialisée dans la fourniture de solutions
                                            innovantes et performantes, SMAB
                                            accompagne les artisans, semi-professionnels
                                            et professionnels dans la valorisation de leurs
                                            produits, couvrant divers secteurs.
                                        </span>

                                        <span>
                                            Avec plus de 50 ans d’expertise, nous proposons
                                            des équipements et machineries de pointe pour
                                            les industries agro-food, pharmaceutique,
                                            cosmétique, chimique et agricole.
                                        </span>

                                        <span>
                                            Notre mission est d’optimiser chaque étape de la
                                            production, depuis la transformation initiale
                                            jusqu’aux opérations de fin de ligne, en
                                            garantissant qualité, efficacité et conformité aux
                                            normes en vigueur.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='visionMission py-5'>
                <div className='container-fluid container-xl'>
                    <div className='row g-3'>
                        <div className='col-12 col-md-6'>
                            <div className='VISION'>
                                <div className='card p-3'>
                                    <h3 style={{ color: 'var(--primary-color)' }} className='title text-center'>NOTRE VISION</h3>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src='/Images/about us/Notre Vision.png' />
                                        <p className='p-2'>Nous aspirons à être le partenaire
                                            de référence des entreprises
                                            souhaitant améliorer leur
                                            production grâce à des
                                            équipements performants et
                                            innovants.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className='Mission'>
                                <div className='card p-3'>
                                    <h3 style={{ color: 'var(--primary-color)' }} className='title text-center'>NOTRE MISSION</h3>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src='/Images/about us/Notre Mission.png' alt='Notre Mission' />
                                        <p className='p-2'>
                                            {`  Notre mission est
                                            d'accompagner nos clients
                                            à chaque étape de leur
                                            projet en leur proposant
                                            des solutions
                                            personnalisées,
                                            performantes et adaptées à
                                            leurs besoins spécifiques.
                                        `}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='nosValeurs'>
                <div className='container-fluid container-xl'>
                    <div className='row g-3'>
                        <div className='col-12 col-md-4'>
                            <div className='titlediv'>
                                <h2 className='title' style={{ color: 'var(--primary-color)' }}>NOS VALEURS</h2>
                                <h3>Un Engagement
                                    au Service de
                                    Votre Réussite.
                                </h3>
                            </div>
                        </div>

                        <div className='col-12 col-md-8'>
                            <div className='row g-3'>
                                <div className='col-12 col-sm-6 col-lg-6'>
                                    <div className='QualitePerformance'>
                                        <div className='card'>
                                            <h3 className='title'>Qualité <br /> et performance</h3>
                                            <div className='cardBody'>
                                                <img src='/Images/about us/Qualité et performance .png' alt='Qualité et performance' />
                                                <div className='hoverText'>
                                                    <p>Nous nous engageons à fournir des solutions innovantes pour assurer la qualité et la performance.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 col-lg-6'>
                                    <div className='InnovationContinue'>
                                        <div className='card'>
                                            <h3 className='title'>Innovation <br /> continue</h3>
                                            <div className='cardBody'>
                                                <img src='/Images/about us/Innovation continue .png' alt="Innovation" />
                                                <div className='hoverText'>
                                                    <p>Nous nous engageons à fournir des solutions innovantes pour assurer la qualité et la performance.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 col-lg-6'>
                                    <div className=' integriteTransparence'>
                                        <div className='card'>
                                            <h3 className='title'> Intégrité <br />et transparence</h3>
                                            <div className='cardBody'>
                                                <img src='/Images/about us/Intégrité et transparence.png' alt='Intégrité et transparence' />
                                                <div className='hoverText'>
                                                    <p>Nous nous engageons à fournir des solutions innovantes pour assurer la qualité et la performance.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 col-lg-6'>
                                    <div className='accompagnementPersonnalise'>
                                        <div className='card'>
                                            <h3 className='title'>Accompagnement <br /> personnalisé</h3>
                                            <div className='cardBody'>
                                                <img src='/Images/about us/Accompagnement personnalisé.png' alt='Accompagnement personnalisé' />
                                                <div className='hoverText'>
                                                    <p>Nous nous engageons à fournir des solutions innovantes pour assurer la qualité et la performance.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='NotreExpertise py-5'>
                <div className='container-fluid container-xl'>
                    <div className=''>
                        <h2 style={{ color: 'var(--primary-color)' }} className='title text-center my-5'>Notre Expertise</h2>
                        <div className='row g-4 align-items-center conception'>
                            <div className='col-12 col-md-6 col-lg-7'>
                                <div className='text-center'>
                                    <h3 className='mb-4'>Étude & Conception :<br /> Des Solutions Sur Mesure</h3>
                                    <p> Chaque projet commence par une phase d’analyse
                                        approfondie afin de proposer des équipements
                                        adaptés aux besoins spécifiques de nos clients. Grâce
                                        à notre expertise et à des outils de conception
                                        avancés, nous développons des solutions innovantes,
                                        performantes et conformes aux exigences
                                        industrielles.
                                    </p>
                                </div>
                            </div>
                            <div className='picture col-12 col-md-6 col-lg-5'>
                                <div>
                                    <img style={{ width: '100%', borderRadius: '8px' }} src='/Images/about us/etude et conception.png' alt='etude et conception' />
                                </div>
                            </div>
                        </div>

                        <div className='row g-4 align-items-center'>
                            <div className='picture col-12 col-md-6 col-lg-5 order-2 order-md-1'>
                                <div>
                                    <img style={{ width: '100%', borderRadius: '8px' }} src='/Images/about us/MontageFabrication.png' alt='montage et fabrication' />
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-7 order-1 order-md-2'>
                                <div className='text-center'>
                                    <h3 className='mb-4'>Montage & Fabrication : <br />Une Qualité Maîtrisée</h3>
                                    <p> Nos machines sont fabriquées avec des matériaux de
                                        haute qualité et assemblées selon des processus
                                        rigoureux garantissant fiabilité et durabilité. Chaque
                                        étape, du montage à la mise en service, est réalisée
                                        avec précision pour assurer une performance optimale
                                        et une intégration fluide dans vos lignes de production.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='NotreAccompagnement'>
                <div className='container-fluid container-xl'>
                    <div>
                        <h2 style={{ color: 'var(--primary-color)' }} className='title text-center my-5'>Notre <br /> Accompagnement</h2>
                        <div className='row g-3'>
                            <div className='col-12 col-md-6 col-lg-7'>
                                <div className=''>
                                    <p>Quel que soit le stade de votre projet, que vous
                                        soyez un expert ou un débutant, SMAB vous
                                        accompagne avec des solutions sur mesure. Nous
                                        soutenons également les porteurs de projets
                                        financés par des programmes tels que FORSA,
                                        Intilaka ou INDH, en leur apportant des solutions
                                        adaptées à leurs besoins.</p>
                                    <p>
                                        {`En choisissant SMAB, vous bénéficiez d'un
                                accompagnement complet, de la conception à
                                l’installation de vos équipements, ainsi qu'un service
                                client dédié pour assurer la performance et la
                                pérennité de vos investissements.`}
                                    </p>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 col-lg-5'>
                                <div className=''>
                                    <img style={{ width: '100%', borderRadius: '8px' }} src='/Images/about us/NotreAccpmpagnement.png' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='ContacteznousAboutUs mb-5 py-5'>
                <div className='container-fluid container-xl'>
                    <div className='text-center'>
                        <h2 style={{ color: 'var(--primary-color)' }} className='title text-center'>Contactez-nous</h2>
                        <p>Besoin d’informations supplémentaires ?
                            <br />
                            Contactez notre équipe pour discuter de vos projets et découvrir nos solutions
                            adaptées à vos besoins.
                            <br />
                            SMAB, votre partenaire pour une production optimisée et un avenir prospère.
                        </p>
                        <a
                            href="https://wa.me/212766074939"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: 'inline-block', marginTop: '15px', color: '#25D366' }}>
                            <WhatsAppIcon className="whatsapp-icon" style={{ fontSize: 70 }} />
                        </a>
                    </div>
                </div>
            </div>

        </div>
    )
}
