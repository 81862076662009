import { Link } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';
import './index.css'
// import { useDispatch } from "react-redux";
// import { useState } from "react";
// import { addToCart } from "../../store/Produits";
// import Slider from "react-slick";
// import DrawerPanier from "../../components/DrawerPanier";


export default function NosConseils() {
    // const dispatch = useDispatch();
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // const handleAddToCart = () => {
    //     setIsDrawerOpen(true);
    // };

    // const nosActivite = [
    //     { id: 1, name: 'Broyage et mouture', image: '/Images/activites/activites.jpg', slug: "Broyage-et-mouture" },
    //     { id: 2, name: 'Extraction des fruits', image: '/Images/activites/activites2.jpg', slug: "Extraction-des-fruits" },
    //     { id: 3, name: 'SÉCHACE ET TORRÉFACTION', image: '/Images/activites/activites3.jpg', slug: "sechage-et-torrefaction" },
    //     { id: 4, name: 'EXTRACTION DES HUILES', image: '/Images/activites/activites4.jpg', slug: "extraction-des-huiles" },
    //     { id: 5, name: 'NETTOYAGE ET SÉPARATION', image: '/Images/activites/activites5.jpg', slug: "nettoyage-et-separation" },
    //     { id: 6, name: 'PACKAGING', image: '/Images/activites/activites6.jpg', slug: "packaging" },
    // ];

    const blogs = [
        { id: 1, title: 'Secteurs Porteurs pour les Jeunes Entrepreneurs au Maroc en 2024', image: '/Images/blogs/blog1.png', link: `/nos-conseils/blog/Secteurs-porteurs-pour-les-jeunes-entrepreneurs-au-maroc-en-2024?id=${1}`, description: 'En 2024, les jeunes entrepreneurs au Maroc ont l’opportunité de se lancer dans des secteurs en pleine' },
        {id:2, title:`Révolutionner la Production d'Amlou : Le Rôle des Machines Modernes`, image: '/Images/blogs/blog Production Amlou.jpg', link:`/nos-conseils/blog/revolutionner-la-production-Amlou?id=${2}`,description:'La production d’Amlou repose sur des ingrédients de qualité, mais également sur des processus optimisés. L’intégration de machines comme le moulin à beurre a transformé l’industrie'},
        {id:3, title:`Comment optimiser votre production avec des machines d'ensachage automatiques`, image: '/Images/blogs/blogId3(3).png', link:`/nos-conseils/blog/optimiser-votre-production-avec-des-machines-densachage-automatiques?id=${3}`,description:'L’ensachage est une étape essentielle dans de nombreuses industries, notamment alimentaire, cosmétique et pharmaceutique'}

    ]

    // var settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     autoplay: true, // Enable autoplay
    //     autoplaySpeed: 2000, // Set autoplay speed (in milliseconds)
    //     responsive: [
    //         {
    //             breakpoint: 1224, // Large screen
    //             settings: {
    //                 slidesToShow: 3,
    //             }
    //         },
    //         {
    //             breakpoint: 768, // Medium screen
    //             settings: {
    //                 slidesToShow: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 576, // Small screen
    //             settings: {
    //                 slidesToShow: 1,
    //             }
    //         }
    //     ]
    // };

    // const toSlug = (label) => {
    //     return label
    //         .toLowerCase()
    //         .replace(/ /g, '-')         // Replace spaces with hyphens
    //         .replace(/[^\w-]+/g, '');   // Remove all non-word characters
    // };
    return (
        <div className="NosConseils my-5">
            <div className="blog">
                <div className="blogsection">
                    <div className="container-fluid container-xl px-5 px-lg-0">
                        <h1 style={{ fontSize: '4.2em', fontWeight: '800' }} className="text-start my-5">Blogs</h1>
                        <div className="blogsCards">
                            <div className="row g-3">
                                {blogs.map((item) => (
                                    <div key={item.id} className="col-12 col-sm-6 col-lg-4">
                                        <div className="Blogcards ">
                                            <div className="imageTop">
                                                <img style={{ borderRadius: '8px' }} src={item.image} alt="blog" loading="lazy" />
                                            </div>
                                            <div>
                                                <h2 style={{ fontSize: '1.3em', fontWeight: '700', lineHeight: '26px' }} className="title my-2">{item.title}</h2>
                                                <div className="description">
                                                    <p className="mb-0 two-line-clamp">{item.description}</p>
                                                </div>
                                                <Link className="" to={item.link}>lire la suite <EastIcon /></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*            <div className="articlesconnexes my-5">
                <div className="container-fluid">
                    <h2 className="title text-center my-5">Articles connexes</h2>
                    <div>
                        <Slider {...settings}>
                            {nosActivite.map((item, index) => (
                                <div key={index} className="mb-2 p-2 ">
                                    <Link to={`/produit/${toSlug('SBV240TS Plateau Vide Séparateur')}?id=2`} className='position-relative'>
                                        <img src={'/Images/produit.jpg'} className="card-img-top" alt="sous activites" loading="lazy" />
                                    </Link>

                                    <div className="cardbody p-1">
                                        <div className="cardtitle">
                                            <p style={{ lineHeight: '25px', fontSize: '1.3em', fontWeight: '600' }} className='name_pro_acti_p_mv mb-1'>SBV240TS Plateau Vide Séparateur</p>
                                        </div>
                                        <div className=''>
                                            <button
                                                onClick={() => {
                                                    dispatch(addToCart({ id: item, quantity: 1, prix: 1000, ref: '00003', name: 'SBV240TS Plateau Vide Séparateur', image: '/Images/produit.jpg' }))
                                                    handleAddToCart()
                                                }}
                                                className="addPannier border-0">Ajouter au panier</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
            <DrawerPanier isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />*/}
        </div>
    )
}
