import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthProtectedRoute = () => {
    const isAuthenticated = useSelector((state) => state.Auth.userToken);

    if (isAuthenticated) {
        return <Navigate to="/mon-compte" />;
    }

    return <Outlet />;
};

export default AuthProtectedRoute;
