/* eslint-disable react/prop-types */
import Slider from "react-slick";

export default function SlideVideo() {
    const nosservices = [
        // { id: 1, name: 'Broyage et mouture', video: 'https://www.youtube.com/embed/aP_2dEKG9Kk?si=ZdahLx0j5FWZfK8x&controls=0&showinfo=0&rel=0', slug: "Broyage-et-mouture" },
        // { id: 2, name: 'Extraction des fruits', video: 'https://www.youtube.com/embed/aP_2dEKG9Kk?si=ZdahLx0j5FWZfK8x&controls=0&showinfo=0&rel=0', slug: "Extraction-des-fruits" },
        // { id: 3, name: 'SÉCHACE ET TORRÉFACTION', video: 'https://www.youtube.com/embed/aP_2dEKG9Kk?si=ZdahLx0j5FWZfK8x&controls=0&showinfo=0&rel=0', slug: "sechage-et-torrefaction" },
        // { id: 4, name: 'EXTRACTION DES HUILES', video: 'https://www.youtube.com/embed/aP_2dEKG9Kk?si=ZdahLx0j5FWZfK8x&controls=0&showinfo=0&rel=0', slug: "extraction-des-huiles" },
        // { id: 5, name: 'NETTOYAGE ET SÉPARATION', video: 'https://www.youtube.com/embed/aP_2dEKG9Kk?si=ZdahLx0j5FWZfK8x&controls=0&showinfo=0&rel=0', slug: "nettoyage-et-separation" },
        // { id: 6, name: 'PACKAGING', video: 'https://www.youtube.com/embed/aP_2dEKG9Kk?si=ZdahLx0j5FWZfK8x&controls=0&showinfo=0&rel=0', slug: "packaging" },

        {id:1 ,video:'https://www.youtube.com/embed/gmsx9D_JYhQ?si=PwoQ_DgttSVS3CeE' },
        {id:1 , video:'https://www.youtube.com/embed/s5ejLq-iQgw?si=FenLMcqHg7QsAfx-' }
    ];

    var settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false, // Enable autoplay
        arrows: false,
        autoplaySpeed: 2000, // Set autoplay speed (in milliseconds)
        responsive: [
            {
                breakpoint: 1224, // Large screen
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768, // Medium screen
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576, // Small screen
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
        {nosservices.map((item, index) => (
          <div key={index} className='video px-2'>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                style={{ borderRadius:"10px" }}
                src={item.video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
           
          </div>
        ))}
      </Slider>
    );
}
