/* eslint-disable no-unused-vars */
// @ts-nocheck
import { useState } from 'react';
import './adresse.css';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useAddAddressesMutation, useUpdateAddressesMutation, useGetAddressesQuery, useDeleteAddressesMutation } from '../../../store/UserApi';
import Loading from '../../../components/Loading';

const initialFormState = {
  name: '',
  address: '',
  ville: '',
  phone: '',
  email: '',
  isDefault: false,
};

const villes = ['casablanca', 'marrakech', 'Rabat', 'Tanger', 'Agadir'];

export default function Adresses() {
  const [formData, setFormData] = useState(initialFormState);
  const [error, setError] = useState('');
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successRemoveAdresse, setSuccessRemoveAdresse] = useState(false);
  const [message, setMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [addAddresses] = useAddAddressesMutation()
  const [updateAddresses] = useUpdateAddressesMutation()


  const { data: addresses, iserror, isLoading, refetch } = useGetAddressesQuery();
  const [deleteAddresses] = useDeleteAddressesMutation();

  if (isLoading) {
    return <div><Loading /></div>
  }

  const startEditing = (address) => {
    setIsEditing(true);
    setFormData({
      id: address.id,
      name: address.name,
      address: address.address,
      ville: address.ville,
      phone: address.phone,
      email: address.email,
      isDefault: address.isDefault,
    });
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateForm = () => {
    const { email, phone } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    if (!emailRegex.test(email)) {
      return 'Veuillez entrer une adresse e-mail valide.';
    }

    if (!phoneRegex.test(phone)) {
      return 'Le numéro de téléphone doit comporter 10 chiffres "0XXXXXXXXX".';
    }
    return '';
  };


  const cancelEditing = () => {
    setIsEditing(false);
    setFormData(initialFormState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      setLoading(false);
      return;
    }

    if (isEditing) {
      try {
        const response = await updateAddresses(formData)
        if (response.data.status === 200) {
          refetch()
        }
      } catch {
        console.log('error')
      }
    } else {
      try {
        const response = await addAddresses(formData)
        if (response.data.status === 201) {
          refetch()
        }
      } catch {
        console.log('error')
      }
    }

    setLoading(false);
    setSuccessMessageOpen(true);
    setMessage(isEditing ? 'Address updated successfully!' : 'Address added successfully!');
    cancelEditing();
  };

  const handleRemove = async (addressId) => {
    setLoading(true);
    try {
      const response = await deleteAddresses(addressId)
      console.log('ddd')
      if (response.data.status === 200) {
        refetch()
      }
    } catch {
      console.log('error')
    }
    setLoading(false);
    setSuccessRemoveAdresse(true);
  };

  const handleDefaultAddress = async (addressId) => {
    setLoading(true);
    console.log('Setting default address with id:', addressId);
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className='loading d-flex justify-content-center align-items-center'>
          <CircularProgress style={{ width: 60, height: 60 }} />
        </div>
      )}


      <div>
        <div className='Mes_adresses_livraison pt-4'>
          <h2 className='title m-3'>Mes adresses de livraison</h2>
          <hr />
          <div className='p-3'>

            <form onSubmit={handleSubmit} className='form-adress'>
              <input
                type='text'
                name='name'
                placeholder='Nom *'
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <input
                    type='text'
                    name='address'
                    placeholder='Adresse *'
                    value={formData.address}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <select
                    name='ville'
                    className='form-select'
                    value={formData.ville}
                    onChange={handleInputChange}
                    required
                  >
                    <option value=''>Ville *</option>
                    {villes.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <input
                    type='text'
                    name='phone'
                    placeholder='Tel *'
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <input
                    type='email'
                    name='email'
                    placeholder='Email *'
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className='d-flex justify-content-start align-items-center'>
                <Checkbox
                  id='default_adresse'
                  name='isDefault'
                  checked={formData.isDefault}
                  onChange={handleInputChange}
                />
                <label className='default_adresse' htmlFor='default_adresse'>Définir comme adresse par défaut</label>
              </div>
              {error && <div className='error_message alert alert-danger'>{error}</div>}
              <div className='d-md-flex justify-content-md-end '>
                <button type='submit' className='send_adresse my-2' disabled={loading}>
                  {isEditing ? 'Modifier' : 'Soumettre'}
                </button>
                <button type='button' className='annuler_adresse my-2' onClick={cancelEditing}>
                  Annuler
                </button>
              </div>
            </form>

            <div className='adresses_saved my-4'>
              <div className='row'>
                {addresses && addresses.data && addresses.data.length > 0 ? (
                  addresses.data.map((address) => (
                    <div className='col-12 my-2 col-md-6' key={address.id}>
                      <div className='adresses_saved_columns position-relative'>
                        <span style={{ cursor: "pointer" }} onClick={() => handleRemove(address.id)}>
                          <DeleteIcon sx={{ color: '#e63812', fontSize: 40 }} />
                        </span>

                        <div className='first_line'>
                          <div className='d-flex'>
                            <p className='mb-0'>Nom :</p>
                            <p className='mb-0 ms-2'>{address.name}</p>
                          </div>
                          <div className='d-flex'>
                            <p className='mb-0'>Tél :</p>
                            <p className='mb-0 ms-2'>{address.phone}</p>
                          </div>
                        </div>

                        <div className='d-flex'>
                          <p className='mb-0'>Email :</p>
                          <p className='ms-2 mb-0'>{address.email}</p>
                        </div>

                        <div className='third_line'>
                          <p className='mb-0'>Adresse :</p>
                          <p className='adresse_saved mb-0'>{address.address}</p>
                        </div>

                        <button
                          className='send_adresse'
                          style={{ width: 80 }}
                          onClick={() => startEditing(address)}
                        >
                          Éditer
                        </button>

                        {!address.isDefault && (
                          <button
                            onClick={() => handleDefaultAddress(address.id)}
                            className='set_default_adresse my-2'
                          >
                            Set as Default
                          </button>
                        )}

                        <div className='is_default'>
                          {address.isDefault !== 0 && (
                            <div className='p-2'>
                              Par Défaut
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='text-center'>Aucune adresse enregistrée</div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
