/* eslint-disable no-unused-vars */
import { Update } from '@mui/icons-material';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Create an API slice
export const AuthAdminApi = createApi({
  reducerPath: 'AuthAdminApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: 'http://127.0.0.1:8000/api',
    baseUrl: 'https://smab-co.com/backend/public/api',
    // baseUrl: 'https://mintcream-crocodile-587037.hostingersite.com/public/api',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('adminToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Login endpoint
    loginAdmin: builder.mutation({
      query: (FormData) => ({
        url: '/admin-login',
        method: 'POST',
        body: FormData,
      }),
    }),


    logoutAdmin: builder.mutation({
      query: () => ({
        url: '/user/logout', // Assuming this is the logout endpoint on your backend
        method: 'POST', // Make sure it's a POST request
      }),
    }),


    AdminCheckValidityToken: builder.query({
      query: (adminToken) => ({
        url: '/admin/check-validity-token',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${adminToken}`
        }
      })
    }),

    getAdmins: builder.query({
      query: () => ({
        url: '/get-admins',
        method: 'GET',
      })
    }),

    addAdmin: builder.mutation({
      query: (formData) => ({
        url: '/add-admin',
        method: 'POST', // Make sure it's a POST request
        body: formData
      }),
    }),

    UpdateAdmin: builder.mutation({
      query: ({ id, ...formData }) => ({
        url: `/update-admin/${id}`, // Append the ID to the URL
        method: 'PUT', // Ensure this is a PUT request
        body: formData,
      }),
    }),

    deleteAdmin: builder.mutation({
      query: (id) => ({
        url: `/delete-admin/${id}`, // Append the ID to the URL
        method: 'DELETE', 
      }),
    }),
  }),
});

export const { useLoginAdminMutation, useLogoutAdminMutation, useAdminCheckValidityTokenQuery, useGetAdminsQuery, useAddAdminMutation, useUpdateAdminMutation, useDeleteAdminMutation } = AuthAdminApi;
