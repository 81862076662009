import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Create an API slice
export const UserApi = createApi({
  reducerPath: 'UserApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: 'http://127.0.0.1:8000/api',
    baseUrl: 'https://smab-co.com/backend/public/api',
    // baseUrl: 'https://mintcream-crocodile-587037.hostingersite.com/public/api',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('userToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => ({
        url: `/get-user`,
        method: 'GET',
      }),
    }),

    updateUser: builder.mutation({
      query: (updatedUser) => ({
        url: `/update-user`,
        method: 'put',
        body: updatedUser,
      }),
    }),

    changePasswordUser: builder.mutation({
      query: (passwords) => ({
        url: `/update-password`,
        method: 'put',
        body: passwords,
      }),
    }),

    getAddresses: builder.query({
      query: () => ({
        url: `/get-addresses`,
        method: 'GET',
      }),
    }),

    addAddresses: builder.mutation({
      query: (FormData) => ({
        url: '/new-addresses',
        method: 'POST',
        body: FormData,
      }),
    }),

    updateAddresses: builder.mutation({
        query: (FormData) => ({
          url: `/update-addresses`,
          method: 'put',
          body: FormData,
        }),
      }),

      deleteAddresses: builder.mutation({
        query: (addressId) => ({
          url: `/delete-addresse/${addressId}`,
          method: 'DELETE',
        }),
      }),

  }),
});

export const { useAddAddressesMutation, useUpdateAddressesMutation, useGetAddressesQuery, useDeleteAddressesMutation, useGetUserQuery, useUpdateUserMutation, useChangePasswordUserMutation} = UserApi;
