/* eslint-disable react/prop-types */
import { Link, useParams } from "react-router-dom";
import './index.css'
import { useState } from "react";
import { useGetProduitsBySousCategoryQuery } from "../../store/ProduitsApi";
import { useDispatch } from "react-redux";
import { addToCart } from '../../store/Produits';
import DrawerPanier from "../../components/DrawerPanier";
import { Helmet } from "react-helmet-async";
import Loading from "../../components/Loading";
import ActiviteRelated from "./ActiviteRelated";
import DescriptionComponent from "./DescriptionComponent";


export default function Activite({ seoData }) {
    // const [isExpanded, setIsExpanded] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);


    const dispatch = useDispatch();
    const { activitename } = useParams();

    const sousactivitesIcons = [
        { name: "Ensachage", icon: "/Images/sousactiviteicons/Ensachage.png" },
        { name: "Etiquetage", icon: "/Images/sousactiviteicons/Etiquetage.png" },
        { name: "Marquage", icon: "/Images/sousactiviteicons/Marquage.png" },
        { name: "Remplissage et dosage", icon: "/Images/sousactiviteicons/Remplissage et dosage.png" },
        { name: "Scellage", icon: "/Images/sousactiviteicons/Scellage.png" },
        { name: "Sertissage et Bouchage", icon: "/Images/sousactiviteicons/Sertissage et Bouchage.png" },
    ]

    const { data: produits } = useGetProduitsBySousCategoryQuery(activitename);


    function getImageUrl(productRef, imageFilenames) {
        const validImages = imageFilenames.filter(filename => !filename.endsWith('.pdf'));
        if (validImages.length > 0) {
            const imageName = validImages[0]; // You can change this index if needed
            return `https://www.kelmohub.com/product-images/${productRef}/${imageName}`;
        }
        return '/Images/default-image.jpg';
    }
    // const toggleText = () => {
    //     setIsExpanded(!isExpanded);
    // };

    const toSlug = (label) => {
        return label
            .toLowerCase()
            .replace(/ /g, '-')         // Replace spaces with hyphens
            .replace(/[^\w-]+/g, '');   // Remove all non-word characters
    };

    const handleAddToCart = () => {
        setIsDrawerOpen(true);
    };

    return (
        <>
            <Helmet>
                <title>{seoData.meta_title || 'Default Title'}</title>
                <meta name="description" content={seoData.meta_description || 'Default description'} />
                <meta name="keywords" content={seoData.focus_keyword || 'default keywords'} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <div className='activitePage'>
                {activitename == "nettoyage-et-separation" ? (
                    <div className="coming-soon text-center">
                        <div className="sectionheader mb-3">
                            <div className='imagebanner'>
                                <img src={'/Images/activites/comingsoon/Nettoyage et Séparation.png'} alt="Banner" loading="eager" />
                                <div className='titreonBanner'>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : activitename == "sechage-et-torrefaction" ? (
                    <div className="coming-soon text-center">
                        <div className="sectionheader mb-3">
                            <div className='imagebanner'>
                                <img src={'/Images/activites/comingsoon/Séchage et torréfaction.png'} alt="Banner" loading="eager" />
                                <div className='titreonBanner'>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : activitename == "Broyage-et-mouture" ? (
                    <div className="coming-soon text-center">
                        <div className="sectionheader mb-3">
                            <div className='imagebanner'>
                                <img src={'/Images/activites/comingsoon/Broyage et mouture.png'} alt="Banner" loading="eager" />
                                <div className='titreonBanner'>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : activitename == "Extraction-des-fruits" ? (
                    <div className="coming-soon text-center">
                        <div className="sectionheader mb-3">
                            <div className='imagebanner'>
                                <img src={'/Images/activites/comingsoon/Extraction des Fruits.png'} alt="Banner" loading="eager" />
                                <div className='titreonBanner'>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : activitename == "extraction-des-huiles" ? (
                    <div className="coming-soon text-center">
                        <div className="sectionheader mb-3">
                            <div className='imagebanner'>
                                <img src={'/Images/activites/comingsoon/Extraction des Huiles.png'} alt="Banner" loading="eager" />
                                <div className='titreonBanner'>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="sectionheader">
                            <div className='imagebanner'>
                                <img src={'/Images/sousactivitesImages/mainBanniere.png'} alt="Banner" loading="eager" />
                                <div className='titreonBanner'>
                                </div>
                            </div>
                        </div >

                        <div className="activitePagesection1 my-5">
                            <div className="container-fluid container-xxl">
                                <div className="d-flex align-items-center justify-content-center">
                                    <h1 className="title text-center">{seoData.H1 || 'Ce que nous vous proposons'}</h1>
                                </div>

                                <div style={{ height:'400px',borderBottom:'1px solid #b5b5b5' }} className="sousActivites  my-5">
                                    <div className="row g-3">
                                        {sousactivitesIcons.map((item, index) => (
                                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                <Link to={`/sous-activite/${item.name}`} className="d-flex flex-column align-items-center justify-content-center sousActiviteicons">
                                                    <img src={item.icon} alt={item.name} style={{ borderRadius: '50%' }} loading="lazy" />
                                                    <p className="text-center mt-3">{item.name}</p>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/*start section2 meilleurs vents*/}
                                <div className='activite_page_meilleurs_vents my-4'>
                                    <h2 className='title py-2 m-3 text-center'>meilleures ventes</h2>
                                    <div className="container-fluid container-lg">
                                        <div className='row'>
                                            {produits ? (
                                                <div className='row'>
                                                    {(produits.products).slice(0, 4).map(product => (
                                                        <div className='col-12 col-md-3' key={product.ProductId}>
                                                            <div style={{ boxShadow:'#e3e2e2e0 0px 0px 7px 1px',borderRadius:'8px' }} className="mb-2 p-2">
                                                                <Link to={`/produit/${toSlug(product.ProductLabel)}?id=${product.ProductId}`} className='position-relative'>
                                                                    <img style={{ aspectRatio: 1 }}
                                                                        src={getImageUrl(product.ProductRef, product.ImageFilenames)}
                                                                        className="card-img-top"
                                                                        alt={product.ProductLabel || "Product Image"}
                                                                        loading="lazy"
                                                                    />
                                                                </Link>

                                                                <div className="cardbody p-1">
                                                                    <div className="cardtitle">
                                                                        <p style={{ lineHeight: '25px', fontSize: '1.3em', fontWeight: '600' }} className='name_pro_acti_p_mv towLine mb-1'>{product.ProductLabel}</p>
                                                                    </div>
                                                                    <div className=''>
                                                                        <button
                                                                            onClick={() => {
                                                                                dispatch(addToCart({ id: product.ProductId, quantity: 1, prix: product.Price, ref: product.ProductRef, name: product.ProductLabel, image: getImageUrl(product.ProductRef, product.ImageFilenames) }))
                                                                                handleAddToCart()
                                                                            }}
                                                                            className="add_pannier_act_p_mv border-0">Ajouter au panier</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div><Loading /></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/*end section2 meilleurs vents*/}

                                {/*section3 banier publicitaire */}
                                <div className="publicitaire my-5">
                                    <div className="">
                                        <img style={{ cursor: 'pointer', minHeight: '220px', borderRadius: '10px' }} src={'/Images/banners/bannierpackaging.png'} className="card-img-top" alt="banier publicitaire" loading="eager" />
                                    </div>
                                </div>
                                {/*section2 publicitaire */}
                                <div style={{ marginTop: '150px', marginBottom: '150px' }} className="categoriesconnexes ">
                                    <div>
                                        <h2 className="title text-center mt-5">Catégories connexes</h2>
                                        <div className='px-lg-5'>
                                            <ActiviteRelated />
                                        </div>
                                    </div>
                                </div>
                                <div className='my-5 descriptionactivite'>
                                    <div className="container-fluid">
                                        <DescriptionComponent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
                }
                <DrawerPanier isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
            </div >
        </>
    )
}
