/* eslint-disable react/prop-types */
import './index.css';

export default function SystemAlert({ pages }) {
  if (!pages) {
    return null; 
  }

  const getMissingFields = (page) => {
    const missingFields = [];

    if (!page.focus_keyword) missingFields.push('Focus Keyword');
    if (!page.H1) missingFields.push('H1');
    if (!page.paragraph) missingFields.push('Paragraph');
    if (!page.meta_description) missingFields.push('Meta Description');
    if (!page.meta_title) missingFields.push('Meta Title');

    return missingFields;
  };

  const pagesWithIncompleteFields = pages.filter((page) => {
    return (
      !page.focus_keyword || 
      !page.H1 || 
      !page.paragraph || 
      !page.meta_description ||
      !page.meta_title
    );
  });

  return (
    <div className={`SystemAlert ${pagesWithIncompleteFields.length > 0 ? 'alert-visible' : ''}`}>
      {pagesWithIncompleteFields.length > 0 ? (
        <div className="alert-message">
            <span className='my-3 d-inline-block' style={{ fontSize:'1.3em',fontWeight:'600',color:'red' }}>
            <strong>Attention!</strong> Les pages suivantes manquent de champs obligatoires :
            </span>
          <ul>
            {pagesWithIncompleteFields.map((page) => (
              <li style={{ fontSize:'1.1em',fontWeight:'600', }} key={page.page_id}>
                <strong>{page.page_name}</strong> il manque les champs suivants:
                <ul>
                  {getMissingFields(page).map((field, index) => (
                    <li style={{ fontSize:'1em',fontWeight:'400', }} key={index}>{field}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="alert-message">
        Toutes les pages sont complètes!
        </div>
      )}
    </div>
  );
}
