import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.css';
import { useSelector, useDispatch } from 'react-redux';
import { selectCartTotal, removeAllFromCart } from '../../store/Produits';
import { useGetZoneByIdQuery, useStoreOrderMutation } from '../../store/OrderApi';
import { Alert, Snackbar } from '@mui/material';
import Loading from '../../components/Loading';

export default function Checkout() {
    const soustotal = useSelector(selectCartTotal);
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.Produits.cart);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const zoneId = queryParams.get('zoneId');
    const { data: Zone_Livraison, isLoading } = useGetZoneByIdQuery(zoneId);
    const [storeOrder] = useStoreOrderMutation();

    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [tel, setTel] = useState('');
    const [adresse, setAdresse] = useState('');
    const [email, setemail] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOrderSuccess, setIsOrderSuccess] = useState(false); // New state for success

    if (isLoading) {
        return <div><Loading /></div>;
    }

    const validateFields = () => {
        const newErrors = {};
        if (!nom.trim()) newErrors.nom = 'Nom est requis.';
        if (!prenom.trim()) newErrors.prenom = 'Prénom est requis.';
        if (!tel.trim() || !/^\d{10}$/.test(tel)) newErrors.tel = 'Le numéro de téléphone doit être composé de 10 chiffres.';
        if (!adresse.trim()) newErrors.adresse = 'Adresse est requise.';
        if (!Zone_Livraison) newErrors.zone = 'Zone de livraison invalide.';
        if (!paymentMethod) newErrors.paymentMethod = 'Veuillez sélectionner un mode de paiement.';
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0; // Valid if no errors
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateFields()) return; // Prevent submission if validation fails

        setLoading(true);  // Set loading state

        const orderData = {
            nom,
            prenom,
            tel,
            email,
            adresse,
            Zone_Livraison,
            soustotal,
            articles: cart,
            paymentMethod,
        };

        try {
            const response = await storeOrder(orderData);
            if (response.data.status === 201) {  
                dispatch(removeAllFromCart());
                setSuccessMessage('Commande passée avec succès!');
                setErrorMessage('');
                setIsOrderSuccess(true); 
            }
        } catch (error) {
            console.error('Error storing the order:', error);
            setErrorMessage('Une erreur est survenue lors de la soumission de la commande.');
            setSuccessMessage('');
        } finally {
            setLoading(false); 
        }
    };

    return (
        <>
            {isOrderSuccess ? (
                <div className="order-success text-center">
                    <strong>Commande réussie !</strong>
                    <p>Merci pour votre commande. Vous pouvez maintenant <Link to="/">{`retourner à la page d'accueil`}</Link>.</p>
                </div>
            ) : (
                <div className="checkout_page my-5">
                    <div className="container-fluid container-xl">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="facture-content p-3">
                                        <div className="Total_Montant d-flex justify-content-between align-items-center">
                                            <strong>Total</strong>
                                            <strong>{soustotal + (Zone_Livraison?.prix_livraison || 0)} MAD</strong>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="form_checkout_inputs">
                                        <div className="nom_prenom d-flex">
                                            <input
                                                className="me-1"
                                                placeholder="Nom *"
                                                value={nom}
                                                onChange={(e) => setNom(e.target.value)}
                                                required
                                            />
                                            <span className="error">{errors.nom}</span>
                                            <input
                                                className="ms-1"
                                                placeholder="Prénom *"
                                                value={prenom}
                                                onChange={(e) => setPrenom(e.target.value)}
                                                required
                                            />
                                            <span className="error">{errors.prenom}</span>
                                        </div>
                                        <div className="Tel">
                                            <input
                                                type="text"
                                                placeholder="Téléphone"
                                                value={tel}
                                                onChange={(e) => setTel(e.target.value)}
                                                required
                                            />
                                            <span className="error">{errors.tel}</span>
                                        </div>
                                        <div className="Email">
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setemail(e.target.value)}
                                            />
                                        </div>
                                        <div className="Adresse">
                                            <input
                                                type="text"
                                                list="adresses"
                                                placeholder="Adresse"
                                                value={adresse}
                                                onChange={(e) => setAdresse(e.target.value)}
                                                required
                                            />
                                            <span className="error">{errors.adresse}</span>
                                        </div>
                                        <div className="ville">
                                            <input
                                                type="text"
                                                placeholder="Ville"
                                                value={Zone_Livraison?.name || ''}
                                                disabled
                                                required
                                            />
                                        </div>
                                        <div className="btn_paymentcod my-3">
                                            <button
                                                type="submit"
                                                onClick={() => setPaymentMethod('cod')}
                                            >
                                                Payé à Livraison
                                            </button>
                                        </div>
                                        <div className="btn_paymentcmi">
                                            <button
                                                type="submit"
                                                onClick={() => setPaymentMethod('online')}
                                            >
                                                Carte de Crédit
                                            </button>
                                        </div>
                                        <span className="error">{errors.paymentMethod}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Success and Error Alerts */}
            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={() => setSuccessMessage('')}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>

            <Snackbar
                open={!!errorMessage}
                autoHideDuration={6000}
                onClose={() => setErrorMessage('')}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Snackbar
                open={loading}
                autoHideDuration={null}
            >
                <Alert severity="info" sx={{ width: '100%' }}>
                    Chargement...
                </Alert>
            </Snackbar>
        </>
    );
}
