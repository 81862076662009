import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function SlideActivite() {
    const nosActivite = [
        { id: 1 ,name:'PACKAGING', image: '/Images/activites/activites6.jpg', slug: "packaging"},
        { id: 5, name: 'NETTOYAGE ET SÉPARATION', image: '/Images/activites/activites5.jpg', slug: "nettoyage-et-separation" },
        { id: 3, name: 'SÉCHACE ET TORRÉFACTION', image: '/Images/activites/activites3.jpg', slug: "sechage-et-torrefaction" },
        { id: 6 ,name: 'Broyage et mouture', image: '/Images/activites/activites.jpg', slug: "Broyage-et-mouture"},
        { id: 2, name: 'Extraction des fruits', image: '/Images/activites/activites2.jpg', slug: "Extraction-des-fruits" },
        { id: 4, name: 'EXTRACTION DES HUILES', image: '/Images/activites/activites4.jpg', slug: "extraction-des-huiles" },
    ];

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Set autoplay speed (in milliseconds)
        responsive: [
            {
                breakpoint: 1224, // Large screen
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768, // Medium screen
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576, // Small screen
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            {nosActivite.map((item, index) => (
                <div key={index} className='m-0 p-2'>
                        <div className='activitecard position-relative my-3'>
                            <Link to={item.image} className='linkToproduct'></Link>
                            <div className='productImg'>
                                <img style={{ borderRadius:"8px 8px 0 0" }} src={item.image} alt={item.name} loading="lazy" />
                            </div>
                            <div className='cardactivitebody m-auto'>
                                {/*<div className='titleanddesc text-center'>
                                    <strong className="activite-title  mb-0">{item.name}</strong>
                                </div>*/}
                                <div className='seemore'>
                                    <Link style={{ borderRadius:"0 0 4px 4px" }} to={`/activite/${item.slug}`}>{"Plus d'infos"}</Link>
                                </div>
                            </div>
                        </div>
                </div>
            ))}
        </Slider>
    );
}
