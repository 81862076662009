import { useState } from 'react';
import './index.css';
import { useSendEmailMutation } from '../../store/CommunicationApi';
import { Alert, AlertTitle } from '@mui/material'; // Import MUI Alert

export default function ContactForm() {
    // State to store form data
    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        besoin: '',
    });

    // State for loading and success/error messages
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [sendEmail] = useSendEmailMutation();

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Start loading
        setSuccessMessage(''); // Reset success message
        setErrorMessage(''); // Reset error message

        try {
            const response = await sendEmail(formData);
            if (response.data.status === 200) {
                setSuccessMessage('Email sent successfully!');
                setFormData({ nom: '', prenom: '', email: '', telephone: '', besoin: '' });
            }
        } catch {
            setErrorMessage('Error sending email. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="ContactForm">
            <div>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Nom</label>
                        <input
                            type="text"
                            name="nom"
                            placeholder="Nom"
                            value={formData.nom}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Prénom</label>
                        <input
                            type="text"
                            name="prenom"
                            placeholder="Prénom"
                            value={formData.prenom}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Téléphone</label>
                        <input
                            type="text"
                            name="telephone"
                            placeholder="Téléphone"
                            value={formData.telephone}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Détaillez nous votre besoin*</label>
                        <textarea
                            name="besoin"
                            placeholder="Détaillez nous votre besoin*"
                            value={formData.besoin}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <span className="d-flex justify-content-end my-5">
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? 'Sending...' : 'Soumettre'}
                        </button>
                    </span>
                </form>

                {/* MUI Alert for Success */}
                {successMessage && (
                    <Alert severity="success" onClose={() => setSuccessMessage('')}>
                        <AlertTitle>Success</AlertTitle>
                        {successMessage}
                    </Alert>
                )}

                {/* MUI Alert for Error */}
                {errorMessage && (
                    <Alert severity="error" onClose={() => setErrorMessage('')}>
                        <AlertTitle>Error</AlertTitle>
                        {errorMessage}
                    </Alert>
                )}
            </div>
        </div>
    );
}
