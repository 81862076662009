import { Link, useLocation } from "react-router-dom";
import BlogId1 from "./BlogId1";
import BlogId2 from "./BlogId2";
import BlogId3 from "./BlogId3";

export default function BlogDetail() {
  const location = useLocation();

  // Parse the `id` from the query string
  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  const blogs = [
    { id: 1, title: 'Secteurs Porteurs pour les Jeunes Entrepreneurs au Maroc en 2024', image: '/Images/blogs/blog1.png', link: `/nos-conseils/blog/Secteurs-porteurs-pour-les-jeunes-entrepreneurs-au-maroc-en-2024?id=${1}`, description: 'En 2024, les jeunes entrepreneurs au Maroc ont l’opportunité de se lancer dans des secteurs en pleine' },
    { id: 2, title: `Révolutionner la Production d'Amlou : Le Rôle des Machines Modernes`, image: '/Images/blogs/blog Production Amlou.jpg', link: `/nos-conseils/blog/revolutionner-la-production-Amlou?id=${2}`, description: 'La production d’Amlou repose sur des ingrédients de qualité, mais également sur des processus optimisés. L’intégration de machines comme le moulin à beurre a transformé l’industrie' },
    { id: 3, title: `Comment optimiser votre production avec des machines d'ensachage automatiques`, image: '/Images/blogs/blogId3(3).png', link: `/nos-conseils/blog/optimiser-votre-production-avec-des-machines-densachage-automatiques?id=${3}`, description: 'L’ensachage est une étape essentielle dans de nombreuses industries, notamment alimentaire, cosmétique et pharmaceutique' }
  ];

  // Get the current blog based on the id
  const currentBlog = blogs.find(blog => blog.id.toString() === id);
  // Filter out the current blog based on the id
  const filteredBlogs = blogs.filter(blog => blog.id.toString() !== id);

  // Conditional rendering based on the id
  const renderBlog = () => {
    switch (id) {
      case "1":
        return <BlogId1 />;
      case "2":
        return <BlogId2 />;
      case "3":
        return <BlogId3 />;
      default:
        return <p>Blog not found</p>; // Fallback for invalid or missing id
    }
  };

  return (
    <>
      <div className="sectionheader mb-2">
        <div
          style={{
            maxHeight: '600px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden', // Ensures the image doesn't overflow the container
          }}
          className="imagebanner"
        >
          <img
            src={currentBlog ? currentBlog.image : '/Images/default-banner.png'}
            alt={currentBlog ? currentBlog.title : 'Default Banner'}
            loading="eager"
            style={{
              maxHeight: '100%',
              maxWidth: '100%',
              objectFit: 'contain', 
            }}
          />
          <div className="titreonBanner"></div>
        </div>
      </div>

      <div className="row my-5 g-0">
        <div className="col-12 col-lg-8">
          <div className="container-fluid container-md">
            <div className='mx-0 mx-md-5'>
              {renderBlog()}
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-4">
          <div style={{ position: 'sticky', top: '0' }} className="container-fluid container-md">
            <div className="row g-2">
              {filteredBlogs.map(blog => (
                <div style={{ borderRadius: "6px" }} className="col-12 col-sm-6 col-lg-12" key={blog.id}>
                  <div style={{ backgroundColor: "#efeeee", borderRadius: '8px' }} className="p-2">
                    <Link style={{ textDecoration: "none" }} to={blog.link} >
                      <div className="blogCard d-flex justify-content-start">
                        <img style={{ width: "100%", maxWidth: '150px', borderRadius: "6px" }} src={blog.image} alt="" />
                        <div className="blogCardContent ms-3">
                          <h3 style={{ fontSize: "15px", fontWeight: "bold", color: "#353333" }}>{blog.title}</h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
