/* eslint-disable react/prop-types */
import { useGetPagesQuery } from "../store/SeoApi";
import Loading from "./Loading";

export default function SEODataWrapper({ component: Component, pageName }) {
    const { data: pages, isLoading, error } = useGetPagesQuery();

    if (isLoading) return <div> <Loading /> </div>;
    if (error) return <div>Error fetching SEO data.</div>;

    // Find SEO data for the current page
    const seoData = pages?.find((page) => page.page_name === pageName);
    // Pass seoData as a prop to the component
    return <Component seoData={seoData} />;
}
