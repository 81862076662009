/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// baseUrl: 'https://honeydew-horse-869124.hostingersite.com/backend/public',
// Create an API slice
export const OrderApi = createApi({
    reducerPath: 'OrderApi',
    baseQuery: fetchBaseQuery({
    // baseUrl: 'http://127.0.0.1:8000/api',
    baseUrl: 'https://smab-co.com/backend/public/api',
    // baseUrl: 'https://mintcream-crocodile-587037.hostingersite.com/public/api',

        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('userToken');
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
          },
    }),
    endpoints: (builder) => ({

        getZoneById: builder.query({
            query: (id) => ({
                url: `/get-zone/${id}`,
                method: 'GET',
            })
        }),

        storeOrder: builder.mutation({
            query: (FormData) => ({
              url: '/store-order',
              method: 'POST',
              body: FormData,
            }),
          }),

          getOrders: builder.query({
            query: () => ({
              url: '/get-orders',
              method: 'get',
            }),
          })
    }),
});

export const { useGetZoneByIdQuery, useStoreOrderMutation, useGetOrdersQuery } = OrderApi;
