/* eslint-disable react/prop-types */
import { Navigate } from "react-router-dom";
import { useUserCheckValidityTokenQuery } from "../store/AuthUserApi";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/Auth";
import Loading from "../components/Loading";

const UserPrivateRoute = ({ children }) => {
    const dispatch = useDispatch();
    const userToken = useSelector((state) => state.Auth.userToken);
    const { data: checkValidity, isLoading: validityLoading, isError } = useUserCheckValidityTokenQuery(userToken);

    if (validityLoading) {
        return <div><Loading /></div>;
    }

    if (isError || (checkValidity && checkValidity.status !== 'success')) {
        dispatch(logoutUser());
        return <Navigate to="/login" />;
    }

    // Render the passed children if authenticated
    return children;
};

export default UserPrivateRoute;
