/* eslint-disable no-unused-vars */
import './App.css'
import { RouterProvider } from "react-router-dom";
import { router } from './routes/Index'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-multi-carousel/lib/styles.css';
import { HelmetProvider } from 'react-helmet-async';
import { useEffect } from 'react';



// Initialize Pixel (run once on app load)

function App() {


  // useEffect(() => {
  //   const handleRightClick = (event) => {
  //     event.preventDefault(); // Disable right-click
  //   };
  //   document.addEventListener('contextmenu', handleRightClick);
  //   return () => {
  //     document.removeEventListener('contextmenu', handleRightClick);
  //   };
  // }, []); 

  
  return (
    <>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </>
  )
}

export default App
