import { Link } from 'react-router-dom'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useState } from 'react';

export default function Footer_small_lg() {
    const [sub_links_Service_commercial, set_sub_links_Service_commercial] = useState(false);
    const [sub_links_service_client, set_sub_links_service_client] = useState(false);

    const handle_sub_links_Service = () => {
        set_sub_links_Service_commercial(!sub_links_Service_commercial)
        set_sub_links_service_client(false)
    }

    const handle_sub_links_client = () => {
        set_sub_links_Service_commercial(false)
        set_sub_links_service_client(!sub_links_service_client)
    }

    return (
        <div className='footer_small_lg'>
            <div className=''>
                <div className='row'>
                    <div className='col-12 '>
                        <strong className='border border-0'>Abonnez-vous à Notre Newsletter</strong>
                        <div className=''>
                            <form action="" className='subscribe'>
                                <div className="d-flex justify-content-start">
                                    <div className="form-outline mb-4">
                                        <input type="email" id="form5Example24" className="form-control" placeholder='Email' />
                                    </div>
                                    <button data-mdb-ripple-init type="submit" className="btn btn-outline mb-4">
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className='col-12 '>
                        <strong style={{ cursor: 'pointer' }} onClick={() => handle_sub_links_Service()}>Service commercial
                            <span className={`show_sub_links_footer ${sub_links_Service_commercial ? 'd-none' : ''}`}>
                                <KeyboardArrowRightOutlinedIcon />
                            </span>
                            <span className={`hide_sub_links_footer ${sub_links_Service_commercial ? '' : 'd-none'}`} >
                                <KeyboardArrowDownOutlinedIcon />
                            </span>
                        </strong>
                        <div className={`sub_links_footer ${sub_links_Service_commercial ? '' : 'd-none'}`}>
                            <ul>
                                <li>
                                    <Link className="text-body" to={'/a-propos-de-nous'}>A propos de nous</Link>
                                </li>
                                <li>
                                    <Link className="text-body" to={'/politique-de-confidentialite'}>Politique de confidentialité et sécurité</Link>
                                </li>
                                <li>
                                    <Link className="text-body" to={'/'}>Programme distributeur SMAB</Link>
                                </li>
                                <li>
                                    <Link className="text-body" to={'/'}>Programme affiliés SMAB</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='col-12 '>
                        <strong style={{ cursor: 'pointer' }} onClick={() => handle_sub_links_client()}>Service client
                            <span className={`show_sub_links_footer ${sub_links_service_client ? 'd-none' : ''}`}>
                                <KeyboardArrowRightOutlinedIcon />
                            </span>
                            <span className={`hide_sub_links_footer ${sub_links_service_client ? '' : 'd-none'}`} >
                                <KeyboardArrowDownOutlinedIcon />
                            </span>
                        </strong>
                        <div className={`sub_links_footer ${sub_links_service_client ? '' : 'd-none'}`}>
                            <ul>
                                <li>
                                    <Link className="text-body" to={'/contactez-nous'}>Contactez-nous</Link>
                                </li>
                                <li>
                                    <Link className="text-body" to={'/mon-compte'}>Votre compte</Link>
                                </li>
                                <li>
                                    <Link className="text-body" to={'/CGV'}>CGV</Link>
                                </li>
                                <li>
                                    <Link className="text-body" to={'/MentionLegal'}>Mentions légales</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='col-12'>
                        <strong className='border border-0'>Trouvez nous sur</strong>
                        <div>
                            <a href='/https://web.facebook.com/SMABMAROC/?locale=fr_FR&_rdc=1&_rdr#' rel="noopener noreferrer" target="_blank"
                                className="me-4 text-reset">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href={'/https://www.tiktok.com/@smabmaroc'} rel="noopener noreferrer" target="_blank" className="me-4 text-reset">
                                <i className="fa-brands fa-tiktok"></i>
                            </a>
                            <a href='/https://www.instagram.com/smabmaroc/' rel="noopener noreferrer" target="_blank" className="me-4 text-reset">
                                <i className="fab fa-instagram"></i>
                            </a>
                            {/*<Link to={'/'} className="me-4 text-reset">
                                <i className="fab fa-linkedin"></i>
                            </Link>*/}
                            <a target="_blank" rel="noopener noreferrer" href={'https://www.youtube.com/@SMABMAROC'} className="me-4 text-reset">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

