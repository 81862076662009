/* eslint-disable no-unused-vars */
import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../Layouts/MainLayout";
import Home from "../pages/home/Home";
import Activite from "../pages/Activite/Activite";
import SousActivite from "../pages/SousActivite/SousActivite";
import Product from "../pages/product/Product";
import Panier from "../pages/Panier/Panier";
import Index from '../pages/user/Index'
import Adresses from "../pages/user/adresses/Adresses";
import Parametres from "../pages/user/Parametres/Parametres";
import Password from "../pages/user/password/Password";
import Orders from "../pages/user/orders/Orders";
import Login from "../pages/LoginRegister/Login";
import Register from "../pages/LoginRegister/Register";
import Compare from "../pages/product/compare product/Compare";
import Checkout from "../pages/checkout/Checkout";
import NosProjets from "../pages/nosProjets/NosProjets";
import ProjectDetails from "../pages/nosProjets/ProjectDetails";
import UserPrivateRoute from "./UserPrivateRoute";
import AuthProtectedRoute from "./AuthProtectedRoute";
import NosServices from "../pages/nosServices/NosServices";
import NosConseils from "../pages/NosConseils/NosConseils";
import AboutUs from "../pages/aboutUs/AboutUs";
import BlogDetail from "../pages/NosConseils/BlogDetail";
import ContactUs from "../pages/contactUs/ContactUs";
import AdminLayout from "../Layouts/AdminLayout";
import Dashboard from "../pages/admin/Dashboard/Dashboard";
import ManageSeo from "../pages/admin/seo/ManageSeo";
import SEODataWrapper from "../components/SEODataWrapper";
import LoginAdmin from "../pages/admin/LoginAdmin";
import AdminPrivateRoute from "./AdminPrivateRoute";
import AuthAdminProtectedRoute from "./AuthAdminProtectedRoute copy";
import GestionAdmins from "../pages/admin/gestion admins/GestionAdmins";
import Cgv from "../pages/CGV";
import PolitiqueConfidentialite from "../pages/PolitiqueConfidentialite";
import MentionLegal from "../pages/MentionLegal";
import ForgetPassword from "../pages/LoginRegister/ForgetPassword";
import NewPassword from "../pages/LoginRegister/NewPassword";


export const router = createBrowserRouter([
    {
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <SEODataWrapper component={Home} pageName="Page d'accueil" />,
            },

            {
                path: '/activite/:activitename',
                element: <SEODataWrapper component={Activite} pageName="Page-activite--Pakaging" />,
            },
            {
                path: '/sous-activite/:sousActiviteName',
                element: <SousActivite />
            },

            {
                path: '/produit/:produitname',
                element: <Product />
            },

            {
                path: '/panier',
                element: <Panier />
            },

            {
                path: '/login',
                element: <AuthProtectedRoute />, // Use the public route component
                children: [
                    {
                        path: '/login',
                        element: <Login />
                    }
                ]
            },

            {
                path: '/creer-un-compte',
                element: <Register />
            },

            {
                path: '/:activiteName/:sousActiviteName/comparer',
                element: <Compare />
            },

            {
                path: '/checkout',
                element: <Checkout />
            },

            {
                path: '/nos-projets',
                element: <NosProjets />
            },

            {
                path: '/categorieName/projets-details',
                element: <ProjectDetails />
            },


            {
                path: '/nos-services',
                element: <SEODataWrapper component={NosServices} pageName="nos_services" />,
            },

            {
                path: '/nos-conseils',
                element: <NosConseils />
            },

            {
                path: '/a-propos-de-nous',
                element: <AboutUs />
            },

            {
                path: '/nos-conseils/blog/:blogslug',
                element: <BlogDetail />
            },

            {
                path: '/contactez-nous',
                element: <ContactUs />
            },

            {
                path: '/mon-compte',
                element:
                    <UserPrivateRoute>
                        <Index />
                    </UserPrivateRoute>,

                children: [
                    { path: '/mon-compte/adresses', element: <Adresses /> },
                    { path: '/mon-compte/parametres', element: <Parametres /> },
                    { path: '/mon-compte/mot-de-passe', element: <Password /> },
                    { path: '/mon-compte/Historique-des-commandes', element: <Orders /> },
                ]
            },

            {
                path: '/CGV',
                element: <Cgv />
            },

            {
                path: '/MentionLegal',
                element: <MentionLegal />
            },

            {
                path: '/politique-de-confidentialite',
                element: <PolitiqueConfidentialite />
            },

            {
                path: '/Mot-de-passe-oublie',
                element: <ForgetPassword />
            },

            {
                path: '/new-password',
                element: <NewPassword />
            }
        ]
    },



    {
        path: '/admin-login',
        element: <AuthAdminProtectedRoute />, // Use the public route component
        children: [
            {
                path: '/admin-login',
                element: <LoginAdmin />
            }
        ]
    },

    {
        path: '/admin',
        element: <AdminPrivateRoute>
            <AdminLayout />
        </AdminPrivateRoute>,
        children: [
            {
                index: true,
                element: < Dashboard />
            },
            {
                path: '/admin/gestion-seo',
                element: < ManageSeo />
            },

            {
                path: '/admin/gestion-admins',
                element: < GestionAdmins />
            },

        ]
    },

    {
        path: '*',
        element: <p>NotFound 404</p>
    },


])