import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userToken: localStorage.getItem('userToken') || '',
  adminToken: localStorage.getItem('adminToken') || '',
  adminRole: localStorage.getItem('adminRole') || '',
}

export const Auth = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.userToken = action.payload;
      localStorage.setItem('userToken', action.payload);
    },

    loginAdmin: (state, action) => {
      state.adminToken = action.payload.token;
      state.adminRole = action.payload.role;
      localStorage.setItem('adminToken', action.payload.token);
      localStorage.setItem('adminRole', action.payload.role);
    },

    logoutUser: (state) => {
      state.userToken = '';
      localStorage.removeItem('userToken');
    },

    logoutadmin: (state) => {
      state.adminToken = '';
      localStorage.removeItem('adminToken');
      localStorage.removeItem('adminRole');
    },
  },
});

export const { loginUser, logoutUser, loginAdmin, logoutadmin} = Auth.actions;
export default Auth.reducer;
