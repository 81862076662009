/* eslint-disable react/prop-types */

export default function Description({Description}) {
    return (
        <div className="my-5">
            <div className="Description">
                <div className="image_product d-flex align-items-center justify-content-start" >
                <p
                className='text-start'
                dangerouslySetInnerHTML={{
                    __html: Description,
                }}
            ></p>
                </div>
            </div>
        </div>
    )
}
